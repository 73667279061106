import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { FiMinus } from 'react-icons/fi';
import { FaSearch } from 'react-icons/fa';

import getValidationErrors from '../../../../utils/getValidationErrors';

import api from '../../../../services/api';

import { useToast } from '../../../../hooks/Toast';

import Input from '../../../../components/Form/Input';
import Button from '../../../../components/Form/Button';
import Select from '../../../../components/Form/Select';
import InputMask from '../../../../components/Form/InputMask';
import InputFile from '../../../../components/Form/InputFile';
import Textarea from '../../../../components/Form/Textarea';
import InputNumber from '../../../../components/Form/NumberInput';
import ProposalPdf from '../../proposalPdf';
import SearchModal from '../../../../components/Modals/SearchModal';

import { Container } from './styles';

import { Equipment } from '../../../../interfaces/Equipment';
import { Miscellanies } from '../../../../interfaces/Miscellanies';
import { Infrastructure } from '../../../../interfaces/Infrastructure';
import { Service } from '../../../../interfaces/Service';

interface LaborEquipments {
  name: string;
  points: any;
  unit_cost: any;
  total_cost: any;
  cost_with_margin: any;
  cost_with_tax: any;
}
interface GeneralLabors {
  name: string;
  observation: string;
  total_cost: any;
  cost_with_margin: string;
  cost_with_tax: string;
}
interface FormProps {
  product: {
    id: number;
    name: string;
    equipments: Equipment[],
    labor_equipments: LaborEquipments[],
    general_labors: GeneralLabors[],
    miscellanies: Miscellanies[];
    infrastructures: Infrastructure[];
    services: Service[];
  },
  currentOptions: {
    equipments: Equipment[],
    miscellanies: Miscellanies[];
    infrastructures: Infrastructure[];
    services: Service[];
  },
  proposal: any,
  refreshProposal(): void;
  previousStep(): void;
}

const FormProduct: React.FC<FormProps> = ({ product, currentOptions, proposal, refreshProposal, previousStep }) => {
  const formRef = useRef<FormHandles>(null);

  const [isLoading, setIsLoading] = useState(false);
  const [refresh, setRefresh] = useState<boolean>(true);
  const [sending, setSending] = useState<boolean>(false);

  const [equipments, setEquipments] = useState<Equipment[]>([]);
  const [laborEquipments, setLaborEquipments] = useState<LaborEquipments[]>([]);
  const [generalLabors, setGeneralLabors] = useState<GeneralLabors[]>([]);
  const [miscellanies, setMiscellanies] = useState<Miscellanies[]>([]);
  const [infrastructures, setInfrastructures] = useState<Infrastructure[]>([]);
  const [services, setServices] = useState<Service[]>([]);

  const [totalValue, setTotalValue] = useState('0,00');
  const [margin, setMargin] = useState(0);
  const [tax, setTax] = useState(0);
  const [totalCost, setTotalCost] = useState(0);
  const [totalLaborEquip, setTotalLaborEquip] = useState(0);
  const [totalMisc, setTotalMisc] = useState(0);
  const [totalInfra, setTotalInfra] = useState(0);
  const [totalGeneralLabor, setTotalGeneralLabor] = useState(0);

  /* Search Modal states */
  const [showSearchModal, setShowSearchModal] = useState(false);
  const [searchModalCurrentIndex, setSearchModalCurrentIndex] = useState<number>(1);
  const [searchModalAttrName, setSearchModalAttrName] = useState<string>('');
  const [searchModalItemName, setSearchModalItemName] = useState<string>('');
  const [searchModalCurrentRoute, setSearchModalCurrentRoute] = useState<string>('');
  /* End Search Modal states */

  const [canLoadTotal, setCanLoadTotal] = useState<boolean>(false);

  const { addToast } = useToast();

  useEffect(() => {
    if (product && proposal) {
      setCanLoadTotal(false);
      loadDefaultData();
    }
  }, [product]);

  let loadDefaultDataTimeout: any;
  const loadDefaultData = useCallback(async () => {
    clearTimeout(loadDefaultDataTimeout);

    const equipmentsAux: Equipment[] = [];
    const promiseEquipments = await product?.equipments?.map((item, index) => {
      equipmentsAux.push({} as Equipment);
    })


    const laborEquipmentsAux: LaborEquipments[] = [];
    const promiseLaborEquipments = await product?.labor_equipments?.map((item, lb_index) => {
      laborEquipmentsAux.push({} as LaborEquipments);
    })

    const generalLaborsAux: GeneralLabors[] = [];
    const promiseGeneralLabors = await product?.general_labors?.map(item => {
      generalLaborsAux.push({} as GeneralLabors);
    })

    if (!product?.general_labors || product?.general_labors?.length === 0) {
      generalLaborsAux.push({
        name: '1',
        observation: 'HOSPEDAGEM',
        total_cost: '0',
        cost_with_margin: '0',
        cost_with_tax: '0',
      })
      generalLaborsAux.push({
        name: '1',
        observation: 'COMBUSTÍVEL',
        total_cost: '0',
        cost_with_margin: '0',
        cost_with_tax: '0',
      })
      generalLaborsAux.push({
        name: '1',
        observation: 'PEDÁGIO',
        total_cost: '0',
        cost_with_margin: '0',
        cost_with_tax: '0',
      })
      generalLaborsAux.push({
        name: '1',
        observation: 'REFEIÇÃO',
        total_cost: '0',
        cost_with_margin: '0',
        cost_with_tax: '0',
      })

      product.general_labors = generalLaborsAux;
    }


    const miscellaniesAux: Miscellanies[] = [];
    const promiseMiscellanies = await product?.miscellanies?.map(item => {
      miscellaniesAux.push({} as Miscellanies);
    })

    const infrastructuresAux: Infrastructure[] = [];
    const promiseInfrastructures = await product?.infrastructures?.map(item => {
      infrastructuresAux.push({} as Infrastructure);
    })

    const servicesAux: Service[] = [];
    const promiseService = await product?.services?.map(item => {
      servicesAux.push({} as Service);
    })

    await Promise.all([
      promiseEquipments,
      promiseLaborEquipments,
      promiseGeneralLabors,
      promiseMiscellanies,
      promiseInfrastructures,
      promiseService,
    ]);

    setEquipments(equipmentsAux);
    setLaborEquipments(laborEquipmentsAux);
    setGeneralLabors(generalLaborsAux);
    setMiscellanies(miscellaniesAux);
    setInfrastructures(infrastructuresAux);
    setServices(servicesAux);

    formRef.current?.setData({
      ...product,
      equipments: product?.equipments?.map((item, equipIndex) => {
        const withMarginValue = item.unit_cost + (item.unit_cost * proposal?.margin);
        var default_unit_cost_with_tax = withMarginValue + (withMarginValue * proposal?.tax);

        formRef.current?.setFieldValue(`equipments[${equipIndex}].photo_url`, item.photo_url);

        return {
          ...item,
          photo_url: item.photo_url,
          description: item.description,
          unit_sales_price: default_unit_cost_with_tax,
          total_sales_price: default_unit_cost_with_tax * (item.qtd || 1),
          cost_with_margin: withMarginValue,
          cost_with_tax: (withMarginValue + (withMarginValue * proposal?.tax)) * (item.qtd || 1) || '0'
        }
      }) || [],
      labor_equipments: product?.labor_equipments?.map((item: any, labor_index: number) => {
        const currentEquip = product?.equipments.filter((equipAux) => equipAux.name === item.name);

        var qtd = currentEquip && currentEquip.length > 0 ? currentEquip[0].qtd : 0;

        const total_cost = (item.unit_cost * parseFloat(item.points)) * qtd;
        const withMarginValue = total_cost + (total_cost * proposal?.margin);
        return {
          ...item,
          unit_cost: item.unit_cost || '0',
          total_cost: total_cost || '0',
          cost_with_margin: withMarginValue || '0',
          cost_with_tax: withMarginValue + (withMarginValue * proposal?.tax) || '0'
        }
      }) || [],
      general_labors: product?.general_labors?.map(item => {
        const withMarginValue = item.total_cost + (item.total_cost * proposal?.margin);
        return {
          ...item,
          total_cost: item.total_cost === 0 ? '0' : item.total_cost,
          cost_with_margin: withMarginValue === 0 ? '0' : withMarginValue,
          cost_with_tax: withMarginValue === 0 ? '0' : withMarginValue + (withMarginValue * proposal?.tax)
        }
      }) || [],
      miscellanies: product?.miscellanies?.map(item => {
        const withMarginValue = item.total_cost + (item.total_cost * proposal?.margin);
        return {
          ...item,
          cost_with_margin: withMarginValue,
          cost_with_tax: withMarginValue + (withMarginValue * proposal?.tax)
        }
      }) || [],
      infrastructures: product?.infrastructures?.map(item => {
        const withMarginValue = item.total_cost + (item.total_cost * proposal?.margin);
        return {
          ...item,
          cost_with_margin: withMarginValue,
          cost_with_tax: withMarginValue + (withMarginValue * proposal?.tax)
        }
      }) || [],
      services: product?.services?.map(item => {
        return {
          ...item,
          total_cost: item.total_cost || '0'
        }
      }) || [],
    });

    loadDefaultDataTimeout = setTimeout(() => {
      setTimeout(() => {
        updateTotal();
        updateTotalLaborEquip();
        updateTotalGeneralLabors();
        updateTotalMisc();
        updateTotalInfra();

        setCanLoadTotal(true);
      }, 3000);
    }, 1000);
  }, [product, loadDefaultDataTimeout, proposal, services]);

  const handleNewItem = useCallback((type) => {
    switch (type) {
      case 'equipment':
        setEquipments(oldValue => [...oldValue, {} as Equipment]);
        setLaborEquipments(oldValue => [...oldValue, {} as LaborEquipments]);
        break;
      case 'general_labors':
        setGeneralLabors(oldValue => [...oldValue, {} as GeneralLabors])
        break;
      case 'miscellanies':
        setMiscellanies(oldValue => [...oldValue, {} as Miscellanies])
        break;
      case 'infrastructures':
        setInfrastructures(oldValue => [...oldValue, {} as Infrastructure])
        break;
      case 'services':
        setServices(oldValue => [...oldValue, {} as Service])
        break;
      default:
        break;
    }
  }, []);

  const handleRemoveItem = useCallback(async (type, index) => {
    let itemsAux = [];
    let valueAux = [];
    let laborsAux = [];
    const formData: any = formRef.current?.getData();

    switch (type) {
      case 'equipment':
        valueAux = formData?.equipments;
        laborsAux = formData?.labor_equipments;
        valueAux = valueAux?.filter((item: Equipment, i: number) => i !== index) || [];
        itemsAux = equipments.filter((item, i) => i !== index) || [];
        laborsAux = laborsAux?.filter((item: LaborEquipments, i: number) => i !== index) || [];

        setEquipments([...itemsAux]);
        setLaborEquipments([...laborsAux]);

        await valueAux.map((item: Equipment, i: number) => {
          const withMarginValue = (item.total_cost + (item.total_cost * proposal?.margin));

          formRef.current?.setFieldValue(`equipments[${i}].name`, item.name);
          formRef.current?.setFieldValue(`equipments[${i}].description`, item.description);
          formRef.current?.setFieldValue(`equipments[${i}].photo_url`, item.photo_url);
          formRef.current?.setFieldValue(`equipments[${i}].model`, item.model);
          formRef.current?.setFieldValue(`equipments[${i}].maker`, item.maker);
          formRef.current?.setFieldValue(`equipments[${i}].qtd`, item.qtd);
          formRef.current?.setFieldValue(`equipments[${i}].unit_cost`, item.unit_cost);
          formRef.current?.setFieldValue(`equipments[${i}].total_cost`, item.total_cost);
          formRef.current?.setFieldValue(`equipments[${i}].cost_with_margin`, withMarginValue);
          formRef.current?.setFieldValue(`equipments[${i}].cost_with_tax`, (withMarginValue + (withMarginValue * proposal?.tax)));

          var default_unit_cost_with_margin = item.unit_cost + (item.unit_cost * proposal?.margin);
          var default_unit_cost_with_tax = default_unit_cost_with_margin + (default_unit_cost_with_margin * proposal?.tax);
          formRef.current?.setFieldValue(`equipments[${i}].unit_sales_price`, default_unit_cost_with_tax);
          formRef.current?.setFieldValue(`equipments[${i}].total_sales_price`, default_unit_cost_with_tax * (item.qtd || 1));
        });

        await laborsAux.map((item: LaborEquipments, i: number) => {
          const total_labor_equipment_value = (item.unit_cost * parseFloat(item.points)) || 0;
          const total_labor_equipment_value_with_margin = total_labor_equipment_value + (total_labor_equipment_value * proposal?.margin) || 0;

          formRef.current?.setFieldValue(`labor_equipments[${i}].name`, item.name);
          formRef.current?.setFieldValue(`labor_equipments[${i}].points`, parseFloat(item.points));
          formRef.current?.setFieldValue(`labor_equipments[${i}].unit_cost`, item.unit_cost || '0');
          formRef.current?.setFieldValue(`labor_equipments[${i}].total_cost`, total_labor_equipment_value.toString());
          formRef.current?.setFieldValue(`labor_equipments[${i}].cost_with_margin`, total_labor_equipment_value_with_margin);
          formRef.current?.setFieldValue(`labor_equipments[${i}].cost_with_tax`, (total_labor_equipment_value_with_margin + (total_labor_equipment_value_with_margin * proposal?.tax)));
        });

        updateTotal();
        updateTotalLaborEquip();

        break;
      case 'general_labors':
        valueAux = formData?.general_labors;
        valueAux = valueAux?.filter((item: GeneralLabors, i: number) => i !== index);
        itemsAux = generalLabors.filter((item, i) => i !== index);
        setGeneralLabors([...itemsAux]);
        await valueAux.map((item: GeneralLabors, i: number) => {
          const withMarginValue = (item.total_cost + (item.total_cost * proposal?.margin));

          formRef.current?.setFieldValue(`general_labors[${i}].name`, item.name);
          formRef.current?.setFieldValue(`general_labors[${i}].observation`, item.observation);
          formRef.current?.setFieldValue(`general_labors[${i}].total_cost`, item.total_cost);
          formRef.current?.setFieldValue(`general_labors[${i}].cost_with_margin`, withMarginValue);
          formRef.current?.setFieldValue(`general_labors[${i}].cost_with_tax`, (withMarginValue + (withMarginValue * proposal?.tax)));
        });

        updateTotalGeneralLabors();

        break;
      case 'miscellanies':
        valueAux = formData?.miscellanies;
        valueAux = valueAux?.filter((item: Miscellanies, i: number) => i !== index);
        itemsAux = miscellanies.filter((item, i) => i !== index);
        setMiscellanies([...itemsAux]);
        await valueAux.map((item: Miscellanies, i: number) => {
          const withMarginValue = (item.total_cost + (item.total_cost * proposal?.margin));

          formRef.current?.setFieldValue(`miscellanies[${i}].description`, item.description);
          formRef.current?.setFieldValue(`miscellanies[${i}].qtd`, item.qtd);
          formRef.current?.setFieldValue(`miscellanies[${i}].unit_cost`, item.unit_cost);
          formRef.current?.setFieldValue(`miscellanies[${i}].total_cost`, item.total_cost);
          formRef.current?.setFieldValue(`miscellanies[${i}].cost_with_margin`, withMarginValue);
          formRef.current?.setFieldValue(`miscellanies[${i}].cost_with_tax`, (withMarginValue + (withMarginValue * proposal?.tax)));
        });

        updateTotalMisc();

        break;
      case 'infrastructures':
        valueAux = formData?.infrastructures;
        valueAux = valueAux?.filter((item: Infrastructure, i: number) => i !== index);
        itemsAux = infrastructures.filter((item, i) => i !== index);
        setInfrastructures([...itemsAux]);
        await valueAux.map((item: Infrastructure, i: number) => {
          const withMarginValue = (item.total_cost + (item.total_cost * proposal?.margin));

          formRef.current?.setFieldValue(`infrastructures[${i}].name`, item.name);
          formRef.current?.setFieldValue(`infrastructures[${i}].qtd`, item.qtd);
          formRef.current?.setFieldValue(`infrastructures[${i}].unit_cost`, item.unit_cost);
          formRef.current?.setFieldValue(`infrastructures[${i}].total_cost`, item.total_cost);
          formRef.current?.setFieldValue(`infrastructures[${i}].cost_with_margin`, withMarginValue);
          formRef.current?.setFieldValue(`infrastructures[${i}].cost_with_tax`, (withMarginValue + (withMarginValue * proposal?.tax)));
        });

        updateTotalInfra();

        break;
      case 'services':
        valueAux = formData?.services;
        valueAux = valueAux?.filter((item: Service, i: number) => i !== index);
        itemsAux = services.filter((item, i) => i !== index);
        setServices([...itemsAux]);
        await valueAux.map((item: Service, i: number) => {
          formRef.current?.setFieldValue(`services[${i}].name`, item.name);
          formRef.current?.setFieldValue(`services[${i}].description`, item.description);
          formRef.current?.setFieldValue(`services[${i}].total_cost`, item.total_cost);
        });

        break;
      default:
        break;
    }
  }, [equipments, laborEquipments, generalLabors, miscellanies, infrastructures, services, proposal]);

  // EQUIPMENT
  const handleSelectEquipment = useCallback(async (data, index) => {
    if (data) {
      const equipAux = await currentOptions.equipments.filter((item: Equipment) => item.id === data.value)[0];
      const currentQtd = formRef.current?.getFieldValue(`equipments[${index}].qtd`);
      const totalCost = equipAux.cost * (currentQtd || 1);
      const costMargin = totalCost + (totalCost * proposal?.margin);
      const costTax = costMargin + (costMargin * proposal?.tax);

      if (!currentQtd) {
        formRef.current?.setFieldValue(`equipments[${index}].qtd`, 1);
      }

      const labor_total = ((proposal.point_value || 0) * equipAux.total_of_points);

      formRef.current?.setFieldValue(`labor_equipments[${index}].name`, equipAux.name);
      formRef.current?.setFieldValue(`labor_equipments[${index}].points`, equipAux.total_of_points);
      formRef.current?.setFieldValue(`labor_equipments[${index}].unit_cost`, (proposal.point_value === 0 ? '0' : proposal.point_value));
      formRef.current?.setFieldValue(`labor_equipments[${index}].total_cost`, labor_total === 0 ? '0' : labor_total);

      const laborCostMargin = labor_total + (labor_total * proposal?.margin);
      const laborCostTax = laborCostMargin + (laborCostMargin * proposal?.tax);
      formRef.current?.setFieldValue(`labor_equipments[${index}].cost_with_margin`, laborCostMargin === 0 ? '0' : laborCostMargin);
      formRef.current?.setFieldValue(`labor_equipments[${index}].cost_with_tax`, laborCostTax === 0 ? '0' : laborCostTax);

      formRef.current?.setFieldValue(`equipments[${index}].name`, equipAux.name);
      formRef.current?.setFieldValue(`equipments[${index}].description`, equipAux.description);
      formRef.current?.setFieldValue(`equipments[${index}].photo_url`, equipAux.photo_url);
      formRef.current?.setFieldValue(`equipments[${index}].model`, equipAux.model);
      formRef.current?.setFieldValue(`equipments[${index}].maker`, equipAux.maker);
      formRef.current?.setFieldValue(`equipments[${index}].unit_cost`, equipAux.cost);
      formRef.current?.setFieldValue(`equipments[${index}].total_cost`, totalCost);
      formRef.current?.setFieldValue(`equipments[${index}].cost_with_margin`, costMargin);
      formRef.current?.setFieldValue(`equipments[${index}].cost_with_tax`, costTax);


      var default_unit_cost = await currentOptions.equipments.filter((item: Equipment) => item.id === data.value)[0];
      var default_unit_cost_with_margin = default_unit_cost.cost + (default_unit_cost.cost * proposal?.margin);
      var default_unit_cost_with_tax = default_unit_cost_with_margin + (default_unit_cost_with_margin * proposal?.tax);
      formRef.current?.setFieldValue(`equipments[${index}].unit_sales_price`, default_unit_cost_with_tax);
      formRef.current?.setFieldValue(`equipments[${index}].total_sales_price`, default_unit_cost_with_tax * (currentQtd || 1));
    }

    updateTotal();
    updateTotalLaborEquip();
  }, [currentOptions, proposal]);

  const updateQtdEquipment = useCallback(async (qtd, index) => {
    let currentCost = await formRef.current?.getFieldValue(`equipments[${index}].unit_cost`);
    let total_of_points = await formRef.current?.getFieldValue(`labor_equipments[${index}].points`);
    const equipment_total_cost = currentCost * (qtd || 0);
    const equipment_cost_margin = equipment_total_cost + (equipment_total_cost * proposal?.margin);
    const equipment_cost_tax = equipment_cost_margin + (equipment_cost_margin * proposal?.tax);

    formRef.current?.setFieldValue(`equipments[${index}].total_cost`, equipment_total_cost);
    formRef.current?.setFieldValue(`equipments[${index}].cost_with_margin`, equipment_cost_margin);
    formRef.current?.setFieldValue(`equipments[${index}].cost_with_tax`, equipment_cost_tax);

    let labor_equipment_cost = await formRef.current?.getFieldValue(`labor_equipments[${index}].unit_cost`);
    const total_labor_equipment_cost = labor_equipment_cost * (parseFloat(total_of_points) || 0);
    formRef.current?.setFieldValue(`labor_equipments[${index}].total_cost`, (total_labor_equipment_cost * (qtd || 0)));

    const labor_equipment_cost_margin = total_labor_equipment_cost + (total_labor_equipment_cost * proposal?.margin);
    const labor_equipment_cost_tax = labor_equipment_cost_margin + (labor_equipment_cost_margin * proposal?.tax);
    formRef.current?.setFieldValue(`labor_equipments[${index}].cost_with_margin`, labor_equipment_cost_margin * (qtd || 0));
    formRef.current?.setFieldValue(`labor_equipments[${index}].cost_with_tax`, labor_equipment_cost_tax * (qtd || 0));

    formRef.current?.setFieldValue(`equipments[${index}].total_sales_price`, equipment_cost_tax);

    updateTotal();
    updateTotalLaborEquip();
  }, [currentOptions, proposal]);

  var updateUnitPriceEquipTimeout: any;
  const updateUnitPriceEquip = useCallback(async (price, index) => {
    clearTimeout(updateUnitPriceEquipTimeout);

    updateUnitPriceEquipTimeout = setTimeout(() => {
      const cost = formRef.current?.getFieldValue(`equipments[${index}].unit_cost`);

      // let cost = price.replace("R$", "");
      // cost = cost.replace(",", ".");
      const currentQtd = formRef.current?.getFieldValue(`equipments[${index}].qtd`);
      if (currentQtd) {
        const totalCost = parseFloat(cost);
        const costMargin = totalCost + (totalCost * proposal?.margin);
        const costTax = costMargin + (costMargin * proposal?.tax);
        formRef.current?.setFieldValue(`equipments[${index}].total_cost`, totalCost * currentQtd);
        formRef.current?.setFieldValue(`equipments[${index}].cost_with_margin`, costMargin * currentQtd);
        formRef.current?.setFieldValue(`equipments[${index}].cost_with_tax`, costTax * currentQtd);

        formRef.current?.setFieldValue(`equipments[${index}].unit_sales_price`, costTax);
        formRef.current?.setFieldValue(`equipments[${index}].total_sales_price`, costTax * currentQtd);
      }

      updateTotal();
    }, 1000);
  }, [currentOptions, proposal, updateQtdLaborEquipTimeout]);

  // LABOR EQUIP
  var updateQtdLaborEquipTimeout: any;
  const updateQtdLaborEquip = useCallback(async (qtd, index) => {
    clearTimeout(updateQtdLaborEquipTimeout);
    updateQtdLaborEquipTimeout = setTimeout(async () => {
      let currentQtd = qtd.toString().replace(",", ".");
      let currentCost = await formRef.current?.getFieldValue(`labor_equipments[${index}].unit_cost`);
      const totalCost = currentCost * (parseFloat(currentQtd) || 0);

      formRef.current?.setFieldValue(`labor_equipments[${index}].total_cost`, totalCost === 0 ? "0" : totalCost);

      const costMargin = totalCost + (totalCost * proposal?.margin);
      const costTax = costMargin + (costMargin * proposal?.tax);
      formRef.current?.setFieldValue(`labor_equipments[${index}].cost_with_margin`, costMargin === 0 ? "0" : costMargin);
      formRef.current?.setFieldValue(`labor_equipments[${index}].cost_with_tax`, costTax === 0 ? "0" : costTax);

      updateTotalLaborEquip();
    }, 1000);
  }, [currentOptions, proposal, updateQtdLaborEquipTimeout]);

  var updateUnitPriceLaborEquipTimeout: any;
  const updateUnitPriceLaborEquip = useCallback(async (price, index) => {
    clearTimeout(updateUnitPriceLaborEquipTimeout);

    updateUnitPriceLaborEquipTimeout = setTimeout(() => {
      const cost = formRef.current?.getFieldValue(`labor_equipments[${index}].unit_cost`);

      // let cost = price.replace("R$", "");
      // cost = cost.replace(",", ".");
      const currentQtd = formRef.current?.getFieldValue(`labor_equipments[${index}].points`).toString().replace(",", ".");

      if (currentQtd) {
        const totalCost = parseFloat(cost) * parseFloat(currentQtd);

        formRef.current?.setFieldValue(`labor_equipments[${index}].total_cost`, totalCost === 0 ? '0' : totalCost);

        const costMargin = totalCost + (totalCost * proposal?.margin);
        const costTax = costMargin + (costMargin * proposal?.tax);
        formRef.current?.setFieldValue(`labor_equipments[${index}].cost_with_margin`, costMargin === 0 ? '0' : costMargin);
        formRef.current?.setFieldValue(`labor_equipments[${index}].cost_with_tax`, costTax === 0 ? '0' : costTax);
      }

      updateTotalLaborEquip();
    }, 1000);
  }, [currentOptions, updateUnitPriceLaborEquipTimeout, proposal]);

  // MISCELLANIE
  const handleSelectMiscellanie = useCallback(async (data, index) => {
    if (data) {
      const itemAux = await currentOptions.miscellanies.filter((item: Miscellanies) => item.id === data.value)[0];
      const currentQtd = formRef.current?.getFieldValue(`miscellanies[${index}].qtd`);
      const totalCost = itemAux.cost * (currentQtd || 1);
      if (!currentQtd) {
        formRef.current?.setFieldValue(`miscellanies[${index}].qtd`, 1);
      }
      formRef.current?.setFieldValue(`miscellanies[${index}].description`, itemAux.description);
      formRef.current?.setFieldValue(`miscellanies[${index}].unit_cost`, itemAux.cost.toFixed(2));
      formRef.current?.setFieldValue(`miscellanies[${index}].total_cost`, totalCost.toFixed(2));

      const costMargin = totalCost + (totalCost * proposal?.margin);
      const costTax = costMargin + (costMargin * proposal?.tax);
      formRef.current?.setFieldValue(`miscellanies[${index}].cost_with_margin`, costMargin);
      formRef.current?.setFieldValue(`miscellanies[${index}].cost_with_tax`, costTax);
    }

    updateTotalMisc();
  }, [currentOptions, proposal]);

  const updateQtdMiscellanie = useCallback(async (qtd, index) => {
    const currentCost = formRef.current?.getFieldValue(`miscellanies[${index}].unit_cost`);
    if (currentCost) {
      const totalCost = currentCost * (qtd || 0);
      formRef.current?.setFieldValue(`miscellanies[${index}].total_cost`, totalCost.toFixed(2));

      const costMargin = totalCost + (totalCost * proposal?.margin);
      const costTax = costMargin + (costMargin * proposal?.tax);
      formRef.current?.setFieldValue(`miscellanies[${index}].cost_with_margin`, costMargin);
      formRef.current?.setFieldValue(`miscellanies[${index}].cost_with_tax`, costTax);
    }

    updateTotalMisc();
  }, [currentOptions, proposal]);

  var miscellanieTimeout: any;
  const updateUnitPriceMiscellanie = useCallback(async (price, index) => {
    clearTimeout(infrastructure_timeout);
    infrastructure_timeout = setTimeout(() => {
      const cost = formRef.current?.getFieldValue(`miscellanies[${index}].unit_cost`);

      // let cost = price.replace("R$", "");
      // cost = cost.replace(",", ".");
      const currentQtd = formRef.current?.getFieldValue(`miscellanies[${index}].qtd`);
      if (currentQtd) {
        const totalCost = cost * currentQtd;
        formRef.current?.setFieldValue(`miscellanies[${index}].total_cost`, totalCost.toFixed(2));

        const costMargin = totalCost + (totalCost * proposal?.margin);
        const costTax = costMargin + (costMargin * proposal?.tax);
        formRef.current?.setFieldValue(`miscellanies[${index}].cost_with_margin`, costMargin);
        formRef.current?.setFieldValue(`miscellanies[${index}].cost_with_tax`, costTax);
      }

      updateTotalMisc();
    }, 1000);
  }, [currentOptions, proposal, miscellanieTimeout]);

  // INFRA
  const handleSelectInfrastructure = useCallback(async (data, index) => {
    if (data) {
      const itemAux = await currentOptions.infrastructures.filter((item: Miscellanies) => item.id === data.value)[0];
      const currentQtd = formRef.current?.getFieldValue(`infrastructures[${index}].qtd`);
      const totalCost = itemAux.cost * (currentQtd || 1);
      if (!currentQtd) {
        formRef.current?.setFieldValue(`infrastructures[${index}].qtd`, 1);
      }
      formRef.current?.setFieldValue(`infrastructures[${index}].description`, itemAux.description);
      formRef.current?.setFieldValue(`infrastructures[${index}].unit_cost`, itemAux.cost.toFixed(2));
      formRef.current?.setFieldValue(`infrastructures[${index}].total_cost`, totalCost.toFixed(2));

      const costMargin = totalCost + (totalCost * proposal?.margin);
      const costTax = costMargin + (costMargin * proposal?.tax);
      formRef.current?.setFieldValue(`infrastructures[${index}].cost_with_margin`, costMargin);
      formRef.current?.setFieldValue(`infrastructures[${index}].cost_with_tax`, costTax);
    }

    updateTotalInfra();
  }, [currentOptions, proposal]);

  const updateQtdInfrastructure = useCallback(async (qtd, index) => {
    const currentCost = formRef.current?.getFieldValue(`infrastructures[${index}].unit_cost`);
    if (currentCost) {
      const totalCost = currentCost * (qtd || 0);
      formRef.current?.setFieldValue(`infrastructures[${index}].total_cost`, totalCost.toFixed(2));

      const costMargin = totalCost + (totalCost * proposal?.margin);
      const costTax = costMargin + (costMargin * proposal?.tax);
      formRef.current?.setFieldValue(`infrastructures[${index}].cost_with_margin`, costMargin);
      formRef.current?.setFieldValue(`infrastructures[${index}].cost_with_tax`, costTax);
    }

    updateTotalInfra();
  }, [currentOptions, proposal]);

  var infrastructure_timeout: any;
  const updateUnitPriceInfrastructure = useCallback(async (price, index) => {
    clearTimeout(infrastructure_timeout);
    infrastructure_timeout = setTimeout(() => {
      const cost = formRef.current?.getFieldValue(`infrastructures[${index}].unit_cost`);

      // let cost = price.replace("R$", "");
      // cost = cost.replace(",", ".");

      // console.log("COST ====> ", cost);

      const currentQtd = formRef.current?.getFieldValue(`infrastructures[${index}].qtd`);

      if (currentQtd) {
        const totalCost = cost * currentQtd;
        formRef.current?.setFieldValue(`infrastructures[${index}].total_cost`, totalCost.toFixed(2));

        const costMargin = totalCost + (totalCost * proposal?.margin);
        const costTax = costMargin + (costMargin * proposal?.tax);
        formRef.current?.setFieldValue(`infrastructures[${index}].cost_with_margin`, costMargin);
        formRef.current?.setFieldValue(`infrastructures[${index}].cost_with_tax`, costTax);
      }

      updateTotalInfra();
    }, 1000);
  }, [currentOptions, proposal, infrastructure_timeout]);

  var general_labors_timeout: any;
  const updatePriceGeneralLabors = useCallback(async (price, index) => {
    clearTimeout(general_labors_timeout);
    general_labors_timeout = setTimeout(() => {
      const cost = formRef.current?.getFieldValue(`general_labors[${index}].total_cost`);

      // let cost = price.replace("R$", "");
      // cost = cost.replace(".", "");
      // cost = cost.replace(".", "");
      // cost = cost.replace(".", "");
      // cost = cost.replace(",", ".");
      // cost = parseFloat(cost)

      // formRef.current?.setFieldValue(`general_labors[${index}].total_cost`, cost);

      var costMargin = cost + (cost * proposal?.margin);
      var costTax = costMargin + (costMargin * proposal?.tax);

      formRef.current?.setFieldValue(`general_labors[${index}].cost_with_margin`, costMargin);
      formRef.current?.setFieldValue(`general_labors[${index}].cost_with_tax`, costTax);

      updateTotalGeneralLabors();
    }, 1000);
  }, [currentOptions, proposal, general_labors_timeout]);

  // SERVICES
  const handleSelectService = async (data: any, index: number) => {
    if (data) {
      const itemAux = await currentOptions.services.filter((item: Service) => item.id === data.value)[0];
      formRef.current?.setFieldValue(`services[${index}].name`, itemAux.name);
      formRef.current?.setFieldValue(`services[${index}].description`, itemAux.description);
      formRef.current?.setFieldValue(`services[${index}].total_cost`, itemAux.value.toFixed(2));
    }
  };

  /* TOTAL VALUES */
  const updateTotal = () => {
    setTimeout(() => {
      const equipmentsAux: any = formRef.current?.getData();
      let price = 0.00;

      // console.log("UPDATE TOTAL ", equipmentsAux)
      equipmentsAux?.equipments?.map((item: Equipment) => {
        if (item.total_cost) {
          price = price + item.total_cost;
        }
      });
      setTotalCost(price);
    }, 1000);
  };

  const updateTotalLaborEquip = useCallback(() => {
    const equipmentsAux: any = formRef.current?.getData();
    let laborPrice = 0.00;
    equipmentsAux?.labor_equipments?.map((item: LaborEquipments) => {
      if (item.total_cost) {
        laborPrice = laborPrice + parseFloat(item.total_cost);
      }
    });
    setTotalLaborEquip(laborPrice);
  }, []);

  var generalLaborsTimeout: any;
  const updateTotalGeneralLabors = useCallback(() => {
    // clearTimeout(generalLaborsTimeout);

    // generalLaborsTimeout = setTimeout(() => {
    const itemAux: any = formRef.current?.getData();

    let laborPrice = 0.00;
    itemAux?.general_labors?.map((item: GeneralLabors) => {
      if (item.total_cost) {
        laborPrice = laborPrice + parseFloat(item.total_cost);
      }
    });
    setTotalGeneralLabor(laborPrice);
    // }, 1000);
  }, [generalLaborsTimeout]);

  const updateTotalMisc = useCallback(() => {
    const itemAux: any = formRef.current?.getData();
    let itemPrice = 0.00;
    itemAux?.miscellanies?.map((item: Miscellanies) => {
      if (item.total_cost) {
        itemPrice = itemPrice + parseFloat(item.total_cost);
      }
    });
    setTotalMisc(itemPrice);
  }, []);

  const updateTotalInfra = useCallback(() => {
    const itemAux: any = formRef.current?.getData();
    let itemPrice = 0.00;
    itemAux?.infrastructures?.map((item: Infrastructure) => {
      if (item.total_cost) {
        itemPrice = itemPrice + parseFloat(item.total_cost);
      }
    });
    setTotalInfra(itemPrice);
  }, []);
  /* END TOTAL VALUES */

  const calcTotalValue = () => {
    return (totalCost + totalLaborEquip + totalMisc + totalInfra + totalGeneralLabor);
  };

  const calcDiscountValue = () => {
    return (
      (
        (calcTotalValue() + calcTotalValue() * proposal?.margin) +
        (calcTotalValue() + (calcTotalValue() * proposal?.margin)) * proposal?.tax
      )
      *
      proposal?.discount
    );;
  };

  const calcComissionValue = () => {
    return (
      (
        (calcTotalValue() + calcTotalValue() * proposal?.margin) +
        (calcTotalValue() + (calcTotalValue() * proposal?.margin)) * proposal?.tax
      )
      *
      proposal?.comission
    );;
  };

  const refreshList = useCallback(async () => {
    setIsLoading(true)
    setRefresh(!refresh);
    refreshProposal();
    setTimeout(() => setIsLoading(false), 1000);
  }, [refresh]);

  const handleSubmit = useCallback(
    async (data) => {

      setSending(true);
      try {
        formRef.current?.setErrors({});

        let schema = Yup.object().shape({
          name: Yup.string().required('Nome obrigatório'),
          labor_title: Yup.string().nullable(),
          equipments: Yup.array().of(Yup.object().shape({
            name: Yup.string().required('Nome obrigatório'),
            description: Yup.string().nullable(),
            model: Yup.string().required('Modelo obrigatório'),
            maker: Yup.string().required('Fabricante obrigatório'),
            qtd: Yup.string().required('Quantidade obrigatória'),
            unit_cost: Yup.string().required('Preço unitário obrigatório'),
            photo_url: Yup.string().nullable(),
          })),
          labor_equipments: Yup.array().of(Yup.object().shape({
            name: Yup.string().required('Nome obrigatório'),
            points: Yup.string().required('Pontos obrigatório'),
            unit_cost: Yup.string().required('Valor obrigatório'),
          })),
          general_labors: Yup.array().of(Yup.object().shape({
            name: Yup.string().required('Nome obrigatório'),
            observation: Yup.string(),
            total_cost: Yup.string().required('Valor obrigatório'),
          })),
          miscellanies: Yup.array().of(Yup.object().shape({
            description: Yup.string().required('Nome obrigatório'),
            qtd: Yup.string().required('Quantidade obrigatória'),
            unit_cost: Yup.string().required('Valor obrigatório'),
          })),
          infrastructures: Yup.array().of(Yup.object().shape({
            description: Yup.string().required('Nome obrigatório'),
            qtd: Yup.string().required('Quantidade obrigatória'),
            unit_cost: Yup.string().required('Valor obrigatório'),
          })),
          services: Yup.array().of(Yup.object().shape({
            name: Yup.string().required('Nome obrigatório'),
            description: Yup.string(),
            total_cost: Yup.string().required('Valor obrigatório'),
          })),
          internal_observation: Yup.string(),
          product_observation: Yup.string(),
          services_observation: Yup.string(),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const response = await api.put(`proposals/products/${product.id}`, {
          ...data,
          equipments: data.equipments?.map((item: any) => {
            return {
              name: item.name,
              labor_title: item.labor_title,
              description: item.description,
              model: item.model,
              maker: item.maker,
              qtd: item.qtd,
              unit_cost: item.unit_cost,
              total_cost: item.total_cost,
              photo_url: item.photo_url && !!item.photo_url ? item.photo_url : null,
            }
          })
            || [],
          labor_equipments: data.labor_equipments?.map((item: any) => {
            return {
              name: item.name,
              points: parseFloat(item.points),
              unit_cost: item.unit_cost,
              total_cost: item.total_cost,
            }
          })
            || [],
          general_labors: data.general_labors?.map((item: any) => {
            return {
              name: item.name,
              observation: item.observation,
              total_cost: item.total_cost,
            }
          })
            || [],
          miscellanies: data.miscellanies?.map((item: any) => {
            return {
              description: item.description,
              qtd: item.qtd,
              unit_cost: item.unit_cost,
              total_cost: item.total_cost,
            }
          })
            || [],
          infrastructures: data.infrastructures?.map((item: any) => {
            return {
              description: item.description,
              qtd: item.qtd,
              unit_cost: item.unit_cost,
              total_cost: item.total_cost,
            }
          })
            || []
        });

        addToast({
          type: 'success',
          title: `Proposta atualizada`,
          description: response.data.success.message,
          timer: 6000
        });

        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth"
        });

        refreshProposal();

        setSending(false);
      } catch (err) {
        setSending(false);
        console.log(err);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          showYupErrorToast();
        } else {
          addToast({
            type: 'error',
            title: 'Falha na requisição',
            description:
              'Ocorreu um erro ao salvar, verifique os dados e tente novamente',
          });
        }
      }
    },
    [proposal, product],
  );

  const showYupErrorToast = useCallback(() => {
    addToast({
      type: 'error',
      title: 'Atenção!',
      description:
        'Preencha todos os campos obrigatórios'
    });
  }, []);

  const calcTotalEquipments = ({ type }: any) => {
    var value: any = 0.00;

    const formData: any = formRef.current?.getData();

    formData?.equipments?.map((equip: any) => {
      if (equip && equip.unit_cost) {
        if (type === "unit") {
          value = value + equip.unit_cost;
        } else if (type === "total") {
          const total = equip.unit_cost * (equip.qtd || 1);
          value = value + total;
        } else if (type === "unit_sales") {

          const costMargin = equip.unit_cost + (equip.unit_cost * proposal?.margin);
          const costTax = costMargin + (costMargin * proposal?.tax);

          value = value + costTax;

        } else if (type === "total_sales") {

          const costMargin = equip.unit_cost + (equip.unit_cost * proposal?.margin);
          const costTax = costMargin + (costMargin * proposal?.tax);

          value = value + (costTax * (equip.qtd || 1));

        } else if (type === "margin") {
          const withMargin = (equip.unit_cost + (equip.unit_cost * proposal?.margin)) * (equip.qtd || 1);
          value = value + withMargin;
        } else if (type === "tax") {
          const withMargin = (equip.unit_cost + (equip.unit_cost * proposal?.margin)) * (equip.qtd || 1);
          const withTax = (withMargin + (withMargin * proposal?.tax));
          value = value + withTax;
        }
      }
    });

    return value;
  };

  const calcTotalLaborEquipments = ({ type }: any) => {
    var value: any = 0;

    const formData: any = formRef.current?.getData();

    formData?.labor_equipments?.map((equip: any, index: number) => {
      const currentEquip = product?.equipments.filter((equipAux) => equipAux.name === equip.name);
      var qtd = currentEquip && currentEquip.length > 0 ? currentEquip[0].qtd : 0;

      if (equip && equip.unit_cost) {
        if (type === "total") {
          const total = equip.unit_cost * (equip.points || 1);
          value = value + (total * qtd);
        } else if (type === "margin") {
          const withMargin = (equip.unit_cost + (equip.unit_cost * proposal?.margin)) * parseFloat(equip.points);
          value = value + (withMargin * qtd);
        } else if (type === "tax") {
          const withMargin = equip.unit_cost + (equip.unit_cost * proposal?.margin);
          const withTax = (withMargin + (withMargin * proposal?.tax)) * parseFloat(equip.points);
          value = value + (withTax * qtd);
        }
      }
    });

    return value;
  };

  const calcTotalGeneralLabors = ({ type }: any) => {
    var value: any = 0;

    const formData: any = formRef.current?.getData();
    formData?.general_labors?.map((equip: any) => {
      if (equip && equip.total_cost) {
        if (type === "total") {
          const total = equip.total_cost;
          value = value + total;
        } else if (type === "margin") {
          const withMargin = (equip.total_cost + (equip.total_cost * proposal?.margin));
          value = value + withMargin;
        } else if (type === "tax") {
          const withMargin = equip.total_cost + (equip.total_cost * proposal?.margin);
          const withTax = (withMargin + (withMargin * proposal?.tax));
          value = value + withTax;
        }
      }
    });

    return value;
  };

  const calcTotalMiscellanies = ({ type }: any) => {
    var value: any = 0;

    const formData: any = formRef.current?.getData();

    formData?.miscellanies?.map((equip: any) => {
      if (equip && equip.unit_cost) {
        if (type === "unit") {
          value = value + equip.unit_cost;
        } else if (type === "total") {
          const total = equip.unit_cost * parseInt(equip.qtd);
          value = value + total;
        } else if (type === "margin") {
          const withMargin = (equip.unit_cost + (equip.unit_cost * proposal?.margin)) * parseInt(equip.qtd);
          value = value + withMargin;
        } else if (type === "tax") {
          const withMargin = (equip.unit_cost + (equip.unit_cost * proposal?.margin)) * parseInt(equip.qtd);
          const withTax = (withMargin + (withMargin * proposal?.tax));
          value = value + withTax;
        }
      }
    });

    return value;
  };

  const calcTotalInfrastructures = ({ type }: any) => {
    var value: any = 0;

    const formData: any = formRef.current?.getData();

    formData?.infrastructures?.map((equip: any) => {
      if (equip && equip.unit_cost) {
        if (type === "unit") {
          value = value + equip.unit_cost;
        } else if (type === "total") {
          // const total = equip.unit_cost * parseInt(equip.qtd);
          value = value + equip.total_cost;
        } else if (type === "margin") {
          const withMargin = (equip.total_cost + (equip.total_cost * proposal?.margin));
          value = value + withMargin;
        } else if (type === "tax") {
          const withMargin = (equip.total_cost + (equip.total_cost * proposal?.margin));
          const withTax = (withMargin + (withMargin * proposal?.tax));
          value = value + withTax;
        }
      }
    });

    return value;
  };

  return (
    <Container>
      <Form
        onSubmit={handleSubmit}
        ref={formRef}
      // initialData={proposal ? proposal : undefined}
      >

        <Input name="name" label="Nome do produto" fullWidth />

        {/* PRODUTO */}

        <h3>Informe os equipamentos</h3>
        <div className="item-block">
          <table>
            <thead>
              <tr>
                <td>Equipamento</td>
                <td width="160px">Modelo</td>
                <td width="160px">Fabricante</td>
                <td width="160px">Descrição téc.</td>
                <td width="80px">Qtd.</td>
                <td width="160px">Custo un.</td>
                <td width="160px">Custo total</td>
                <td width="160px">Valor un. (venda)</td>
                <td width="160px">Valor Total (venda)</td>
                <td width="160px">Total + Margem</td>
                <td width="160px">Total + Imposto</td>
                <td width="60px"></td>
              </tr>
            </thead>
            <tbody>
              {equipments.map((prod, index) => (
                <tr key={index.toString()}>
                  <td className="flex">
                    <Input
                      onFocus={() => {
                        setSearchModalCurrentIndex(index);
                        setSearchModalAttrName(`equipments`);
                        setSearchModalItemName("Produto");
                        setSearchModalCurrentRoute("/produtos/equipamentos/novo");
                        setShowSearchModal(true);
                      }}
                      name={`equipments[${index}].name`}
                      placeholder="Informe um produto"
                      fullWidth
                    />
                    <Input
                      name={`equipments[${index}].photo_url`}
                      isHidden
                    />
                  </td>
                  <td><Input name={`equipments[${index}].model`} fullWidth /></td>
                  <td><Input name={`equipments[${index}].maker`} fullWidth /></td>
                  <td><Input name={`equipments[${index}].description`} fullWidth /></td>
                  <td>
                    <Input
                      name={`equipments[${index}].qtd`}
                      type="number"
                      fullWidth
                      min={1}
                      onChange={e => updateQtdEquipment(e.currentTarget.value, index)}
                    />
                  </td>
                  <td>
                    <InputNumber
                      name={`equipments[${index}].unit_cost`}
                      fullWidth
                      thousandSeparator={'.'}
                      decimalSeparator=","
                      fixedDecimalScale={true}
                      decimalScale={2}
                      prefix="R$ "
                      onChange={(e) => updateUnitPriceEquip(e.currentTarget.value, index)}
                    />
                  </td>
                  <td>
                    <InputNumber
                      name={`equipments[${index}].total_cost`}
                      fullWidth
                      thousandSeparator={'.'}
                      decimalSeparator=","
                      fixedDecimalScale={true}
                      decimalScale={2}
                      prefix="R$ "
                      disabled
                    />
                  </td>

                  <td>
                    <InputNumber
                      name={`equipments[${index}].unit_sales_price`}
                      fullWidth
                      thousandSeparator={'.'}
                      decimalSeparator=","
                      fixedDecimalScale={true}
                      decimalScale={2}
                      prefix="R$ "
                      disabled
                    />
                  </td>
                  <td>
                    <InputNumber
                      name={`equipments[${index}].total_sales_price`}
                      fullWidth
                      thousandSeparator={'.'}
                      decimalSeparator=","
                      fixedDecimalScale={true}
                      decimalScale={2}
                      prefix="R$ "
                      disabled
                    />
                  </td>
                  <td>
                    <InputNumber
                      name={`equipments[${index}].cost_with_margin`}
                      fullWidth
                      thousandSeparator={'.'}
                      decimalSeparator=","
                      fixedDecimalScale={true}
                      decimalScale={2}
                      prefix="R$ "
                      disabled
                    />
                  </td>
                  <td>
                    <InputNumber
                      name={`equipments[${index}].cost_with_tax`}
                      fullWidth
                      thousandSeparator={'.'}
                      decimalSeparator=","
                      fixedDecimalScale={true}
                      decimalScale={2}
                      prefix="R$ "
                      disabled
                    />
                  </td>
                  <td>
                    <button
                      className="remove-btn"
                      onClick={() => handleRemoveItem('equipment', index)}
                      // disabled={equipments.length === 1}
                      type="button"
                    >
                      <FiMinus />
                    </button>
                  </td>
                </tr>
              ))}
              <tr className="total-tr">
                <td><strong>TOTAL</strong></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td>{!canLoadTotal ? "..." : calcTotalEquipments({ type: 'unit' }).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</td>
                <td>{!canLoadTotal ? "..." : calcTotalEquipments({ type: 'total' }).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</td>
                <td>{!canLoadTotal ? "..." : calcTotalEquipments({ type: 'unit_sales' }).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</td>
                <td>{!canLoadTotal ? "..." : calcTotalEquipments({ type: 'total_sales' }).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</td>
                <td>{!canLoadTotal ? "..." : calcTotalEquipments({ type: 'margin' }).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</td>
                <td>{!canLoadTotal ? "..." : calcTotalEquipments({ type: 'tax' }).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="buttons">
          <Button className="new-btn" onClick={() => handleNewItem('equipment')} type="button">Novo</Button>
        </div>

        {/* MÃO DE OBRA POR EQUIPAMENTO */}
        <h3>Mão de obra por equipamento</h3>
        <div className="item-block">
          <table>
            <thead>
              <tr>
                <td>Mão de obra</td>
                <td width="160px">Pontos</td>
                <td width="160px">Valor do ponto</td>
                <td width="160px">Custo total</td>
                <td width="160px">Total + Margem</td>
                <td width="160px">Total + Imposto</td>
              </tr>
            </thead>
            <tbody>
              {laborEquipments.map((labor, index) => (
                <tr key={index.toString()}>
                  <td><Input name={`labor_equipments[${index}].name`} fullWidth disabled /></td>
                  <td>
                    <Input
                      name={`labor_equipments[${index}].points`}
                      fullWidth
                      type="number"
                      step={'any'}
                      min={0}
                      onChange={(e) => updateQtdLaborEquip(e.currentTarget.value, index)}
                    />
                  </td>
                  <td>
                    <InputNumber
                      name={`labor_equipments[${index}].unit_cost`}
                      fullWidth
                      thousandSeparator={'.'}
                      decimalSeparator=","
                      fixedDecimalScale={true}
                      decimalScale={2}
                      prefix="R$ "
                      onChange={(e) => updateUnitPriceLaborEquip(e.currentTarget.value, index)}
                    // disabled
                    />
                  </td>
                  <td>
                    <InputNumber
                      name={`labor_equipments[${index}].total_cost`}
                      fullWidth
                      thousandSeparator={'.'}
                      decimalSeparator=","
                      fixedDecimalScale={true}
                      decimalScale={2}
                      prefix="R$ "
                      disabled
                    />
                  </td>
                  <td>
                    <InputNumber
                      name={`labor_equipments[${index}].cost_with_margin`}
                      fullWidth
                      thousandSeparator={'.'}
                      decimalSeparator=","
                      fixedDecimalScale={true}
                      decimalScale={2}
                      prefix="R$ "
                      disabled
                    />
                  </td>
                  <td>
                    <InputNumber
                      name={`labor_equipments[${index}].cost_with_tax`}
                      fullWidth
                      thousandSeparator={'.'}
                      decimalSeparator=","
                      fixedDecimalScale={true}
                      decimalScale={2}
                      prefix="R$ "
                      disabled
                    />
                  </td>
                </tr>
              ))}
              <tr className="total-tr">
                <td><strong>TOTAL</strong></td>
                <td></td>
                <td></td>
                <td>{!canLoadTotal ? "..." : calcTotalLaborEquipments({ type: "total" }).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</td>
                <td>{!canLoadTotal ? "..." : calcTotalLaborEquipments({ type: "margin" }).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</td>
                <td>{!canLoadTotal ? "..." : calcTotalLaborEquipments({ type: "tax" }).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</td>
              </tr>
            </tbody>
          </table>
        </div>

        {/* MÃO DE OBRA GERAL */}
        <h3>Mão de obra geral</h3>
        <div className="item-block">
          <table>
            <thead>
              <tr>
                <td>Mão de obra</td>
                <td>Observação</td>
                <td width="160px">Custo total</td>
                <td width="160px">Total + Margem</td>
                <td width="160px">Total + Imposto</td>
                <td width="60px"></td>
              </tr>
            </thead>
            <tbody>
              {generalLabors.map((labor, index) => (
                <tr key={index.toString()}>
                  <td><Input name={`general_labors[${index}].name`} fullWidth /></td>
                  <td><Input name={`general_labors[${index}].observation`} fullWidth /></td>
                  <td>
                    <InputNumber
                      name={`general_labors[${index}].total_cost`}
                      fullWidth
                      thousandSeparator={'.'}
                      decimalSeparator=","
                      fixedDecimalScale={true}
                      decimalScale={2}
                      prefix="R$ "
                      onChange={(e) => {
                        // updateTotalGeneralLabors();
                        updatePriceGeneralLabors(e.currentTarget.value, index);
                      }}
                    />
                  </td>
                  <td>
                    <InputNumber
                      name={`general_labors[${index}].cost_with_margin`}
                      fullWidth
                      thousandSeparator={'.'}
                      decimalSeparator=","
                      fixedDecimalScale={true}
                      decimalScale={2}
                      prefix="R$ "
                      disabled
                    />
                  </td>
                  <td>
                    <InputNumber
                      name={`general_labors[${index}].cost_with_tax`}
                      fullWidth
                      thousandSeparator={'.'}
                      decimalSeparator=","
                      fixedDecimalScale={true}
                      decimalScale={2}
                      prefix="R$ "
                      disabled
                    />
                  </td>
                  <td>
                    <button
                      className="remove-btn"
                      onClick={() => handleRemoveItem('general_labors', index)}
                      // disabled={generalLabors.length === 1}
                      type="button"
                    >
                      <FiMinus />
                    </button>
                  </td>
                </tr>
              ))}
              <tr className="total-tr">
                <td><strong>TOTAL</strong></td>
                <td></td>
                <td>{!canLoadTotal ? "..." : calcTotalGeneralLabors({ type: "total" }).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</td>
                <td>{!canLoadTotal ? "..." : calcTotalGeneralLabors({ type: "margin" }).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</td>
                <td>{!canLoadTotal ? "..." : calcTotalGeneralLabors({ type: "tax" }).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="buttons">
          <Button type="button" className="new-btn" onClick={() => handleNewItem('general_labors')}>Novo</Button>
        </div>

        {/* MISCELÂNEAS */}
        <h3>Miscelâneas</h3>
        <div className="item-block">
          <table>
            <thead>
              <tr>
                <td>Miscelânea</td>
                <td width="160px">Quantidade</td>
                <td width="160px">Valor un.</td>
                <td width="160px">Valor total</td>
                <td width="160px">Total + Margem</td>
                <td width="160px">Total + Imposto</td>
                <td width="60px"></td>
              </tr>
            </thead>
            <tbody>
              {miscellanies.map((labor, index) => (
                <tr key={index.toString()}>
                  <td className="flex">
                    <Input
                      onFocus={() => {
                        setSearchModalCurrentIndex(index);
                        setSearchModalAttrName(`miscellanies`);
                        setSearchModalItemName("Miscelânea");
                        setSearchModalCurrentRoute("/produtos/miscelaneas/novo");
                        setShowSearchModal(true);
                      }}
                      name={`miscellanies[${index}].description`}
                      placeholder="Informe uma miscelânea"
                      fullWidth
                    />
                  </td>
                  <td>
                    <Input
                      name={`miscellanies[${index}].qtd`}
                      type="number"
                      fullWidth
                      min={1}
                      onChange={e => updateQtdMiscellanie(e.currentTarget.value, index)}
                    />
                  </td>
                  <td>
                    <InputNumber
                      name={`miscellanies[${index}].unit_cost`}
                      fullWidth
                      thousandSeparator={'.'}
                      decimalSeparator=","
                      fixedDecimalScale={true}
                      decimalScale={2}
                      prefix="R$ "
                      onChange={(e) => updateUnitPriceMiscellanie(e.currentTarget.value, index)}
                    />
                  </td>
                  <td>
                    <InputNumber
                      name={`miscellanies[${index}].total_cost`}
                      fullWidth
                      thousandSeparator={'.'}
                      decimalSeparator=","
                      fixedDecimalScale={true}
                      decimalScale={2}
                      prefix="R$ "
                      disabled
                    />
                  </td>
                  <td>
                    <InputNumber
                      name={`miscellanies[${index}].cost_with_margin`}
                      fullWidth
                      thousandSeparator={'.'}
                      decimalSeparator=","
                      fixedDecimalScale={true}
                      decimalScale={2}
                      prefix="R$ "
                      disabled
                    />
                  </td>
                  <td>
                    <InputNumber
                      name={`miscellanies[${index}].cost_with_tax`}
                      fullWidth
                      thousandSeparator={'.'}
                      decimalSeparator=","
                      fixedDecimalScale={true}
                      decimalScale={2}
                      prefix="R$ "
                      disabled
                    />
                  </td>
                  <td>
                    <button
                      className="remove-btn"
                      onClick={() => handleRemoveItem('miscellanies', index)}
                      type="button"
                    >
                      <FiMinus />
                    </button>
                  </td>
                </tr>
              ))}
              <tr className="total-tr">
                <td><strong>TOTAL</strong></td>
                <td></td>
                <td>{!canLoadTotal ? "..." : calcTotalMiscellanies({ type: "unit" }).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</td>
                <td>{!canLoadTotal ? "..." : calcTotalMiscellanies({ type: "total" }).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</td>
                <td>{!canLoadTotal ? "..." : calcTotalMiscellanies({ type: "margin" }).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</td>
                <td>{!canLoadTotal ? "..." : calcTotalMiscellanies({ type: "tax" }).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="buttons">
          <Button type="button" className="new-btn" onClick={() => handleNewItem('miscellanies')}>Novo</Button>
        </div>

        {/* INFRAESTRUTURA */}
        <h3>Infraestrutura</h3>
        <div className="item-block">
          <table>
            <thead>
              <tr>
                <td>Infraestrutura</td>
                <td width="160px">Quantidade</td>
                <td width="160px">Valor un.</td>
                <td width="160px">Valor total</td>
                <td width="160px">Total + Margem</td>
                <td width="160px">Total + Imposto</td>
                <td width="60px"></td>
              </tr>
            </thead>
            <tbody>
              {infrastructures.map((labor, index) => (
                <tr key={index.toString()}>
                  <td className="flex">
                    <Input
                      onFocus={() => {
                        setSearchModalCurrentIndex(index);
                        setSearchModalAttrName(`infrastructures`);
                        setSearchModalItemName("Infraestrutura");
                        setSearchModalCurrentRoute("/produtos/infraestruturas/novo");
                        setShowSearchModal(true);
                      }}
                      name={`infrastructures[${index}].description`}
                      placeholder="Informe uma infraestrutura"
                      fullWidth
                    />
                  </td>
                  <td>
                    <Input
                      name={`infrastructures[${index}].qtd`}
                      type="number"
                      fullWidth
                      min={1}
                      onChange={e => updateQtdInfrastructure(e.currentTarget.value, index)}
                    />
                  </td>
                  <td>
                    <InputNumber
                      name={`infrastructures[${index}].unit_cost`}
                      fullWidth
                      thousandSeparator={'.'}
                      decimalSeparator=","
                      fixedDecimalScale={true}
                      decimalScale={2}
                      prefix="R$ "
                      onChange={(e) => updateUnitPriceInfrastructure(e.currentTarget.value, index)}
                    />
                  </td>
                  <td>
                    <InputNumber
                      name={`infrastructures[${index}].total_cost`}
                      fullWidth
                      thousandSeparator={'.'}
                      decimalSeparator=","
                      fixedDecimalScale={true}
                      decimalScale={2}
                      prefix="R$ "
                      disabled
                    />
                  </td>
                  <td>
                    <InputNumber
                      name={`infrastructures[${index}].cost_with_margin`}
                      fullWidth
                      thousandSeparator={'.'}
                      decimalSeparator=","
                      fixedDecimalScale={true}
                      decimalScale={2}
                      prefix="R$ "
                      disabled
                    />
                  </td>
                  <td>
                    <InputNumber
                      name={`infrastructures[${index}].cost_with_tax`}
                      fullWidth
                      thousandSeparator={'.'}
                      decimalSeparator=","
                      fixedDecimalScale={true}
                      decimalScale={2}
                      prefix="R$ "
                      disabled
                    />
                  </td>
                  <td>
                    <button
                      className="remove-btn"
                      onClick={() => handleRemoveItem('infrastructures', index)}
                      type="button"
                    >
                      <FiMinus />
                    </button>
                  </td>
                </tr>
              ))}
              <tr className="total-tr">
                <td><strong>TOTAL</strong></td>
                <td></td>
                <td>{!canLoadTotal ? "..." : calcTotalInfrastructures({ type: "unit" }).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</td>
                <td>{!canLoadTotal ? "..." : calcTotalInfrastructures({ type: "total" }).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</td>
                <td>{!canLoadTotal ? "..." : calcTotalInfrastructures({ type: "margin" }).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</td>
                <td>{!canLoadTotal ? "..." : calcTotalInfrastructures({ type: "tax" }).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="buttons">
          <Button type="button" className="new-btn" onClick={() => handleNewItem('infrastructures')}>Novo</Button>
        </div>

        <h3>Resultados deste produto</h3>
        <table className="results">
          <tbody>
            <tr>
              <td>CUSTO MÃO DE OBRA + EQUIPAMENTOS
                <span>
                  {!canLoadTotal ? "..." : (calcTotalValue()).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                </span>
              </td>
              <td></td>
              {/* <td>VALOR DO IMPOSTO ({tax * 100}%) */}
              <td>VALOR DO IMPOSTO (15%)
                <span>
                  {!canLoadTotal ? "..." : (
                    ((calcTotalValue() + calcTotalValue() * proposal?.margin) +
                      (calcTotalValue() + (calcTotalValue() * proposal?.margin)) * proposal?.tax) * 0.15
                  ).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                </span>
              </td>
            </tr>
            <tr>
              <td>CUSTO + MARGEM DE {proposal?.margin * 100}%
                <span>
                  {!canLoadTotal ? "..." : (
                    calcTotalValue() + calcTotalValue() * proposal?.margin
                  ).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                </span>
              </td>
              <td></td>
              <td>LUCRO PRESUMIDO (MARGEM)
                <span>
                  {!canLoadTotal ? "..." : ((calcTotalValue() +
                    calcTotalValue() * proposal?.margin
                  ) - calcTotalValue()
                  ).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                </span>
              </td>
            </tr>
            <tr>
              <td>TOTAL + IMPOSTO DE {proposal?.tax * 100}%
                <span>
                  {!canLoadTotal ? "..." : ((calcTotalValue() + calcTotalValue() * proposal?.margin) +
                    (calcTotalValue() + (calcTotalValue() * proposal?.margin)) * proposal?.tax
                  ).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                </span>
              </td>
              <td></td>
              <td>LUCRO REAL OBTIDO
                <span>
                  {!canLoadTotal ? "..." : (

                    // TOTAL + IMPOSTO DE X %
                    (
                      (calcTotalValue() + calcTotalValue() * proposal?.margin) +
                      (calcTotalValue() + (calcTotalValue() * proposal?.margin)) * proposal?.tax
                    ) -
                    // SUBTRAINDO O CUSTO MÃO DE OBRA + EQUIPAMENTOS
                    calcTotalValue() -
                    // SUBTRAINDO O VALOR DO IMPOSTO DE 15%
                    (
                      ((calcTotalValue() + calcTotalValue() * proposal?.margin) +
                        (calcTotalValue() + (calcTotalValue() * proposal?.margin)) * proposal?.tax) * 0.15
                    ) -
                    // SUBTRAINDO O VALOR DE DESCONTO
                    calcDiscountValue() -
                    // SUBTRAINDO O VALOR DA COMISSÃO
                    calcComissionValue()
                  ).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                </span>
              </td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td>DESCONTO DE {proposal?.discount * 100}%
                <span>
                  {
                    !canLoadTotal ? "..." :
                      (calcDiscountValue()).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
                  }
                </span>
              </td>
            </tr>
            <tr>
              <td style={{ backgroundColor: '#fff' }}></td>
              <td></td>
              <td>COMISSÃO {proposal?.comission * 100}%
                <span>
                  {
                    !canLoadTotal ? "..." :
                      (calcComissionValue()).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
                  }
                </span>
              </td>
            </tr>
          </tbody>
        </table>

        {/* SERVIÇOS */}
        <h3>Serviços</h3>
        <div className="item-block">
          <table>
            <thead>
              <tr>
                <td>Serviços</td>
                <td>Descrição</td>
                <td width="160px">Valor</td>
                <td width="60px"></td>
              </tr>
            </thead>
            <tbody>
              {services.map((service, index) => (
                <tr key={index.toString()}>
                  <td className="flex">
                    <Input
                      onFocus={() => {
                        setSearchModalCurrentIndex(index);
                        setSearchModalAttrName(`services`);
                        setSearchModalItemName("Serviço");
                        setSearchModalCurrentRoute("/servicos/novo");
                        setShowSearchModal(true);
                      }}
                      name={`services[${index}].name`}
                      placeholder="Informe um serviço"
                      fullWidth
                    />
                  </td>
                  <td><Input name={`services[${index}].description`} fullWidth /></td>
                  <td>
                    <InputNumber
                      name={`services[${index}].total_cost`}
                      fullWidth
                      thousandSeparator={'.'}
                      decimalSeparator=","
                      fixedDecimalScale={true}
                      decimalScale={2}
                      prefix="R$ "
                    />
                  </td>
                  <td>
                    <button
                      className="remove-btn"
                      onClick={() => handleRemoveItem('services', index)}
                      type="button"
                    >
                      <FiMinus />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="buttons">
          <Button type="button" className="new-btn" onClick={() => handleNewItem('services')}>Novo</Button>
        </div>

        <Input name="labor_title" label="Texto da mão de obra" placeholder="Personalize o texto da mão de obra" fullWidth />
        <Textarea name="product_observation" label="Observação do produto" fullWidth />
        <Textarea name="internal_observation" label="Observação interna" fullWidth />
        <Textarea name="services_observation" label="Observações dos serviços mensais" fullWidth />

        <div className="total-value">
          <div>
            <strong>
              Valor total do(a) {product.name} com todos os descontos aplicados:
            </strong>
            {
              !canLoadTotal ? "..." :
                (
                  // Valor total com proposal?.taxas aplicadas
                  ((calcTotalValue() + calcTotalValue() * proposal?.margin) +
                    (calcTotalValue() + (calcTotalValue() * proposal?.margin)) * proposal?.tax)

                  -
                  // Valor da Comissão
                  (
                    ((calcTotalValue() + calcTotalValue() * proposal?.margin) +
                      (calcTotalValue() + (calcTotalValue() * proposal?.margin)) * proposal?.tax)
                    *
                    proposal.comission
                  )

                  -
                  // Valor do desconto
                  (
                    ((calcTotalValue() + calcTotalValue() * proposal?.margin) +
                      (calcTotalValue() + (calcTotalValue() * proposal?.margin)) * proposal?.tax)
                    *
                    proposal.discount
                  )
                ).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
          </div>
        </div>

        <div className="break-line" />
        <div className="buttons">
          <Button type="button" className='outline' onClick={previousStep}>Anterior</Button>
          <Button type="submit" disabled={sending}>{sending ? "Salvando..." : "Salvar"}</Button>
        </div>
      </Form>

      {
        showSearchModal && (
          <SearchModal
            isLoading={isLoading}
            refreshList={() => refreshList()}
            onClose={() => setShowSearchModal(false)}
            CurrentIndex={searchModalCurrentIndex}
            AttrName={searchModalAttrName}
            ItemName={searchModalItemName}
            CurrentRoute={searchModalCurrentRoute}
            CurrentOptions={currentOptions}
            onSelectItem={(value, index) => {
              switch (searchModalAttrName) {
                case 'equipments':
                  handleSelectEquipment(value, index);
                  break;
                case 'miscellanies':
                  handleSelectMiscellanie(value, index);
                  break;
                case 'infrastructures':
                  handleSelectInfrastructure(value, index);
                  break;
                case 'services':
                  handleSelectService(value, index);
                  break;

                default:
                  break;
              }

              setShowSearchModal(false);
            }}
          />
        )
      }
    </Container>
  );
}

export default FormProduct;
