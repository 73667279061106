import React, { useCallback, useRef, useState } from 'react';
import { AiOutlineCloseCircle } from 'react-icons/ai';

import { Container, Header, PopUp } from './styles';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import getValidationErrors from '../../../utils/getValidationErrors';
import * as Yup from 'yup';

import Button from '../../../components/Form/Button';
import Textarea from '../../Form/Textarea';

import { useToast } from '../../../hooks/Toast';

import api from '../../../services/api';

import Dropzone from 'react-dropzone-uploader';
import 'react-dropzone-uploader/dist/styles.css'

interface ModalProps {
  onClose(): void;
  proposalId: number;
}

const SendProposalModal: React.FC<ModalProps> = ({
  onClose,
  proposalId,
}) => {
  const modalFormRef = useRef<FormHandles>(null);

  const [sendingProposal, setSendingProposal] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState<any[]>([]);
  const [arrayAttachments, setArrayAttachments] = useState<any[]>([]);
  const { addToast } = useToast();

  const sendEmail = useCallback(async (data) => {
    setSendingProposal(true);
    try {
      data.files = arrayAttachments;

      let schema = Yup.object().shape({
        message: Yup.string().required('Descreva o texto do e-mail.'),
        files: Yup.array(),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      if (arrayAttachments.length === 0) {
        addToast({
          type: 'error',
          title: 'Atenção!',
          description: "Anexe o(s) documento(s) no corpo do e-mail.",
          timer: 6000
        });

        return;
      }

      await api.post(`/proposals/${proposalId}/send-proposal`, data)
        .then(res => {
          addToast({
            type: 'success',
            title: 'Pronto!',
            description: "Enviado com sucesso.",
            timer: 6000
          });
          setArrayAttachments([]);
          setSelectedFiles([]);
          setSendingProposal(false);
          onClose();
        })
        .catch(err => {
          setSendingProposal(false);
          addToast({
            type: 'error',
            title: 'Falha na requisição',
            description:
              err?.response.data.error.error_message ||
              'Ocorreu um erro ao enviar, verifique os dados e tente novamente',
            timer: 6000
          });
        });
    } catch (err) {
      setSendingProposal(false);
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);

        modalFormRef?.current?.setErrors(errors);
      } else {
        addToast({
          type: 'error',
          title: 'Falha na requisição',
          description:
            err?.response.data.error.error_message ||
            'Ocorreu um erro ao enviar, verifique os dados e tente novamente',
          timer: 6000
        });
      }
    }

  }, [arrayAttachments]);

  const handleAttachments = useCallback(({ meta, file }: any, status: any) => {
    console.log("file ==> ", file);
    console.log("status ==> ", status);

    if (status === "done") {
      selectedFiles.push({ meta, file });
      setSelectedFiles([...selectedFiles]);

      var reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onloadend = function () {
        arrayAttachments.push(reader.result);
        setArrayAttachments([...arrayAttachments]);
      }
    }

    if (status === "removed") {
      var files = selectedFiles.filter((fileAux: any) => fileAux.meta.id !== meta.id);
      setSelectedFiles([...files]);

      var reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onloadend = function () {
        const aux = arrayAttachments.filter((item) => item !== reader.result);
        setArrayAttachments([...aux]);
      }
    }
  }, [arrayAttachments, selectedFiles]);

  return (
    <Container>
      <PopUp>
        <Header>
          <h2>Formulário de envio de proposta</h2>
          <div onClick={onClose}><AiOutlineCloseCircle size={24} /></div>
        </Header>
        <Form onSubmit={sendEmail} ref={modalFormRef}>
          <Textarea name="message" label="Texto do e-mail" placeholder="Descreva o texto do e-mail" fullWidth />
          <Dropzone
            multiple
            onChangeStatus={handleAttachments}
            accept="application/pdf"
            inputContent="Arraste para anexar arquivo(s) ou clique para selecionar"
            inputWithFilesContent="Anexar arquivo(s)"
          />

          <div className="buttons">
            <Button type="submit" disabled={sendingProposal}>{sendingProposal ? "Enviando..." : "Enviar"}</Button>
          </div>
        </Form>
      </PopUp>
    </Container>
  );
}

export default SendProposalModal;
