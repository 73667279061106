import React from 'react';

import { Container } from './styles';
import { HiChevronLeft, HiChevronRight } from 'react-icons/hi';

interface PaginationData {
  pages: number;
  currentPage: number;
  setPage(p: number): void;
}

const Pagination: React.FC<PaginationData> = ({
  pages,
  currentPage,
  setPage,
}) => {
  return (
    <Container>
      <ul>
        <li
          onClick={() => setPage(currentPage - 1)}
          className={currentPage === 1 ? `disabled` : ''}
        >
          <HiChevronLeft size={22} />
        </li>
        {/* FIRST PAGE */}
        {currentPage >= 4 && <li onClick={() => setPage(1)}>1</li>}
        {currentPage > 4 && <li className="disabled">...</li>}
        {/* -- */}
        {/* PAGE - 2 */}
        {currentPage >= 3 && (
          <li onClick={() => setPage(currentPage - 2)}>{currentPage - 2}</li>
        )}
        {/* -- */}
        {/* PAGE -1 */}
        {currentPage >= 2 && (
          <li onClick={() => setPage(currentPage - 1)}>{currentPage - 1}</li>
        )}
        {/* -- */}
        {/* Current Page */}
        <li className="current">{currentPage}</li>
        {/* -- */}
        {/* PAGE +1 */}
        {currentPage <= pages - 1 && (
          <li onClick={() => setPage(currentPage + 1)}>{currentPage + 1}</li>
        )}
        {/* -- */}
        {/* PAGE +2 */}
        {currentPage <= pages - 2 && (
          <li onClick={() => setPage(currentPage + 2)}>{currentPage + 2}</li>
        )}
        {/* -- */}
        {/* LAST PAGE */}
        {currentPage < pages - 3 && <li className="disabled">...</li>}
        {currentPage <= pages - 3 && (
          <li onClick={() => setPage(pages)}>{pages}</li>
        )}
        {/* -- */}
        <li
          onClick={() => setPage(currentPage + 1)}
          className={currentPage === pages ? `disabled` : ''}
        >
          <HiChevronRight size={22} />
        </li>
      </ul>
    </Container>
  );
};

export default Pagination;
