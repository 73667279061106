import styled, { css } from 'styled-components';

import Tooltip from '../Tooltip';

interface ContainerProps {
  isErrored: boolean;
}

interface LabelProps {
  fullWidth: boolean;
}

export const Label = styled.div<LabelProps>`
  width: 100%;
  max-width: calc(50% - 15px);

  strong {
    font-weight: 500;
    margin-bottom: 2px;
    display: inline-block;
    font-size: 14px;
  }

  @media (max-width: 769px) {
    max-width: 100%;
  }

  ${props =>
    props.fullWidth &&
    css`
      max-width: 100%;
    `}
`;

export const Container = styled.div<ContainerProps>`
  height: 40px;
  border-radius: 5px;
  border: 1px solid #c6c6c6;
  background: #ffffff;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  padding: 0 10px 0 0;
  font-size: 0.8rem;

  ${props =>
    props.isErrored &&
    css`
      border-color: #c53030;
    `}

  > div:nth-child(1) {
    width: 100%;

    > div:not(.react-select__menu) {
      border: 0 !important;
      max-height: 38px;
      overflow: auto;
    }

    .react-select__control {
      box-shadow: none !important;

      .react-select__value-container {
        padding: 0 8px;
      }
    }
  }

  @media (max-width: 769px) {
    width: 100%;
  }
`;

export const Error = styled(Tooltip)`
  height: 20px;
  margin-left: 16px;

  svg {
    margin: 0;
  }

  span {
    background: #c53030;
    color: #fff;

    &::before {
      border-color: #c53030 transparent;
    }
  }
`;
