import styled from 'styled-components';

export const Container = styled.div`
  padding: 0 50px;
  place-items: center;
  flex-direction: column;

  @media (max-width: 769px) {
    padding: 0 15px;
  }
`;

export const FormContainer = styled.div`
  padding: 0 50px;
  place-items: center;
  flex-direction: column;

  @media (max-width: 769px) {
    padding: 0 15px;
  }

  form {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    position: relative;
    background: #fff;
    padding: 30px;
    border-radius: 5px;
    margin-bottom: 30px;

    button[type='submit'] {
      border-radius: 5px;
    }

    .break-line {
      width: 100%;
    }

    .close-btn {
      position: absolute;
      right: 0;
      top: -50px;
      padding: 0;
      line-height: 20px;
      background: transparent;
      border: 0;
      color: #181818;
    }
  }
`;
