import React, { useRef, useEffect, useState, useCallback } from 'react';
import NumberFormat, { NumberFormatProps } from 'react-number-format';
import { useField } from '@unform/core';
import { FiAlertCircle } from 'react-icons/fi';

import { Container, Error, Label } from './styles';

interface Props extends NumberFormatProps {
  name: string;
  fullWidth?: boolean;
  label?: string;
  prefix?: string;
  suffix?: string;
}

const InputNumber: React.FC<Props> = ({
  name,
  fullWidth = false,
  label,
  prefix,
  suffix,
  ...rest
}) => {
  const inputRef = useRef(null);
  const [isFocused, setIsFocused] = useState(false);
  const {
    fieldName,
    registerField,
    defaultValue,
    error,
    clearError,
  } = useField(name);

  const [currentValue, setCurrentValue] = useState(defaultValue);

  const handleInputFocus = useCallback(() => {
    clearError();
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);
  }, []);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      getValue: (ref: any) => {

        if (!ref || !ref.state || !ref.state.value) {
          return '';
        }
        let { value } = ref.state;

        if ((typeof value) === 'string' && !value?.includes('R$')) {
          return parseFloat(value);
        }

        if (prefix) {
          value = value.toString().replace(prefix, '');
        }
        if (suffix) {
          value = value.toString().replace(suffix, '');
        }
        if (value.toString().includes('.')) {
          value = value.toString().replace('.', '');
        }
        value = parseFloat(value.toString().replace(',', '.'));

        return value;
      },
      setValue: (ref: any, value: any) => {
        if (value) {
          setCurrentValue(value);
          ref.state.value = value;
          ref.state.numAsString = value.toString();
        }
      },
    });
  }, [fieldName, registerField, prefix, suffix]);

  return (
    <Label fullWidth={fullWidth} className='label'>
      {!!label && <strong>{label}</strong>}
      <Container isErrored={!!error} isFocused={isFocused} className="input">
        <NumberFormat
          id={name}
          ref={inputRef}
          value={currentValue}
          isNumericString
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          prefix={prefix}
          suffix={suffix}
          onValueChange={(e) => {
            setCurrentValue(e.floatValue);
          }}
          {...rest}
        />
        {error && (
          <Error title={error}>
            <FiAlertCircle color='c53030' size={20} />
          </Error>
        )}
      </Container>
    </Label>
  );
};
export default InputNumber;
