import React, { useCallback } from 'react';

import { useAuth } from '../../hooks/Auth';

import { Container, Content } from './styles';

import UserImg from '../../assets/default-image.png';

const Header: React.FC = () => {

  const { user } = useAuth();

  const initalName = useCallback((userName) => {
    userName = userName.split(' ');
    const initals = `${userName[0].charAt(0)}${userName.length > 1 ? userName[1].charAt(0) : ''}`;
    return initals;
  }, []);

  return (
    <Container>
      <Content >
        <div className="user-data">
          {/* <img src={UserImg} alt="Nome do usuário" /> */}
          <div className="user-pic">{initalName(user.name)}</div>
          <div>
            <h3>{user.name}</h3>
            <h5>{user.role.name}</h5>
          </div>
        </div>
      </Content>
    </Container>
  );
}

export default Header;
