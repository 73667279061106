import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import cep from 'cep-promise';
import { components } from 'react-select';
import { MdRefresh } from 'react-icons/md';

import getValidationErrors from '../../../utils/getValidationErrors';

import api from '../../../services/api';

import Input from '../../../components/Form/Input';
import Button from '../../../components/Form/Button';
import Select from '../../../components/Form/Select';
import InputMask from '../../../components/Form/InputMask';

import { Proposal } from '../../../interfaces/Proposal';

import { useToast } from '../../../hooks/Toast';

import { Customer } from '../../../interfaces/Customer';
import InputNumber from '../../../components/Form/NumberInput';
import Textarea from '../../../components/Form/Textarea';

interface StepProps {
  proposal?: Proposal | null;
  nextStep(): void;
  refreshProposal(proposal_id: number): void;
}

interface CustomerOptions {
  value: number;
  label: string;
}

const Step1: React.FC<StepProps> = ({ proposal, nextStep, refreshProposal }) => {
  const formRef = useRef<FormHandles>(null);

  const [phoneMask, setPhoneMask] = useState('(99) 9999-9999?');
  const [sending, setSending] = useState<boolean>(false);
  const [customersAllProps, setCustomersAllProps] = useState<Customer[]>([]);
  const [customers, setCustomers] = useState<CustomerOptions[]>([]);
  const [anyChange, setAnyChange] = useState<boolean>(false);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [fullLoaded, setFullLoaded] = useState<boolean>(false);
  const [customersLimit] = useState<number>(10);

  const { addToast } = useToast();

  const history = useHistory();

  useEffect(() => {
    api.get(`customers/all`)
      .then(res => {
        const aux = res.data.items.map((user: Customer) => {
          return { value: user.id, label: user.name }
        });

        setCustomers([...aux]);
        setCustomersAllProps([...res.data.items]);
      })
      .catch(err => {
        addToast({
          type: 'error',
          title: 'Falha na requisição',
          description:
            'Ocorreu um erro ao buscar, tente novamente',
          timer: 6000
        });
      });
  }, [refresh]);

  useEffect(() => {
    if (customers.length > 0 && proposal) {
      formRef?.current?.setFieldValue('customer_id', proposal.customer_id);
    }
  }, [customers]);

  useEffect(() => {
    if (proposal) {
      // console.log('proposal no step 1 ==> ', proposal)
      formRef.current?.setData(proposal);
      setTimeout(() => {
        setFullLoaded(true);
      }, 1500);
      // formRef.current?.setFieldValue('customer_logo', proposal?.customer_logo || proposal?.customer?.logo_url);
    }
  }, [proposal]);

  const handleCep = useCallback((e) => {
    var value = e.target.value;
    cep(value).then((response: any) => {
      formRef.current?.setFieldValue('customer_address', `${response.street}`);
      formRef.current?.setFieldValue('customer_city', response.city);
      formRef.current?.setFieldValue('customer_uf', response.state);
      formRef.current?.setFieldValue(
        'customer_neighborhood',
        `${response.neighborhood}`
      );
    });
  }, []);

  const convertToBase64 = useCallback((file) => {
    return new Promise((resolve) => {
      var reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onloadend = function () {
        var base64 = reader.result;
        resolve(base64);
      }
    });
  }, []);

  const handleSubmit = useCallback(
    async (data) => {
      if (proposal && !anyChange) {
        nextStep();
        return;
      }

      setSending(true);
      try {
        formRef.current?.setErrors({});

        let base64: any = ''
        if (data.customer_logo) {
          base64 = await convertToBase64(data.customer_logo);
        }

        if (data.has_unit_values === "") {
          delete data.has_unit_values;
        }

        if (data.has_total_values === "") {
          delete data.has_total_values;
        }

        if (data.has_summary_of_values === "") {
          delete data.has_summary_of_values;
        }

        let schema = Yup.object().shape({
          title: Yup.string().required('Nome obrigatório'),
          responsible_name: Yup.string().required('Responsável obrigatório'),
          customer_id: Yup.string().required('Nome obrigatório'),
          customer_email: Yup.string().email('Digite um e-mail válido').required('E-mail obrigatório'),
          customer_phone: Yup.string().required('Telefone obrigatório'),
          customer_cep: Yup.string().nullable(),
          customer_address: Yup.string().nullable(),
          customer_city: Yup.string().required('Cidade obrigatória'),
          customer_uf: Yup.string().required('Estado obrigatório'),
          customer_neighborhood: Yup.string().nullable(),
          customer_complement: Yup.string().nullable(),
          customer_address_number: Yup.string().nullable(),
          applied_margin: Yup.string().required('Margem obrigatória'),
          applied_tax: Yup.string().required('Imposto obrigatório'),
          applied_discount: Yup.string().required('Campo obrigatório'),
          comission: Yup.string().required('Campo obrigatório'),
          point_value: Yup.string().required('Campo obrigatório'),
          has_unit_values: Yup.boolean().required('Campo obrigatório'),
          has_total_values: Yup.boolean().required('Campo obrigatório'),
          has_summary_of_values: Yup.boolean().required('Campo obrigatório'),
          treatment_pronoun: Yup.string().required('Pronome de tratamento obrigatório'),
          company_treatment_pronoun: Yup.string().required('Pronome de tratamento da empresa obrigatório'),
          status: !proposal ? Yup.string() : Yup.string().required('Status obrigatorio'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        let response;
        if (proposal) {
          response = await api.put(`proposals/${proposal.id}`, {
            ...data,
            customer_logo: data.customer_logo ? base64 : undefined,
          });
        } else {
          response = await api.post('proposals', data);
        }
        addToast({
          type: 'success',
          title: `Registro ${proposal ? 'atualizado' : 'cadastrado'}`,
          description: response.data.success.message,
        });

        refreshProposal(response.data.success.item.id);

        setSending(false);
        nextStep();
      } catch (err) {
        setSending(false);
        console.log('err', err);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          addToast({
            type: 'error',
            title: 'Atenção!',
            description:
              'Preencha todos os campos obrigatórios',
            timer: 6000
          });
        } else {
          addToast({
            type: 'error',
            title: 'Falha na requisição',
            description:
              'Ocorreu um erro ao salvar, verifique os dados e tente novamente',
            timer: 6000
          });
        }
      }
    },
    [proposal, addToast, history, sending, anyChange],
  );

  const handleCustomerChange = useCallback(async (customer) => {

    if (customer) {
      const customer_aux = customersAllProps.filter((item) => item.id === customer.value);

      if (customer_aux && customer_aux.length > 0) {
        formRef?.current?.setFieldValue('customer_email', customer_aux[0].email);
        formRef?.current?.setFieldValue('customer_phone', customer_aux[0].phone);
        formRef?.current?.setFieldValue('customer_cep', customer_aux[0].cep);
        formRef?.current?.setFieldValue('customer_city', customer_aux[0].city);
        formRef?.current?.setFieldValue('customer_uf', customer_aux[0].uf);
        formRef?.current?.setFieldValue('customer_neighborhood', customer_aux[0].neighborhood);
        formRef?.current?.setFieldValue('customer_address_number', customer_aux[0].address_number);
        formRef?.current?.setFieldValue('customer_complement', customer_aux[0].complement);
        formRef?.current?.setFieldValue('customer_address', customer_aux[0].address);
        // formRef?.current?.setFieldValue('customer_logo', customer_aux[0].logo_url);
      }
    }
  }, [customersAllProps]);

  function handleHasChange() {
    setAnyChange(true);
  }

  const OptimizedList = (props: any): any => {
    return (
      <components.MenuList {...props}>
        {Array.isArray(props.children)
          ? props.children.slice(0, customersLimit)
          : props.children}
      </components.MenuList>
    );
  };

  return (
    <Form
      onSubmit={handleSubmit}
      ref={formRef}
    >

      <h3>Configurações gerais</h3>
      <Input name="title" label="Título da proposta" onChange={() => {
        if (fullLoaded) {
          handleHasChange();
        }
      }} />
      <Input name="responsible_name" label="Nome do responsável pela proposta" onChange={() => {
        if (fullLoaded) {
          handleHasChange();
        }
      }} />

      <Select
        name="treatment_pronoun"
        label="Pronome de tratamento"
        options={[
          { value: "Prezado Senhor", label: 'Prezado Senhor' },
          { value: "Prezada Senhora", label: 'Prezada Senhora' },
        ]}
        placeholder="Escolher..."
        onChange={() => {
          if (fullLoaded) {
            handleHasChange();
          }
        }}
      />

      <Select
        name="company_treatment_pronoun"
        label="Pronome de tratamento da empresa"
        options={[
          { value: "AO", label: 'AO' },
          { value: "À", label: 'À' },
        ]}
        placeholder="Escolher..."
        onChange={() => {
          if (fullLoaded) {
            handleHasChange();
          }
        }}
      />

      {
        proposal && (
          <Select
            name="status"
            label="Status"
            options={[
              { value: "Pendente", label: 'Pendente' },
              { value: "Aprovada", label: 'Aprovada' },
              { value: "Alterada", label: 'Alterada' },
              { value: "Cancelada", label: 'Cancelada' },
            ]}
            placeholder="Escolher..."
            onChange={() => {
              if (fullLoaded) {
                handleHasChange();
              }
            }}
          />
        )
      }


      <h3>Informe o cliente <a href="/clientes/novo" target="_blank">Cadastrar novo</a></h3>

      <div className="field-block">
        {
          customers.length > 0 ? (
            <Select
              name="customer_id"
              label="Selecione o cliente"
              options={
                customers.length > 0 ?
                  [{ value: 0, label: `Exibindo apenas os ${customersLimit} primeiros. Digite para buscar mais...` }]
                    .concat(customers) :
                  customers
              }
              placeholder="Pesquise por um cliente..."
              noOptionsMessage={() => "Sem resultados..."}
              onChange={handleCustomerChange}
              isSearchable
              components={{ MenuList: OptimizedList }}
              isOptionDisabled={(opt) => opt.value === 0}
              fullWidth
            />
          ) : (
            <Input
              name="customer_id"
              label="Selecione o cliente"
              placeholder="Nenhum cliente encontrado..."
              disabled
              fullWidth
            />
          )
        }

        <Button type="button" title="Atualizar lista de clientes" onClick={() => setRefresh(!refresh)}><MdRefresh size={22} /></Button>
      </div>

      {/* <InputFile name="customer_logo" label="Logo" /> */}
      <Input
        name="customer_email"
        type="email"
        label="E-mail do cliente"
        onChange={() => {
          if (fullLoaded) {
            handleHasChange();
          }
        }}
      />
      <InputMask
        name="customer_phone"
        label="Telefone"
        mask={phoneMask}
        formatChars={{ '9': '[0-9]', '?': '[0-9 ]' }}
        maskChar={null}
        onChange={(event: any) => {
          if (fullLoaded) {
            handleHasChange();
          }

          if (event.target.value.length >= 15) {
            setPhoneMask('(99) 99999-9999');
          } else {
            setPhoneMask('(99) 9999-9999?');
          }
        }}
      />
      <InputMask
        name="customer_cep"
        label="CEP"
        mask="99999-999"
        formatChars={{ '9': '[0-9]' }}
        maskChar={null}
        onChange={(event) => {
          if (fullLoaded) {
            handleHasChange();
          }

          if (event.target.value.length >= 9) {
            handleCep(event);
          }
        }}
      />
      <Input name="customer_uf" label="UF" onChange={() => handleHasChange()} />
      <Input name="customer_city" label="Cidade" onChange={() => handleHasChange()} />
      <Input name="customer_neighborhood" label="Bairro" onChange={() => handleHasChange()} />
      <Input name="customer_address" label="Endereço" onChange={() => handleHasChange()} />
      <Input name="customer_complement" label="Complemento" onChange={() => handleHasChange()} />
      <Input name="customer_address_number" type="number" label="Número" onChange={() => handleHasChange()} />

      <h3>Configurações de taxas</h3>
      <Input step={'any'} name="applied_margin" type="number" label="Margem aplicada (%)" onChange={() => handleHasChange()} />
      <Input step={'any'} name="applied_tax" type="number" label="Imposto aplicado (%)" onChange={() => handleHasChange()} />
      <Input step={'any'} name="applied_discount" type="number" label="Desconto aplicado (%)" onChange={() => handleHasChange()} />
      <Input step={'any'} name="comission" type="number" label="Comissão (%)" onChange={() => handleHasChange()} />
      <Input step={'any'} name="point_value" type="number" label="Valor do ponto (R$)" onChange={() => handleHasChange()} disabled={proposal ? true : false} />
      <Select
        name="has_unit_values"
        label="Proposta final deve possuir valores unitários? (SIM/NÃO)"
        options={[
          { value: true, label: 'Sim' },
          { value: false, label: 'Não' }
        ]}
        placeholder="Escolher..."
        onChange={() => {
          if (fullLoaded) {
            handleHasChange();
          }
        }}
      />
      <Select
        name="has_total_values"
        label="Proposta final deve possuir descrição valores totais? (SIM/NÃO)"
        options={[
          { value: true, label: 'Sim' },
          { value: false, label: 'Não' }
        ]}
        placeholder="Escolher..."
        onChange={() => {
          if (fullLoaded) {
            handleHasChange();
          }
        }}
      />
      <Select
        name="has_summary_of_values"
        label="Proposta final deve possuir resumo de valores? (SIM/NÃO)"
        options={[
          { value: true, label: 'Sim' },
          { value: false, label: 'Não' }
        ]}
        placeholder="Escolher..."
        onChange={() => {
          if (fullLoaded) {
            handleHasChange();
          }
        }}
      />

      <div className="break-line" />
      <div className="buttons">
        <div />
        <Button type="submit" disabled={sending}>{sending ? "Salvando..." : "Próximo"}</Button>
      </div>
    </Form>
  );
}

export default Step1;
