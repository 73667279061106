import styled, { keyframes } from 'styled-components';

const appearFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const Container = styled.div`
  height: 100vh;
  width: 10.7rem;
  min-width: 10.7rem;

  @media (max-width: 1024px) {
    height: 60px;
  }

  @media print {
    display: none;
  }

  .fixed {
    position: fixed;
    z-index: 2;
    height: 100vh;
    width: 10.7rem;
    animation: ${appearFromLeft} 1s;
    background: #fff;
    box-shadow: 3px 0px 6px rgb(0 0 0 / 16%);

    @media (max-width: 1024px) {
      height: 60px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 15px;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    }

    @media (max-width: 769px) {
      background: var(--whiteColor);
    }

    .logo {
      height: 80px;
      display: flex;
      place-content: center;
      place-items: center;
      width: 100%;
      margin-top: 40px;

      @media (max-width: 1024px) {
        margin-top: 0px;
        height: 100%;
        width: auto;

        img {
          height: 60px;
          object-fit: contain;
          padding: 5px 0;
        }
      }

      img {
        max-width: 75%;
      }
    }

    .menu {
      height: 80%;
      overflow-y: scroll;

      &::-webkit-scrollbar {
        background: transparent;
        width: 0;
      }

      .toogle-menu {
        display: none;
        margin: 8px 0;
        background: transparent;
        border: 2px solid var(--textColor);
        width: 40px;
        align-items: center;
        justify-content: center;
        color: var(--textColor);
        border-radius: 5px;
        height: 32px;
        outline: none;
      }

      ul {
        padding: 40px 0 0 0;
        list-style: none;
        cursor: pointer;

        li {
          width: 100%;
          padding: 13px 15px;
          margin-bottom: 10px;
          position: relative;

          a {
            width: 100%;
            display: block;
            color: var(--textColor);
            text-decoration: none;
            line-height: 25px;
            font-size: 14px;
            font-weight: 500;

            svg {
              float: left;
              line-height: 23px;
              margin-right: 10px;

              &.menu-toogle {
                position: absolute;
                right: 5px;
                margin-right: 0;
                margin-top: 5px;

                @media (max-width: 769px) {
                  right: 15px;
                }
              }
            }
          }

          &.active {
            background-color: var(--primaryColor);

            a {
              color: var(--whiteColor);

              svg {
                color: #fff !important;
              }
            }
          }
        }

        ul.submenu {
          width: calc(100% + 40px);
          margin-left: -20px;
          padding: 0 20px;
          transform: scaleY(0);
          overflow: hidden;
          transition: transform 0.4s;
          transform-origin: top;
          height: 0;
          background-color: var(--submenuColor);

          &.open {
            transform: scaleY(1);
            padding: 0 20px;
            height: auto;
            margin-top: 10px;
          }

          li {
            background-color: var(--submenuColor);

            a {
              color: #fff;
            }
          }
        }
      }

      /* @media (max-width: 1024px) {
        background: transparent;

        ul {
          display: flex;
          padding: 12px 0;

          li {
            margin-bottom: 0;

            & + li {
              margin-left: 20px;
            }

            a {
              display: flex;
              color: var(--textColor);

              svg {
                margin-right: 5px;
              }
            }
          }
        }
      } */

      @media (max-width: 1024px) {
        .toogle-menu {
          display: flex;
        }

        ul {
          position: absolute;
          right: 0;
          flex-direction: column;
          background: #fff;
          margin-top: 6px;
          width: 100%;
          box-shadow: -3px 3px 6px rgba(0, 0, 0, 0.16);
          padding: 0;
          transform: scaleY(0);
          transition: transform 0.4s;
          overflow: hidden;
          transform-origin: top;
          max-height: calc(100vh - 60px);
          overflow: auto;

          &.open {
            transform: scaleX(1);
          }

          li {
            padding: 0;
            margin: 0 !important;
            border-top: 1px solid #ddd;

            a {
              padding: 12px 15px;
              font-weight: 500;
              color: var(--textColor);
            }

            &.active {
              a {
                color: var(--whiteColor);
              }
            }
          }

          ul.submenu.open {
            position: relative;
            margin-left: -20px;
            padding-left: 20px;
            width: calc(100% + 40px);
            box-shadow: none;
          }
        }
      }
    }
  }
`;
