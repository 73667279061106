import React, { useState, useCallback, useEffect } from 'react';
import { FiXCircle } from 'react-icons/fi';
import { useParams, useHistory } from 'react-router-dom';
import * as Yup from 'yup';

import getValidationErrors from '../../utils/getValidationErrors';

import { useToast } from '../../hooks/Toast';

import api from '../../services/api';

import Layout from '../../components/Layout';
import DefaultForm from '../../components/DefaultForm';

import { Container, FormContainer } from './styles';

import { User } from '../../interfaces/User';

interface UserFormData extends User {
  password: string;
}

const FormModal: React.FC = () => {
  const [user, setUser] = useState<User | null>();

  const history = useHistory();
  const { entity_id } = useParams<any>();

  const { addToast } = useToast();

  useEffect(() => {
    if (entity_id && entity_id !== 'novo') {
      api
        .get(`users/${entity_id}`)
        .then(response => {
          const userAux = response.data.item;
          setUser(userAux);
        })
        .catch(err => {
          console.log(err);
          addToast({
            type: 'error',
            title: 'Falha na requisição',
            description:
              'Ocorreu um erro ao carregar os dados, verifique sua conexão e tente novamente.',
          });
        });
    }
  }, []);

  const sendForm = useCallback(
    async (data: UserFormData) => {
      const isUpdate = entity_id && entity_id !== 'novo' ? true : false;
      try {
        let schema = Yup.object().shape({
          name: Yup.string().required('Nome obrigatório'),
          email: Yup.string()
            .email('Digite um e-mail válido')
            .required('E-mail obrigatório'),
          password: isUpdate ? Yup.string() : Yup.string().required('Senha obrigatória'),
          phone: Yup.string(),
        });
        await schema.validate(data, {
          abortEarly: false,
        });
        let response;
        if (isUpdate) {
          response = await api.put(`users/${entity_id}`, data);
        } else {
          response = await api.post('users', data);
        }
        return ({ resp: true, message: response.data.success.message });
      } catch (err) {
        console.log(err);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          return ({ resp: false, errors });
        } else {
          addToast({
            type: 'error',
            title: 'Falha na requisição',
            description:
              'Ocorreu um erro ao salvar, verifique os dados e tente novamente',
          });
        }
      }
    },
    [entity_id],
  );

  return (
    <Layout>
      <Container>
        <div className="breadcrumb">
          <h1>MDS &gt; Usuários &gt; {user ? user.name : 'Novo'}</h1>

          <button
            type="button"
            onClick={() => history.go(-1)}
            className="close-btn"
          >
            <FiXCircle />
          </button>
        </div>

        <FormContainer>
          <DefaultForm
            indexPath="/usuarios"
            submitForm={(formData) => sendForm(formData)}
            currentData={user}
            entity="users"
            inputs={[
              {
                input_type: "Input",
                name: "name",
                label: "Nome completo",
                fullWidth: true,
              },
              {
                input_type: "Input",
                type: "email",
                name: "email",
                label: "E-mail",
                fullWidth: true,
              },
              {
                input_type: "InputPassword",
                name: "password",
                label: "Senha",
                fullWidth: true,
              },
              {
                input_type: "InputMask",
                mask_type: "phone",
                name: "phone",
                label: "Telefone",
                fullWidth: true,
              }
            ]}
          />
        </FormContainer>

      </Container>
    </Layout>
  );
};

export default FormModal;
