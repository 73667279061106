import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 1.4rem;
  width: 100%;
  text-align: right;

  ul {
    display: flex;
    list-style: none;
    justify-content: flex-end;

    li {
      height: 2.2rem;
      width: 2.5rem;
      border: 1px solid var(--primaryColor);
      cursor: pointer;
      color: var(--primaryColor);
      font-weight: 500;
      border-radius: 2px;

      display: flex;
      align-items: center;
      justify-content: center;

      & + li {
        margin-left: 0.6rem;
      }

      &.current {
        cursor: initial;
        pointer-events: none;
        color: #fff;
        background-color: var(--primaryColor);
      }

      &.disabled {
        cursor: initial;
        pointer-events: none;
        color: #ccc;
        border-color: #ccc;
      }
    }
  }
`;
