import React, { useEffect, useState } from 'react';
import { PDFViewer, Document, Page, Text, View, Image, Link, StyleSheet, Font } from '@react-pdf/renderer';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/pt-br';

import api from '../../services/api';

import { useToast } from '../../hooks/Toast';

import LogoMds from '../../assets/logotipo-mds-security.jpg';
import Logo from '../../assets/logo.png';
import CapaDaAgua from '../../assets/papel-timbrado-mds-02.jpg';
import ServicosImg from '../../assets/servicos.png';
import Whats from '../../assets/whatsapp.png';

import { Proposal } from '../../interfaces/Proposal';

const Header = () => (
  <View style={geral.header}>
    <Image src={LogoMds} style={geral.headerLogo} />
  </View>
);

const Footer = () => (
  <View style={geral.footer}>
    <View style={geral.footerCol1}>
      <Text style={geral.TextFooter}>Av. Antônio Artioli, 570 - Prédio Flims{"\n"}Swiss Park Office - Campinas/SP{"\n"}</Text>
      <Text style={geral.TextFooter}>R. Guarujá, 86 - São Bernardo - Campinas/SP{"\n"}</Text>
      <View style={{ flexDirection: 'row' }}>
        <Image src={Whats} style={{ height: '10px' }} />
        <Text style={geral.TextFooter}>
          19 3273.7339 |&nbsp;
          <Text style={{ color: '#0077cc', fontFamily: 'Helvetica-Bold' }}>
            <Link src="www.mdssecurity.com.br" >
              www.mdssecurity.com.br
            </Link>
          </Text>
        </Text>
      </View>
    </View>
    <View style={geral.footerCol2}>
      <Image src={ServicosImg} style={geral.footerImg} />
    </View>
  </View>
);

interface DocInterface {
  proposal?: Proposal | null;
}

const MyDoc = ({ proposal }: DocInterface) => (
  <Document>
    {/* PAGE1 */}
    {/* <Page size="A4" style={geral.page}>
      <Image src={CapaDaAgua} style={geral.bg} />
      <Header />
      <View style={page1.cover}>
        <Text style={{ fontSize: '40px', fontFamily: 'Helvetica-Bold' }}>LISTA DE COMPRAS</Text>
        <Image style={page1.logo} src={proposal?.customer_logo || Logo} />
        <Text style={{ fontSize: '30px', fontFamily: 'Helvetica-Bold' }}>{proposal?.customer_name}</Text>
      </View>
      <Footer />
    </Page> */}
    {/* PAGE 2 */}
    <Page size="A4" style={geral.page}>
      <Image src={CapaDaAgua} style={geral.bg} />
      <Header />

      <View style={page2.scopeSection}>
        {/* {proposal?.products.map(product => ( */}
        <View style={geral.table}>
          {/* <Text style={geral.TitleBoldWithMargin}>{product.name}</Text> */}
          <View style={[geral.row, { backgroundColor: '#ddd' }]} wrap={false}>
            <Text style={[geral.col, { width: '100%', fontFamily: 'Helvetica-Bold', fontSize: '12px' }]}>Equipamentos</Text>
          </View>

          <View style={geral.row} wrap={false}>
            <Text style={[geral.col, { width: '7%', paddingVertical: 6, fontFamily: 'Helvetica-Bold' }]}>QTD.</Text>
            <Text style={[geral.col, { width: `${(15 + 16)}%`, paddingVertical: 6, fontFamily: 'Helvetica-Bold' }]}>MODELO</Text>
            <Text style={[geral.col, { width: `${(30 + 16)}%`, paddingVertical: 6, fontFamily: 'Helvetica-Bold' }]}>DESCRIÇÃO</Text>
            <Text style={[geral.col, { width: '16%', paddingVertical: 6, fontFamily: 'Helvetica-Bold' }]}>FABRICANTE</Text>
            {/* <Text style={[geral.col, { width: '16%', paddingVertical: 6, fontFamily: 'Helvetica-Bold' }]}>VALOR UNITÁRIO</Text>
              <Text style={[geral.col, { width: '16%', paddingVertical: 6, fontFamily: 'Helvetica-Bold' }]}>VALOR TOTAL</Text> */}
          </View>
          {proposal?.all_equipments.length > 0 ?
            proposal?.all_equipments.map((item: any, index: number) => (
              <View style={geral.row} key={index.toString()} wrap={false}>
                <Text style={[geral.col, { width: '7%' }]}>{item.qtd}</Text>
                <Text style={[geral.col, { width: `${(15 + 16)}%` }]}>{item.model}</Text>
                <Text style={[geral.col, { width: `${(30 + 16)}%` }]}>{item.name}</Text>
                <Text style={[geral.col, { width: '16%' }]}>{item.maker}</Text>
                {/* <Text style={[geral.col, { width: '16%' }]}>{item.unit_cost.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</Text>
                  <Text style={[geral.col, { width: '16%' }]}>{item.total_cost.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</Text> */}
              </View>
            ))
            : (
              <View style={geral.row} wrap={false}>
                <Text style={[geral.col, { width: '100%' }]}>Nenhum equipamento para esse produto</Text>
              </View>
            )}

          <View style={[geral.row, { backgroundColor: '#ddd', marginTop: 10 }]} wrap={false}>
            <Text style={[geral.col, { width: '100%', fontFamily: 'Helvetica-Bold', fontSize: '12px' }]}>Miscelâneas</Text>
          </View>
          <View style={geral.row} wrap={false}>
            <Text style={[geral.col, { width: '10%', paddingVertical: 6, fontFamily: 'Helvetica-Bold' }]}>QTD.</Text>
            <Text style={[geral.col, { width: `${(50 + 40)}%`, paddingVertical: 6, fontFamily: 'Helvetica-Bold' }]}>DESCRIÇÃO</Text>
            {/* <Text style={[geral.col, { width: '20%', paddingVertical: 6, fontFamily: 'Helvetica-Bold' }]}>VALOR UNITÁRIO</Text>
              <Text style={[geral.col, { width: '20%', paddingVertical: 6, fontFamily: 'Helvetica-Bold' }]}>VALOR TOTAL</Text> */}
          </View>
          {proposal?.all_miscellanies.length > 0 ?
            proposal?.all_miscellanies.map((item: any, index: number) => (
              <View style={geral.row} key={index.toString()} wrap={false}>
                <Text style={[geral.col, { width: '10%' }]}>{item.qtd}</Text>
                <Text style={[geral.col, { width: `${(50 + 40)}%` }]}>{item.description}</Text>
                {/* <Text style={[geral.col, { width: '20%' }]}>{item.unit_cost.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</Text>
                  <Text style={[geral.col, { width: '20%' }]}>{item.total_cost.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</Text> */}
              </View>
            ))
            : (
              <View style={geral.row} wrap={false}>
                <Text style={[geral.col, { width: '100%' }]}>Nenhuma miscelânea para esse produto</Text>
              </View>
            )}

          <View style={[geral.row, { backgroundColor: '#ddd', marginTop: 10 }]} wrap={false}>
            <Text style={[geral.col, { width: '100%', fontFamily: 'Helvetica-Bold', fontSize: '12px' }]}>Material de infraestrutura</Text>
          </View>
          <View style={geral.row} wrap={false}>
            <Text style={[geral.col, { width: '10%', paddingVertical: 6, fontFamily: 'Helvetica-Bold' }]}>QTD.</Text>
            <Text style={[geral.col, { width: `${(50 + 40)}%`, paddingVertical: 6, fontFamily: 'Helvetica-Bold' }]}>DESCRIÇÃO</Text>
            {/* <Text style={[geral.col, { width: '20%', paddingVertical: 6, fontFamily: 'Helvetica-Bold' }]}>VALOR UNITÁRIO</Text>
              <Text style={[geral.col, { width: '20%', paddingVertical: 6, fontFamily: 'Helvetica-Bold' }]}>VALOR TOTAL</Text> */}
          </View>
          {proposal?.all_infrastructures.length > 0 ?
            proposal?.all_infrastructures.map((item: any, index: number) => (
              <View style={geral.row} key={index.toString()} wrap={false}>
                <Text style={[geral.col, { width: '10%' }]}>{item.qtd}</Text>
                <Text style={[geral.col, { width: `${(50 + 40)}%` }]}>{item.description}</Text>
                {/* <Text style={[geral.col, { width: '20%' }]}>{item.unit_cost.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</Text>
                  <Text style={[geral.col, { width: '20%' }]}>{item.total_cost.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</Text> */}
              </View>
            ))
            : (
              <View style={geral.row} wrap={false}>
                <Text style={[geral.col, { width: '100%' }]}>Nenhum material de infraestrutura para esse produto</Text>
              </View>
            )}

        </View>

        {/* ))} */}

      </View>
      <Footer />
    </Page>
  </Document>
);

export default function ProposalPdf() {
  const [proposal, setProposal] = useState<Proposal | null>();

  const { entity_id } = useParams<any>();

  const { addToast } = useToast();

  useEffect(() => {
    if (entity_id && entity_id !== 'novo') {
      api
        .get(`proposals/${entity_id}`)
        .then(async (response) => {
          const proposalAux = response.data.item;

          var equipAux: any = [];
          var miscellaniesAux: any = [];
          var infrastructuresAux: any = [];

          await Promise.all([
            proposalAux.products?.map((prod: any) => {
              if (prod.equipments) {
                equipAux = equipAux.concat(prod.equipments);
              }

              if (prod.miscellanies) {
                miscellaniesAux = miscellaniesAux.concat(prod.miscellanies);
              }

              if (prod.infrastructures) {
                infrastructuresAux = infrastructuresAux.concat(prod.infrastructures);
              }
            })
          ]);

          setProposal({
            ...proposalAux,
            all_equipments: equipAux,
            all_miscellanies: miscellaniesAux,
            all_infrastructures: infrastructuresAux
          });
        })
        .catch(err => {
          console.log(err);
          addToast({
            type: 'error',
            title: 'Falha na requisição',
            description:
              'Ocorreu um erro ao carregar os dados, verifique sua conexão e tente novamente.',
          });
        });
    }
  }, [entity_id]);

  return (
    <div style={{ width: '100vw', height: '100vh' }}>
      {proposal && (
        <PDFViewer width="100%" height="100%" style={{ flex: 1 }}>
          <MyDoc proposal={proposal} />
        </PDFViewer>
      )}
    </div>
  )
}

// export default ProposalPdf;

// Create geral
const geral = StyleSheet.create({
  page: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: 30,
    paddingBottom: 15,
  },
  bg: {
    position: 'absolute',
    top: 0,
    left: 0,
    flex: 1,
    width: '100vw',
    height: '100%',
  },
  header: {},
  headerLogo: {
    width: 140,
    objectFit: 'scale-down',
  },
  footer: {
    height: 50,
    alignItems: 'center',
    flexDirection: 'row',
  },
  footerCol1: {
  },
  footerCol2: {
    flex: 1,
    marginLeft: '10px',
    borderLeftWidth: '1px',
    borderLeftColor: '#ddd',
    alignItens: 'center',
    justifyContent: 'center',
  },
  footerImg: {
    marginRight: '-15px',
    marginLeft: '10px',
  },
  TextFooter: {
    fontFamily: 'Helvetica',
    fontSize: '10px',
    color: '#777',
    marginBottom: '1px',
  },
  Title: {
    fontSize: '12px',
    fontWeight: 'bold',
    lineHeight: 1.3,
    fontFamily: 'Helvetica'
  },
  TitleBold: {
    fontSize: '12px',
    lineHeight: 1.3,
    fontFamily: 'Helvetica-Bold'
  },
  TitleWithMargin: {
    fontSize: '12px',
    fontWeight: 'bold',
    lineHeight: 1.3,
    fontFamily: 'Helvetica',
    margin: '7px 0',
  },
  TitleBoldWithMargin: {
    fontSize: '12px',
    lineHeight: 1.3,
    fontFamily: 'Helvetica-Bold',
    margin: '7px 0',
  },
  Text1: {
    fontSize: '12px',
    fontWeight: 'light',
    lineHeight: 1.3,
    fontFamily: 'Helvetica'
  },
  table: {
    display: 'flex',
    margin: '7px 0',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  col: {
    fontSize: '9px',
    borderWidth: 1,
    padding: 3,
    fontFamily: 'Helvetica',
    textAlign: 'center'
  },
  indexText: {
    fontFamily: 'Helvetica-Bold',
  }
});

const page1 = StyleSheet.create({
  cover: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    flex: 1,
  },
  logo: {
    height: 200,
    objectFit: 'scale-down',
    // width: 100,
  },
});

const page2 = StyleSheet.create({
  section: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    flex: 1,
    paddingHorizontal: 15,
  },
  scopeSection: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    flex: 1,
    paddingHorizontal: 15,
  },
});
