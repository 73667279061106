import React, { useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { FiEdit, FiTrash } from 'react-icons/fi';

import Layout from '../../components/Layout';
import { Container, List } from './styles';
import Table from '../../components/Table';
import Pagination from '../../components/Pagination';

import api from '../../services/api';

import { Customer } from '../../interfaces/Customer';
import { confirmAlert } from 'react-confirm-alert';
import { useToast } from '../../hooks/Toast';


const Customers: React.FC = () => {
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [filteredAttr, setFilteredAttr] = useState('');
  const [loadingData, setLoadingData] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [refresh, setRefresh] = useState<boolean>(false);

  const { addToast } = useToast();

  const history = useHistory();

  useEffect(() => {
    setLoadingData(true);
    api
      .get('customers', {
        params: {
          search: filteredAttr,
          page: currentPage,
        },
      })
      .then(response => {
        setCustomers(response.data.items);
        setLoadingData(false);
        setTotalPages(response.data.last_page);
      });
  }, [filteredAttr, currentPage, refresh]);

  const handleEdit = useCallback(
    user => {
      history.push(`${process.env.PUBLIC_URL}/clientes/${user.id}`);
    },
    [history],
  );

  let timeout: any;
  const handleSearchInput = useCallback((value) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      setFilteredAttr(value);
    }, 1000);
  }, [timeout]);

  const handleDelete = (item: Customer) => {
    try {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="custom-ui">
              <h1>Confirmação</h1>
              <p>Gostaria de deletar {item.name}? (Está ação irreversível)</p>
              <button onClick={onClose}>Não</button>
              <button
                onClick={async () => {
                  await api.delete(
                    `customers/${item.id}`
                  );

                  addToast({
                    type: 'success',
                    title: 'Cliente deletado com sucesso!',
                  });
                  setRefresh(!refresh);

                  onClose();
                }}
              >
                Sim, deletar!
              </button>
            </div>
          );
        },
      });
    } catch (err) {
      console.error('err', err);
      addToast({
        type: 'error',
        title: 'Falha na requisição',
        description:
          'Ocorreu um erro ao deletar os dados, atualize a página e tente novamente.',
      });
    }
  };

  return (
    <Layout>
      <Container>
        <div className="breadcrumb">
          <h1>MDS &gt; Clientes</h1>
        </div>

        <List>
          <Table>
            <div className="top">
              <div className="filter">
                <input
                  type="text"
                  name="search"
                  placeholder="Pesquisar por nome ou email"
                  onChange={event => handleSearchInput(event.target.value)}
                  className="filter-input"
                />
              </div>
              <button
                type="button"
                className="new-btn"
                onClick={() =>
                  history.push(`${process.env.PUBLIC_URL}/clientes/novo`)
                }
              >
                Novo
              </button>
            </div>
            <div className="table-container">
              <table>
                <thead>
                  <tr>
                    <th>Nome</th>
                    <th>Código</th>
                    <th>Status</th>
                    <th>Ações</th>
                  </tr>
                </thead>
                <tbody>
                  {loadingData ? (
                    <tr>
                      <td colSpan={6}>
                        Carregando clientes...
                      </td>
                    </tr>
                  ) : (
                    <>
                      {
                        customers.length > 0 ?
                          customers.map(item => (
                            <tr key={item.id.toString()}>
                              <td>{item.name}</td>
                              <td>{item.code}</td>
                              <td className="icon">{item.status}</td>
                              <td>
                                <button
                                  type="button"
                                  onClick={() => handleEdit(item)}
                                >
                                  <FiEdit />
                                </button>
                                <button
                                  type="button"
                                  onClick={() => handleDelete(item)}
                                >
                                  <FiTrash />
                                </button>
                              </td>
                            </tr>
                          )) : (
                            <tr>
                              <td colSpan={6}>
                                Nenhum resultado encontrado
                              </td>
                            </tr>
                          )
                      }
                    </>
                  )}
                </tbody>
              </table>
              <Pagination
                pages={totalPages}
                currentPage={currentPage}
                setPage={p => setCurrentPage(p)}
              />
            </div>
          </Table>
        </List>


      </Container>
    </Layout>
  );
};

export default Customers;
