import React, { useState, useRef, useCallback, useEffect } from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { FiXCircle } from 'react-icons/fi';
import { useParams, useHistory } from 'react-router-dom';

import * as Yup from 'yup';
import getValidationErrors from '../../utils/getValidationErrors';

import { useToast } from '../../hooks/Toast';

import api from '../../services/api';

import Input from '../../components/Form/Input';
import Button from '../../components/Form/Button';
import Select from '../../components/Form/Select';

import { FormContainer } from './styles';
import Layout from '../../components/Layout';

import { User } from '../../interfaces/User';
import InputPassword from '../../components/Form/InputPassword';
import InputMask from '../../components/Form/InputMask';

interface UserFormData extends User {
  password: string;
}

const MyProfile: React.FC = () => {
  const formRef = useRef<FormHandles>(null);

  const [currentItem, setCurrentItem] = useState<any>();
  const [sending, setSending] = useState<boolean>(false);
  const [phoneMask, setPhoneMask] = useState('(99) 9999-9999?');

  const history = useHistory();
  const { user_id } = useParams<any>();

  const { addToast } = useToast();

  useEffect(() => {
    if (user_id) {
      api
        .get(`users/${user_id}`)
        .then(response => {
          const aux = response.data.item;
          console.log(aux);
          setCurrentItem(aux);

          formRef.current?.setData({
            ...aux,
          });
        })
        .catch(err => {
          console.log(err);
          addToast({
            type: 'error',
            title: 'Oops!',
            description:
              err.response?.data.error.error_message,
          });
        });
    }
  }, [user_id]);

  const handleSubmit = useCallback(
    async (data: UserFormData) => {
      if (!data.password) {
        return;
      }

      setSending(true);

      try {
        formRef.current?.setErrors({});

        let schema = Yup.object().shape({
          password: Yup.string(),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        let response = await api.put(`users/${currentItem.id}`, data);

        addToast({
          type: 'success',
          title: `Pronto!`,
          description: response.data.success.message,
        });

        setSending(false);
      } catch (err) {
        setSending(false);
        console.log(err);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
        } else {
          addToast({
            type: 'error',
            title: 'Falha na requisição',
            description:
              'Ocorreu um erro ao salvar, verifique os dados e tente novamente',
          });
        }
      }
    },
    [currentItem, addToast, history, sending],
  );

  return (
    <Layout>
      <FormContainer>
        <div className="breadcrumb">
          <h2>Início / Editar meu perfil</h2>
        </div>

        <Form
          onSubmit={handleSubmit}
          ref={formRef}
          initialData={currentItem ? currentItem : undefined}
        >

          <Input name="name" label="Nome" disabled />
          <Input name="email" label="E-mail" disabled />
          <InputPassword name="password" label="Senha" placeholder="Alterar senha" />
          <InputMask
            name="phone"
            label="Telefone"
            mask={phoneMask}
            formatChars={{ '9': '[0-9]', '?': '[0-9 ]' }}
            maskChar={null}
            onChange={(event: any) => {
              if (event.target.value.length >= 15) {
                setPhoneMask('(99) 99999-9999');
              } else {
                setPhoneMask('(99) 9999-9999?');
              }
            }}
          />
          <div className="break-line" />
          <div className="buttons">
            <Button type="submit" disabled={sending}>{sending ? "Salvando..." : "Salvar"}</Button>
          </div>
        </Form>
      </FormContainer>
    </Layout>
  );
};

export default MyProfile;
