import React, { useState, useCallback, useEffect } from 'react';
import { FiXCircle } from 'react-icons/fi';
import { useParams, useHistory } from 'react-router-dom';
import * as Yup from 'yup';

import getValidationErrors from '../../utils/getValidationErrors';

import { useToast } from '../../hooks/Toast';

import api from '../../services/api';

import Layout from '../../components/Layout';
import DefaultForm from '../../components/DefaultForm';

import { Container, FormContainer } from './styles';

import { Customer } from '../../interfaces/Customer';

const FormModal: React.FC = () => {
  const [data, setData] = useState<Customer | null>();
  const [refresh, setRefresh] = useState<boolean>(false);

  const history = useHistory();
  const { entity_id } = useParams<any>();

  const { addToast } = useToast();

  useEffect(() => {
    if (entity_id && entity_id !== 'novo') {
      api
        .get(`customers/${entity_id}`)
        .then(response => {
          const objAux = response.data.item;
          console.log('objAux', objAux)
          setData({
            ...objAux,
            display_logo_in_proposal: objAux?.display_logo_in_proposal ? 'Sim' : 'Não',
            logo: objAux?.logo_url ? objAux?.logo_url : '',
          });
        })
        .catch(err => {
          console.log(err);
          addToast({
            type: 'error',
            title: 'Falha na requisição',
            description:
              'Ocorreu um erro ao carregar os dados, verifique sua conexão e tente novamente.',
          });
        });
    }
  }, [refresh]);

  const convertToBase64 = useCallback((file) => {
    return new Promise((resolve) => {
      var reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onloadend = function () {
        var base64 = reader.result;
        resolve(base64);
      }
    });
  }, []);

  const sendForm = useCallback(
    async (data: any) => {
      const isUpdate = entity_id && entity_id !== 'novo' ? true : false;

      var base64: any;
      if (data.logo) {
        base64 = await convertToBase64(data.logo);
        data.logo = base64;
      }

      if (data.display_logo_in_proposal === "") {
        delete data.display_logo_in_proposal;
      }

      try {
        let schema = Yup.object().shape({
          name: Yup.string().required('Nome obrigatório'),
          email: Yup.string()
            .email('Digite um e-mail válido')
            .required('E-mail obrigatório'),
          code: Yup.string().required('E-mail obrigatório'),
          phone: Yup.string(),
          status: Yup.string().required('Status obrigatório'),
          responsible: Yup.string().required('Responsável obrigatório'),
          cep: Yup.string(),
          address: Yup.string(),
          city: Yup.string().nullable(),
          uf: Yup.string().nullable(),
          neighborhood: Yup.string(),
          complement: Yup.string(),
          address_number: Yup.string(),
          logo: isUpdate ? Yup.string() : Yup.string().nullable(),
          display_logo_in_proposal: Yup.string().required('Campo obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        let response;
        if (isUpdate) {
          response = await api.put(`customers/${entity_id}`, {
            ...data,
            logo: base64,
            display_logo_in_proposal: data.display_logo_in_proposal === "Sim"
          });
        } else {
          response = await api.post('customers', {
            ...data,
            display_logo_in_proposal: data.display_logo_in_proposal === "Sim"
          });
          history.push("/clientes");
        }
        setRefresh(!refresh);
        return ({ resp: true, message: response.data.success.message });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          return ({ resp: false, errors, message: "Preencha todos os campos obrigatórios" });
        } else {
          return ({ resp: false, message: err?.response?.data?.error?.error_message });
        }
      }
    },
    [entity_id, refresh],
  );

  return (
    <Layout>
      <Container>
        <div className="breadcrumb">
          <h1>MDS &gt; Clientes &gt; {data ? data.name : 'Novo'}</h1>

          <button
            type="button"
            onClick={() => history.go(-1)}
            className="close-btn"
          >
            <FiXCircle />
          </button>
        </div>

        <FormContainer>
          <DefaultForm
            indexPath="/clientes"
            submitForm={(formData) => sendForm(formData)}
            currentData={data}
            // currentData={{
            //   ...data,
            //   logo: data?.logo_url ? data?.logo_url : '',
            // }}
            entity="customers"
            inputs={[
              {
                input_type: "Input",
                name: "name",
                label: "Nome",
              },
              {
                input_type: "Input",
                name: "code",
                label: "Código",
              },
              {
                input_type: "InputFile",
                name: "logo",
                label: "Logo",
                accept: "image/*",
              },
              {
                input_type: "Select",
                name: "status",
                label: "Status",
                placeholder: "Selecionar",
                selectProps: {
                  options: [
                    { value: 'Ativo', label: 'Ativo' },
                    { value: 'Inativo', label: 'Inativo' },
                  ]
                }
              },
              {
                input_type: "Input",
                type: "email",
                name: "email",
                label: "E-mail",
              },
              {
                input_type: "InputMask",
                mask_type: "phone",
                name: "phone",
                label: "Telefone",
              },
              {
                input_type: "Input",
                name: "responsible",
                label: "Responsável",
              },
              {
                input_type: "Select",
                name: "display_logo_in_proposal",
                label: "Exibir logo na proposta? (Sim/Não)",
                placeholder: "Selecionar",
                selectProps: {
                  options: [
                    { value: 'Sim', label: 'Sim' },
                    { value: 'Não', label: 'Não' },
                  ]
                }
              },
              {
                input_type: "Address",
                type: "address",
                name: "address",
              },
            ]}
          />
        </FormContainer>

      </Container>
    </Layout>
  );
};

export default FormModal;
