import styled, { css } from 'styled-components';

import Tooltip from '../Tooltip';

interface ContainerProps {
  isErrored: boolean;
}

interface LabelProps {
  fullWidth: boolean;
}

export const Label = styled.div<LabelProps>`
  width: 100%;
  max-width: calc(50% - 15px);

  strong {
    font-weight: 500;
    margin-bottom: 2px;
    display: inline-block;
    font-size: 14px;
  }

  @media (max-width: 769px) {
    max-width: 100%;
  }

  ${props =>
    props.fullWidth &&
    css`
      max-width: 100%;
    `}
`;
export const Container = styled.div<ContainerProps>`
  height: 40px;
  border-radius: 5px;
  background: #ffffff;
  border: 1px solid #c9c9c9;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  padding: 0 10px;

  ${props =>
    props.isErrored &&
    css`
      border-color: #c53030;
    `}

  .react-datepicker-wrapper {
    width: 100%;
    height: 100%;

    .react-datepicker__input-container {
      display: flex;
      height: 100%;

      input {
        flex: 1;
        border: 0;
      }
    }
  }

  @media (max-width: 769px) {
    width: 100%;
  }
`;

export const Error = styled(Tooltip)`
  height: 20px;
  margin-left: 16px;

  svg {
    margin: 0;
  }

  span {
    background: #c53030;
    color: #fff;

    &::before {
      border-color: #c53030 transparent;
    }
  }
`;
