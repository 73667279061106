import React, { useCallback, useRef, useState } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import { useHistory } from 'react-router-dom';
import { useAuth } from '../../hooks/Auth';
import { useToast } from '../../hooks/Toast';
import getValidationErrors from '../../utils/getValidationErrors';

import Logo from '../../assets/logo.png';

import Input from '../../components/Form/Input';
import InputPassword from '../../components/Form/InputPassword';
import Button from '../../components/Form/Button';

import { Container } from './styles';

interface SignInFormData {
  email: string;
  password: string;
}

const Login: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const formRef = useRef<FormHandles>(null);

  const { signIn } = useAuth();
  const { addToast } = useToast();
  const history = useHistory();

  const handleSubmit = useCallback(
    async (data: SignInFormData) => {
      setLoading(true);
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          email: Yup.string()
            .required('E-mail obrigatório')
            .email('Digite um e-mail válido'),
          password: Yup.string().required('Senha obrigatória'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await signIn({
          email: data.email,
          password: data.password,
        });
        setLoading(false);
      } catch (err) {
        setLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
        } else {
          addToast({
            type: 'error',
            title: 'Erro na autenticação',
            description:
              'Ocorreu um erro ao fazer login, cheque as credenciais',
          });
        }
      }
    },
    [addToast, signIn],
  );

  return (
    <Container>
      <div className="box-login">
        <div className="logo-box">
          <img src={Logo} alt="MDS" />
        </div>
        <div className="form-box">
          <Form onSubmit={handleSubmit} ref={formRef}>
            <label>E-mail</label>
            <Input
              type="email"
              name="email"
              placeholder="exemplo@exemplo.com.br"
              fullWidth
            />

            <label>Senha</label>
            <InputPassword
              name="password"
              placeholder="*********"
              fullWidth
            />

            {/* <button className="forgot_pass">Esqueci minha senha</button> */}
            <div className="buttons">
              <button
                className="forgot_pass"
                type="button"
                onClick={() => {
                  history.push(`${process.env.PUBLIC_URL}/recuperar-senha`);
                }}
              >
                Esqueci minha senha
              </button>

              <Button type="submit">
                {loading ? 'Acessando...' : 'Acessar'}
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </Container>
  );
};

export default Login;
