import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgb(0 0 0 / 60%);
  z-index: 9999 !important;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 768px) {
    padding: 0 20px;
  }
`;

export const PopUp = styled.div`
  max-width: 700px;
  min-height: 500px;
  width: 100%;
  background-color: #fff;
  padding: 25px;
  border-radius: 5px;

  @media (max-width: 768px) {
    padding: 25px 15px;
  }

  form {

    .buttons {
      justify-content: flex-end;

      button {
        width: 160px;
      }
    }
  }
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 35px;

  h2, svg {
    color: var(--primaryColor);
  }

  svg {
    cursor: pointer;
  }
`;

