import React, { useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { FiEdit, FiTrash } from 'react-icons/fi';
import { IoMdCheckmarkCircleOutline } from 'react-icons/io';
import { AiOutlineCloseCircle } from 'react-icons/ai';


import Layout from '../../components/Layout';
import { Container, List } from './styles';
import Table from '../../components/Table';
import Pagination from '../../components/Pagination';

import api from '../../services/api';

import { Equipment } from '../../interfaces/Equipment';
import { confirmAlert } from 'react-confirm-alert';
import { useToast } from '../../hooks/Toast';
import { HiTrash } from 'react-icons/hi';

const Equipments: React.FC = () => {
  const [data, setData] = useState<Equipment[]>([]);
  const [filteredAttr, setFilteredAttr] = useState('');
  const [loadingData, setLoadingData] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [refresh, setRefresh] = useState(false);

  const history = useHistory();
  const { addToast } = useToast();

  useEffect(() => {
    setLoadingData(true);
    api
      .get('equipments', {
        params: {
          search: filteredAttr,
          page: currentPage,
        },
      })
      .then(response => {
        setData(response.data.items);
        setLoadingData(false);
        setTotalPages(response.data.last_page);
      })
      .catch(err => {
        setLoadingData(false);
      });
  }, [filteredAttr, currentPage, refresh]);

  const handleEdit = useCallback(
    user => {
      history.push(`${process.env.PUBLIC_URL}/produtos/equipamentos/${user.id}`);
    },
    [history],
  );

  const handleDelete =
    (itemInstance: any) => {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="custom-ui">
              <h1>Deletar equipamento?</h1>
              <p>Gostaria mesmo de deletar {itemInstance.name}?</p>
              <button onClick={onClose}>Não</button>
              <button
                onClick={() => {
                  api.delete(`equipments/${itemInstance.id}`).then(resp => {
                    addToast({
                      type: 'success',
                      title: `Item deletado`,
                      description: `${itemInstance.name} foi deletado com sucesso.`,
                    });

                    setRefresh(!refresh);
                  });
                  setFilteredAttr('');
                  onClose();
                }}
              >
                Sim, deletar!
              </button>
            </div>
          );
        },
      });
    };

  let timeout: any;
  const handleSearchInput = useCallback((value) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      setFilteredAttr(value);
    }, 1000);
  }, [timeout]);

  return (
    <Layout>
      <Container>
        <div className="breadcrumb">
          <h1>MDS &gt; Produtos &gt; Equipamentos</h1>


        </div>

        <List>
          <Table>
            <div className="top">
              <div className="filter">
                <input
                  type="text"
                  name="search"
                  placeholder="Pesquisar por nome"
                  onChange={event => handleSearchInput(event.target.value)}
                  className="filter-input"
                />
              </div>
              <button
                type="button"
                className="new-btn"
                onClick={() =>
                  history.push(`${process.env.PUBLIC_URL}/produtos/equipamentos/novo`)
                }
              >
                Novo
              </button>
            </div>
            <div className="table-container">
              <table>
                <thead>
                  <tr>
                    <th>Nome</th>
                    <th>Modelo</th>
                    <th>Fabricante</th>
                    <th>Status</th>
                    <th>Custo</th>
                    <th>Ações</th>
                  </tr>
                </thead>
                <tbody>
                  {loadingData ? (
                    <tr>
                      <td colSpan={7}>
                        Carregando equipamentos...
                      </td>
                    </tr>
                  ) : (
                    <>
                      {
                        data.length > 0 ?
                          data.map(item => (
                            <tr key={item.id.toString()}>
                              <td>{item.name}</td>
                              <td>{item.model}</td>
                              <td>{item.maker}</td>
                              <td>{item.status}</td>
                              <td>{item.cost.toLocaleString('pt-br', {
                                style: 'currency',
                                currency: 'BRL',
                              })}</td>
                              <td>
                                <button
                                  type="button"
                                  onClick={() => handleEdit(item)}
                                >
                                  <FiEdit size={22} />
                                </button>
                                <button
                                  type="button"
                                  onClick={() => handleDelete(item)}
                                  title="Deletar"
                                >
                                  <FiTrash size={22} />
                                </button>
                              </td>
                            </tr>
                          )) : (
                            <tr>
                              <td colSpan={7}>
                                Nenhum resultado encontrado
                              </td>
                            </tr>
                          )
                      }
                    </>
                  )}
                </tbody>
              </table>
              <Pagination
                pages={totalPages}
                currentPage={currentPage}
                setPage={p => setCurrentPage(p)}
              />
            </div>
          </Table>
        </List>


      </Container>
    </Layout >
  );
};

export default Equipments;
