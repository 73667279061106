import styled, { keyframes } from 'styled-components';

export const Container = styled.div`
  h3 {
    width: 100%;
    color: var(--textColor);
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .break-line {
    width: 100%;
  }

  .buttons {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;

    button[type='submit'] {
      border-radius: 5px;
    }
  }
`;
