import React, {
  InputHTMLAttributes,
  useEffect,
  useRef,
  useState,
  useCallback,
} from 'react';
import { FiAlertCircle, FiEdit, FiX } from 'react-icons/fi';
import { useField } from '@unform/core';

import { Container, Error, Label } from './styles';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label?: string;
  fullWidth?: boolean;
}

const InputArray: React.FC<InputProps> = ({
  name,
  label,
  fullWidth = false,
  ...rest
}) => {
  const [items, setItems] = useState<string[]>([]);

  const inputRef = useRef<any>(null);
  const [isFocused, setIsFocused] = useState(false);
  const [customError, setCustomError] = useState<string | undefined>(undefined);
  const { fieldName, defaultValue, error, registerField, clearError } = useField(name);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
    clearError();
    setCustomError(undefined);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);
  }, []);

  const handleRemove = useCallback(
    (value: string) => {
      const aux = items.filter((item: any) => item !== value);
      setItems(aux);
    },
    [items]
  );

  const handleAdd = useCallback(
    () => {
      if (fieldName === "email" || fieldName === "emails") {
        var email = inputRef?.current?.value;
        var already_exists = items.indexOf(email) !== -1;

        if (!email || email === "") {
          return setCustomError(`Informe um e-mail`);
        }

        if (!email.includes('@')) {
          return setCustomError(`Inclua um "@" no endereço de e-mail. ${email} está com um "@" faltando`);
        }

        if (!email.split('@')[1]) {
          return setCustomError(`Insira uma parte depois de "@". ${email} está incompleto`);
        }

        setCustomError(undefined);

        if (email && email !== "" && !already_exists) {
          items.push(email);
          setItems([...items]);
        }
      }
    },
    [fieldName, inputRef, items]
  );

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      getValue: () => {
        return items;
      },
      setValue: async (ref, defaultItems: any) => {
        if (defaultItems) {
          const aux = defaultItems.map((item: any) => {
            return item.email;
          });

          setItems([...aux]);
        }
      },
    });
  }, [fieldName, registerField, items]);

  useEffect(() => {
    if (error) {
      setCustomError(error);
    }
  }, [error]);

  return (
    <Label fullWidth={fullWidth} className='label'>
      {!!label && <strong className='desc'>{label}</strong>}
      <Container isErrored={!!customError} isFocused={isFocused} className='input'>
        <input
          onFocus={() => handleInputFocus()}
          onBlur={() => handleInputBlur()}
          defaultValue={defaultValue}
          ref={inputRef}
          {...rest}
        />

        <button
          type='button'
          className='add'
          onClick={handleAdd}
        >
          Add E-mail
        </button>

        {customError && (
          <Error title={customError}>
            <FiAlertCircle color='c53030' size={20} />
          </Error>
        )}
      </Container>

      <div className='selectes-box'>
        {items &&
          items.map((item, index) => {
            return (
              <div key={index.toString()}>
                {item}
                <button
                  type='button'
                  className='rm'
                  onClick={() => handleRemove(item)}
                >
                  <FiX />
                </button>
              </div>
            );
          })}
      </div>
    </Label>
  );
};

export default InputArray;
