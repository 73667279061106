import React, { useState, useCallback, useEffect } from 'react';
import { FiXCircle } from 'react-icons/fi';
import { useParams, useHistory } from 'react-router-dom';
import * as Yup from 'yup';

import getValidationErrors from '../../utils/getValidationErrors';

import { useToast } from '../../hooks/Toast';

import api from '../../services/api';

import Layout from '../../components/Layout';
import DefaultForm from '../../components/DefaultForm';

import { Container, FormContainer } from './styles';

import { Miscellanies } from '../../interfaces/Miscellanies';

const FormModal: React.FC = () => {
  const [data, setData] = useState<Miscellanies | null>();
  const [refresh, setRefresh] = useState<boolean>(false);
  const history = useHistory();
  const { entity_id } = useParams<any>();

  const { addToast } = useToast();

  useEffect(() => {
    if (entity_id && entity_id !== 'novo') {
      api
        .get(`miscellanies/${entity_id}`)
        .then(response => {
          const item = response.data.item;
          setData({...item});
        })
        .catch(err => {
          console.log(err);
          addToast({
            type: 'error',
            title: 'Falha na requisição',
            description:
              'Ocorreu um erro ao carregar os dados, verifique sua conexão e tente novamente.',
          });
        });
    }
  }, [refresh]);

  const sendForm = useCallback(
    async (data: Miscellanies) => {
      const isUpdate = entity_id && entity_id !== 'novo' ? true : false;
      try {
        let schema = Yup.object().shape({
          description: Yup.string().required('Nome obrigatório'),
          cost: Yup.string().required('Custo obrigatório')
        });
        await schema.validate(data, {
          abortEarly: false,
        });
        let response;
        if (isUpdate) {
          data.cost = parseFloat(data.cost);
          response = await api.put(`miscellanies/${entity_id}`, data);
          setRefresh(!refresh);
        } else {
          response = await api.post('miscellanies', data);
        }
        return ({ resp: true, message: response.data.success.message });
      } catch (err) {
        console.log(err);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          return ({ resp: false, errors });
        } else {
          addToast({
            type: 'error',
            title: 'Falha na requisição',
            description:
              'Ocorreu um erro ao salvar, verifique os dados e tente novamente',
          });
        }
      }
    },
    [entity_id, refresh],
  );

  return (
    <Layout>
      <Container>
        <div className="breadcrumb">
          <h1>MDS &gt; Produtos &gt; Miscelâneas &gt; {data ? data.description : 'Novo'}</h1>

          <button
            type="button"
            onClick={() => history.go(-1)}
            className="close-btn"
          >
            <FiXCircle />
          </button>
        </div>

        <FormContainer>
          <DefaultForm
            indexPath="/produtos/miscelaneas"
            submitForm={(formData) => sendForm(formData)}
            currentData={data}
            entity="miscellanies"
            inputs={[
              {
                input_type: "Input",
                name: "description",
                label: "Descrição",
              },
              {
                input_type: "MoneyInput",
                name: "cost",
                label: "Custo"
              }
            ]}
          />
        </FormContainer>

      </Container>
    </Layout>
  );
};

export default FormModal;
