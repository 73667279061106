import React, { useEffect } from 'react';

import Header from '../Header';
import Menu from '../Menu';

import { Container } from './styles';

const Layout: React.FC = ({ children }) => {

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
  }, []);

  return (
    <Container>
      <Menu />

      <div className="content">
        <Header />
        {children}
      </div>
    </Container>
  );
};

export default Layout;
