import React, {
  createContext,
  useCallback,
  useState,
  useContext,
  useEffect,
} from 'react';
import { User } from '../interfaces/User';
import api from '../services/api';

interface AuthState {
  token: string;
  user: User;
}

interface SignInCredencials {
  email: string;
  password: string;
}

interface AuthContextData {
  user: User;
  signIn(credentials: SignInCredencials): Promise<void>;
  signOut(): void;
  updateUser(user_id: number): void;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<AuthState>(() => {
    const token = localStorage.getItem('@MDSSecurity:token');
    const user = localStorage.getItem('@MDSSecurity:user');

    if (token && user) {
      api.defaults.headers.authorization = `Bearer ${token}`;
      return { token, user: JSON.parse(user) };
    }

    return {} as AuthState;
  });

  useEffect(() => {
    verifyToken();

    if (data.user) {
      updateUser(data.user.id);
    }
  }, []);

  const verifyToken = useCallback(async () => {
    try {
      const response = await api.post('auth/verify');
      if (!response.data === true) {
        signOut();
      }
    } catch (err) {
      signOut();
    }
  }, []);

  const signIn = useCallback(async ({ email, password }) => {
    const response = await api.post('auth/login', { email, password });

    const { token, user } = response.data;

    localStorage.setItem('@MDSSecurity:token', token.token);
    localStorage.setItem('@MDSSecurity:user', JSON.stringify(user));

    api.defaults.headers.authorization = `Bearer ${token.token}`;

    setData({ token, user });
  }, []);

  const signOut = useCallback(() => {
    const token = localStorage.removeItem('@MDSSecurity:token');
    const user = localStorage.removeItem('@MDSSecurity:user');

    setData({} as AuthState);
  }, []);


  const updateUser = useCallback((user_id) => {
    try {
      api.get(`users/${user_id}`).then((resp) => {
        let userObj = {
          id: resp.data.item.id,
          name: resp.data.item.name,
          email: resp.data.item.email,
          phone: resp.data.item.phone,
          role_id: resp.data.item.role_id,
          role: resp.data.item.role,
          schedule_pci: resp.data.item.schedule_pci,
          expiration_date: resp.data.item.expiration_date,
          active: resp.data.item.active,
          created_at: resp.data.item.created_at,
        };

        localStorage.removeItem('@MDSSecurity:user');
        localStorage.setItem('@MDSSecurity:user', JSON.stringify(userObj));

        setData({
          token: data.token,
          user: userObj,
        });
      });
    } catch (err) {
      console.log(err);
    }
  }, [setData, data.token]);

  return (
    <AuthContext.Provider value={{ user: data.user, signIn, signOut, updateUser }}>
      {children}
    </AuthContext.Provider>
  );
};


function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}

export { AuthProvider, useAuth };
