import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 2rem 3.5rem;

  @media (max-width: 769px) {
    padding: 1rem;
  }
`;

export const List = styled.div`
  background: #fff;
  padding: 1.5rem;
`;

export const FormContainer = styled.div`
  padding: 1.5rem;
  place-items: center;
  flex-direction: column;
  background: #fff;
  border-radius: 5px;

  @media (max-width: 769px) {
    padding: 0 1rem;
  }

  form {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    position: relative;
    margin-bottom: 1.8rem;

    button[type='submit'] {
      border-radius: 5px;
    }

    .break-line {
      width: 100%;
    }
  }
`;
