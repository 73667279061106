import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 2rem 3.5rem;

  @media (max-width: 1024px) {
    padding: 1rem;
  }
`;

export const List = styled.div`
  background: #fff;
  padding: 1.5rem;

  @media (max-width: 1024px) {
    padding: 0;
    background: transparent;
  }
`;

export const FormContainer = styled.div`
  padding: 1.5rem;
  place-items: center;
  flex-direction: column;
  background: #fff;
  border-radius: 5px;
  overflow: hidden;

  .close-btn {
    position: absolute;
    right: 18px;
    top: 18px;
    padding: 0;
    line-height: 20px;
    background: transparent;
    border: 0;
    color: var(--primaryColor);
  }

  @media (max-width: 769px) {
    padding: 0 1rem;
  }

  .products-list {
    display: flex;
    margin-top: -1.5rem;
    margin-left: -1.5rem;
    margin-bottom: 1.5rem;

    @media(max-width: 1024px) {
      margin-top: 0;
    }

    .products-item {
      border-width: 0 5px 5px 0;
      border-color: #f5f5f5;
      border-style: solid;
      padding: 0.5rem 1rem;
      margin: 0px;
      background: transparent;
      display: flex;
      align-items: center;

      button {
        color: var(--textColor);
      }

      .button-item {
        background-color: transparent;
        border: none;
      }

      .delete-item {
        margin-left: 7px;
        margin-right: -10px;
        display: inline-flex;
        background: transparent;
        border: 0;
        font-size: 1.2rem;
      }

      &.active {
        background-color: var(--primaryColor);

        button {
          color: var(--whiteColor);
        }
      }
    }

    .new-product {
      background: transparent;
      border: aliceblue;
      font-size: 1.5rem;
      display: inline-flex;
      align-items: center;
      color: var(--textColor);
      border-width: 0 5px 5px 0;
      border-color: #f5f5f5;
      border-style: solid;
      padding: 0.5rem;
    }

  }

  form {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    position: relative;
    margin-bottom: 1.8rem;

    h3 {
      width: 100%;
      color: var(--textColor);
      margin-top: 1rem;
      margin-bottom: 1rem;

      a {
        font-size: 1rem;
        color: var(--primaryColor);
        cursor: pointer;
        text-decoration: underline;
      }
    }

    button[type='submit'] {
      border-radius: 5px;
    }

    .break-line {
      width: 100%;
    }

    .field-block {
      width: calc(50% - 15px);
      display: flex;
      justify-content: space-between;
      align-items: center;

      @media (max-width: 768px) {
        width: 100%;
      }

      button {
        width: 60px;
        margin-left: 15px;
      }
    }



    button.new-btn {
      font-weight: 600;
      width: 130px;
      height: 2.2rem;
      margin-bottom: 1.8rem;
    }

    .total-value {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      margin-bottom: 5rem;

      div {
        padding: 1.2rem 3rem;
        background-color: #F1F1F1;
        border-radius: 5px;
        color: var(--textColor);

        strong {
          margin-right: 0.5rem;
        }
      }
    }
  }

  .buttons {
    flex: 1;
    display: flex;
    justify-content: space-between;
    margin-top: 3px;

    &.action-buttons {
      flex-direction: column;
      margin-left: -1.5rem;
      width: calc(100% + 3rem);
      padding: 1.5rem 1.5rem 0;
      border-top: 7px solid #f5f5f5;

      @media (max-width: 769px) {
        margin-left: 0 1rem;
        width: calc(100% + 2rem);
      }
    }

    > div {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      margin-top: 30px;
    }

    a, button {
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      width: 180px;
      height: 40px;
      background-color: var(--primaryColor);
      border: 1px solid var(--primaryColor);
      box-shadow: 0px 3px 6px rgb(0 0 0 / 16%);
      border-radius: 5px;
      color: #fff;
      font-size: 1rem;
      transition: all 0.3s;
      max-width: 100%;

      &:hover {
        background-color: transparent;
        color: #181818;
      }

      & + a {
        margin-left: 10px;
      }

      & + button {
        margin-left: 10px;
      }

      @media(max-width: 1024px) {
        max-width: 48%;
        margin: 0 0 1rem !important;
      }
    }
  }

  table {
    width: 100%;
    border-spacing: 0;
    table-layout: fixed;
    /* margin-bottom: 1.8rem; */
    border-radius: 5px;

    thead {
      td {
        padding: 0.6rem;
        background-color: var(--primaryColor);
        color: var(--whiteColor);

        &:first-of-type {
          width: 8rem;
        }
      }
    }

    tbody {
      tr {

        &:nth-child(odd) {
          background-color: #F1F1F1;
        }

        td {
          padding: 0.6rem;

          .input {
            height: 30px;
            margin-bottom: 0;
            max-width: 100%;

            > * {
              max-width: 100%;
            }
            /* .react-select__control {
              min-height: 0 !important;
              height: 1.85rem !important;
            } */
          }

          .remove-btn {
            background: var(--redColor);
            width: 1.8rem;
            height: 1.8rem;
            border: 0;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
          }

          &.flex {
            display: flex;
            align-items: center;
            justify-content: space-between;

            button {
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 3px;
              border: none;
              background: var(--primaryColor);
              padding: 10px;
              max-height: 30px;
              color: #fff;
              margin-right: 7px;
              text-align: center;
              box-shadow: 0 3px 6px rgb(0 0 0 / 15%);
            }
          }
        }
      }
    }

    &.results {
      border-bottom: 1px solid #ddd;
      padding-bottom: 2rem;

      tr {
        @media(max-width: 768px) {
          display: flex;
          flex-direction: column;
        }
        td {
          padding: 1rem;

          &:nth-child(2) {
            width: 60px;
            background: #fff;

            @media(max-width: 768px) {
              width: 0;
              padding: 0;
            }
          }

          span {
            float: right;
          }
        }
      }
    }
  }
`;

export const HistoryContainer = styled.div`
  max-height: 40vh;
  overflow: auto;

  .history-item {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    margin-top: 15px;

    &:nth-child(odd) {
      background: #f1f1f1;
    }

    strong {
      font-weight: 500;
      width: 50%;
    }
  }
`;
