import React, { useEffect, useState } from 'react';
import { Chart } from 'react-google-charts';

import Layout from '../../components/Layout';

import api from '../../services/api';

import { Container, TopBlocks, LineChart } from './styles';

import moment from 'moment';

const Dashboard: React.FC = () => {
  const [customers, setCustomers] = useState(0);
  const [proposals, setProposals] = useState(0);
  const [pendingProposals, setPendingProposals] = useState(0);
  const [approvedProposals, setApprovedProposals] = useState(0);
  const [lineData, setLineData] = useState();
  const [loadingCharts, setLoadingCharts] = useState(false);
  const [canLoad, setCanLoad] = useState(true);

  // loadCharts
  useEffect(() => {
    if (canLoad) {
      setLoadingCharts(true);

      api.get(`/numbers-data`).then(response => {
        setCustomers(response.data.customers.total);
        setProposals(response.data.proposals.total);
        setPendingProposals(response.data.pending_proposals.total);
        setApprovedProposals(response.data.approved_proposals.total);
      });

      api.get(`line-chart-data`).then(response => {
        setLineData(response.data.results);
        setTimeout(() => {
          setLoadingCharts(false);
        }, 500);
      });
    }
  }, []);

  return (
    <Layout>
      <Container>
        <div className="breadcrumb">
          <h1>Dashboard</h1>
        </div>

        <TopBlocks>
          <div className="block">
            {loadingCharts ? (
              <div className="loading">
                <div className="h4" />
                <div className="h2" />
                <div className="h6" />
              </div>
            ) : (
              <>
                <div>
                  <h4>Clientes cadastrados</h4>
                  <h2>{customers}</h2>
                  <h6>Atualizado pela última vez as {moment().format('HH:mm')}</h6>
                </div>
              </>
            )}
          </div>

          <div className="block">
            {loadingCharts ? (
              <div className="loading">
                <div className="h4" />
                <div className="h2" />
                <div className="h6" />
              </div>
            ) : (
              <>
                <div>
                  <h4>Propostas criadas</h4>
                  <h2>{proposals}</h2>
                  <h6>Atualizado pela última vez as {moment().format('HH:mm')}</h6>
                </div>
              </>
            )}
          </div>

          <div className="block">
            {loadingCharts ? (
              <div className="loading">
                <div className="h4" />
                <div className="h2" />
                <div className="h6" />
              </div>
            ) : (
              <>
                <div>
                  <h4>Propostas em execução</h4>
                  <h2>{pendingProposals}</h2>
                  <h6>Atualizado pela última vez as {moment().format('HH:mm')}</h6>
                </div>
              </>
            )}
          </div>

          <div className="block">
            {loadingCharts ? (
              <div className="loading">
                <div className="h4" />
                <div className="h2" />
                <div className="h6" />
              </div>
            ) : (
              <>
                <div>
                  <h4>Propostas concluídas</h4>
                  <h2>{approvedProposals}</h2>
                  <h6>Atualizado pela última vez as {moment().format('HH:mm')}</h6>
                </div>
              </>
            )}
          </div>
        </TopBlocks>

        <LineChart>
          <h4>Propostas criadas nos últimos 30 dias</h4>
          {loadingCharts ? (
            <div className="loading">
              <div className="chart" />
            </div>
          ) : (
            <div className="chart-box">
              <Chart
                width="100%"
                height="18.75rem"
                chartType="AreaChart"
                data={lineData || undefined}
                rootProps={{ 'data-testid': '3' }}
                options={{
                  legend: { position: 'none' },
                  colors: ['#254caa'],
                  chartArea: { width: '100%', height: '60%', left: '40' },
                  series: {
                    0: {
                      lineWidth: 2,
                      areaOpacity: 0.4,
                    },
                  },
                  tooltip: { textStyle: { fontSize: 10 } },
                  hAxis: { textStyle: { fontSize: 10 } },
                  vAxis: { textStyle: { fontSize: 10 } },
                }}
              />
            </div>
          )}
        </LineChart>
      </Container>
    </Layout>
  );
};

export default Dashboard;
