import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FormHandles } from '@unform/core';
import { useHistory, Link } from 'react-router-dom';
import { FiPlus, FiXCircle } from 'react-icons/fi';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import api from '../../../services/api';

import { useToast } from '../../../hooks/Toast';

import Button from '../../../components/Form/Button';
import SendProposalModal from '../../../components/Modals/SendProposalModal';
import FormProduct from './FormProduct';

import { Proposal } from '../../../interfaces/Proposal';
import { Equipment } from '../../../interfaces/Equipment';
import { Miscellanies } from '../../../interfaces/Miscellanies';
import { Infrastructure } from '../../../interfaces/Infrastructure';
import { Service } from '../../../interfaces/Service';

interface LaborEquipments {
  name: string;
  points: string;
  unit_cost: string;
  total_cost: string;
}
interface GeneralLabors {
  name: string;
  observation: string;
  total_cost: string;
}
interface Product {
  id: number;
  name: string;
  equipments: Equipment[];
  labor_equipments: LaborEquipments[];
  general_labors: GeneralLabors[];
  miscellanies: Miscellanies[];
  infrastructures: Infrastructure[];
  services: Service[];
}

interface StepProps {
  proposal: Proposal;
  previousStep(): void;
  refreshProposal(): void;
}

const Step2: React.FC<StepProps> = ({ proposal, previousStep, refreshProposal }) => {
  const [currentProduct, setCurrentProduct] = useState<any>(0);

  const [refresh, setRefresh] = useState<boolean>(true);
  const [loadingNewVersion, setLoadingNewVersion] = useState<boolean>(false);
  const [loadingOptions, setLoadingOptions] = useState<boolean>(true);
  const [currentOptions, setCurrentOptions] = useState<any>();
  const [showEmailModal, setShowEmailModal] = useState(false);

  const { addToast } = useToast();

  useEffect(() => {
    loadCurrentOptions();
  }, [refresh]);

  useEffect(() => {
    if (proposal) {
      // console.log('proposal no step 2 ==> ', proposal)
      setCurrentProduct(proposal.products[0]);
    }
  }, [proposal]);

  const createNewVersion = useCallback(async () => {
    setLoadingNewVersion(true);
    api.post(`proposals/${proposal.id}/duplicate`)
      .then(res => {
        setLoadingNewVersion(false);
        addToast({
          type: 'success',
          title: `Pronto!`,
          description: res.data.success.message,
          timer: 6000
        });
      })
      .catch(err => {
        setLoadingNewVersion(false);
        addToast({
          type: 'error',
          title: `Oops!`,
          description: "Ocorreu um erro, tente novamente.",
          timer: 6000
        });
      });
  }, [proposal]);

  const loadCurrentOptions = useCallback(async () => {

    let equipmentsAux: any;
    let miscellaniesAux: any;
    let infrastructuresAux: any;
    let servicesAux: any;

    await Promise.all([
      api.get('equipments', { params: { status: "Ativo", per_page: 9999999 } }).then(resp => {
        equipmentsAux = resp.data.items
      }),
      api.get('miscellanies', { params: { per_page: 9999999 } }).then(resp => {
        miscellaniesAux = resp.data.items
      }),
      api.get('infrastructures', { params: { per_page: 9999999 } }).then(resp => {
        infrastructuresAux = resp.data.items
      }),
      api.get('services', { params: { per_page: 9999999 } }).then(resp => {
        servicesAux = resp.data.items
      }),
    ]);

    setCurrentOptions({
      equipments: equipmentsAux,
      miscellanies: miscellaniesAux,
      infrastructures: infrastructuresAux,
      services: servicesAux,
    });

    setLoadingOptions(false);
  }, []);

  const handleNewProduct = useCallback(async () => {
    try {
      api.post('/proposals/products', {
        name: `Produto ${proposal.products.length + 1}`,
        proposal_id: proposal.id
      }).then(resp => {
        refreshProposal();
        setRefresh(!refresh);
        addToast({
          type: 'success',
          title: `Pronto!`,
          description: resp.data.success.message,
          timer: 6000
        });
      })
    } catch (err) {
      addToast({
        type: 'error',
        title: `Oops!`,
        description: "Ocorreu um erro, tente novamente.",
        timer: 6000
      });
    }
  }, [proposal, refresh]);

  const handleDeleteProduct = useCallback((currentProduct) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui'>
            <h1>Deletar produto?</h1>
            <p>Gostaria mesmo de deletar o produto {currentProduct.name}?</p>
            <button onClick={onClose} type='button'>
              Não
            </button>
            <button
              type='button'
              onClick={() => {
                api
                  .delete(`/proposals/products/${currentProduct.id}`)
                  .then(() => {
                    addToast({
                      type: 'success',
                      title: 'Pronto!',
                      description: 'Produto deletado com sucesso.',
                    });
                    setRefresh(!refresh);
                    refreshProposal();
                  })
                  .catch(() => {
                    addToast({
                      type: 'error',
                      title: 'Ops!',
                      description:
                        'Ocorreu um erro ao deletar produto, tente novamente ou contate o suporte.',
                      timer: 6000,
                    });
                  });
                onClose();
              }}
            >
              Sim, deletar!
            </button>
          </div>
        );
      },
    });
  }, [refresh]);

  const handleSelectedProduct = useCallback((currentProduct) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui'>
            <h1>Abrir {currentProduct.name}?</h1>
            <p>Lembre-se sempre de clicar no botão "Salvar" no fim da página para não perder suas alterações na aba deste produto.</p>
            <button onClick={onClose} type='button'>
              Cancelar
            </button>
            <button
              type='button'
              onClick={() => {
                setCurrentProduct(currentProduct);
                setRefresh(!refresh);
                onClose();
              }}
            >
              Abrir
            </button>
          </div>
        );
      },
    });
  }, [refresh, currentProduct]);

  function calcTotalEquipments() {
    var totalValue: any = 0;

    // console.log('proposal', proposal);

    proposal?.products?.map((product) => {
      product?.equipments?.map((item: any) => {
        // const total_cost_equip = parseFloat(item.unit_cost) * (parseInt(item.qtd) || 1);
        const total_cost_equip = parseFloat(item.total_cost);
        const withMarginValue = total_cost_equip + (total_cost_equip * (proposal.applied_margin / 100));
        const withTaxValue = withMarginValue + (withMarginValue * (proposal.applied_tax / 100));

        totalValue = totalValue + withTaxValue;
      });

      product?.miscellanies?.map((item: any) => {
        // const total_cost_misc = parseFloat(item.unit_cost) * (parseInt(item.qtd) || 1);
        const total_cost_misc = parseFloat(item.total_cost);
        const withMarginValue = total_cost_misc + (total_cost_misc * (proposal.applied_margin / 100));
        const withTaxValue = withMarginValue + (withMarginValue * (proposal.applied_tax / 100));

        totalValue = totalValue + withTaxValue;
      });

      product?.infrastructures?.map((item: any) => {
        // const total_cost_inf = parseFloat(item.unit_cost) * (parseInt(item.qtd) || 1);
        const total_cost_inf = parseFloat(item.total_cost);
        const withMarginValue = total_cost_inf + (total_cost_inf * (proposal.applied_margin / 100));
        const withTaxValue = withMarginValue + (withMarginValue * (proposal.applied_tax / 100));

        totalValue = totalValue + withTaxValue;
      });
    });

    return parseFloat(totalValue);
  };

  function calcTotalLabors() {
    var totalValue: any = 0;

    proposal?.products?.map((product) => {
      product?.labor_equipments?.map((item: any, index: number) => {
        const currentEquip = product?.equipments.filter((equipAux) => equipAux.name === item.name);
        var qtd = currentEquip && currentEquip.length > 0 ? currentEquip[0].qtd : 0;

        const total_cost = (item.unit_cost * (item.points)) * qtd;
        const withMarginValue = total_cost + (total_cost * (proposal?.applied_margin / 100));
        const withTaxValue = withMarginValue + (withMarginValue * (proposal.applied_tax / 100));
        totalValue = totalValue + withTaxValue;
      });

      product?.general_labors?.map((item: any, index: number) => {
        const total_cost = parseFloat(item.total_cost);
        const withMarginValue = total_cost + (total_cost * (proposal?.applied_margin / 100));
        const withTaxValue = withMarginValue + (withMarginValue * (proposal.applied_tax / 100));
        totalValue = totalValue + withTaxValue;
      });
    });

    return parseFloat(totalValue);
  };

  function calcTotalOfProductEquipments(product: any) {
    var totalValue: any = 0;

    product?.equipments?.map((item: any) => {
      // const total_cost = item.unit_cost * (item.qtd || 1);
      const total_cost = parseFloat(item.total_cost);
      const withMarginValue = total_cost + (total_cost * (proposal.applied_margin / 100));
      const withTaxValue = withMarginValue + (withMarginValue * (proposal.applied_tax / 100));

      totalValue = totalValue + withTaxValue;
    });

    product?.miscellanies?.map((item: any) => {
      // const total_cost = item.unit_cost * (item.qtd || 1);
      const total_cost = parseFloat(item.total_cost);
      const withMarginValue = total_cost + (total_cost * (proposal.applied_margin / 100));
      const withTaxValue = withMarginValue + (withMarginValue * (proposal.applied_tax / 100));

      totalValue = totalValue + withTaxValue;
    });

    product?.infrastructures?.map((item: any) => {
      // const total_cost = item.unit_cost * (item.qtd || 1);
      const total_cost = parseFloat(item.total_cost);
      const withMarginValue = total_cost + (total_cost * (proposal.applied_margin / 100));
      const withTaxValue = withMarginValue + (withMarginValue * (proposal.applied_tax / 100));

      totalValue = totalValue + withTaxValue;
    });

    return parseFloat(totalValue);
  };

  function calcTotalOfProductLabors(product: any) {
    var totalValue: any = 0;

    product?.labor_equipments?.map((item: any, index: number) => {
      const currentEquip = product?.equipments.filter((equipAux: any) => equipAux.name === item.name);
      var qtd = currentEquip && currentEquip.length > 0 ? currentEquip[0].qtd : 0;

      const total_cost = (item.unit_cost * (item.points)) * qtd;
      // const total_cost = parseFloat(item.total_cost);
      const withMarginValueLE = total_cost + (total_cost * (proposal?.applied_margin / 100));
      const withTaxValue = withMarginValueLE + (withMarginValueLE * (proposal.applied_tax / 100));
      totalValue = totalValue + withTaxValue;
    });

    product?.general_labors?.map((item: any) => {
      // const total_cost = item.total_cost;
      const total_cost = parseFloat(item.total_cost);
      const withMarginValueGE = total_cost + (total_cost * (proposal?.applied_margin / 100));
      const withTaxValue = withMarginValueGE + (withMarginValueGE * (proposal.applied_tax / 100));
      totalValue = totalValue + withTaxValue;
    });

    return parseFloat(totalValue);
  };

  function calcTotalOfProducts() {
    var totalValue: any = 0;

    proposal?.products?.map((product) => {
      product?.equipments?.map((item: any) => {
        // const total_cost = item.unit_cost * (item.qtd || 1);
        const total_cost = parseFloat(item.total_cost);
        const withMarginValue = total_cost + (total_cost * (proposal.applied_margin / 100));
        const withTaxValue = withMarginValue + (withMarginValue * (proposal.applied_tax / 100));

        totalValue = totalValue + withTaxValue;
      });

      product?.labor_equipments?.map((item: any, index: number) => {
        const currentEquip = product?.equipments.filter((equipAux) => equipAux.name === item.name);
        var qtd = currentEquip && currentEquip.length > 0 ? currentEquip[0].qtd : 0;

        // const total_cost = (item.unit_cost * (item.points || 1)) * qtd;
        const total_cost = parseFloat(item.total_cost);
        const withMarginValue = total_cost + (total_cost * (proposal?.applied_margin / 100));
        const withTaxValue = withMarginValue + (withMarginValue * (proposal.applied_tax / 100));
        totalValue = totalValue + withTaxValue;
      });

      product?.general_labors?.map((item: any, index: number) => {
        // const total_cost = item.total_cost;
        const total_cost = parseFloat(item.total_cost);
        const withMarginValue = total_cost + (total_cost * (proposal?.applied_margin / 100));
        const withTaxValue = withMarginValue + (withMarginValue * (proposal.applied_tax / 100));
        totalValue = totalValue + withTaxValue;
      });

      product?.miscellanies?.map((item: any) => {
        // const total_cost = item.unit_cost * (item.qtd || 1);
        const total_cost = parseFloat(item.total_cost);
        const withMarginValue = total_cost + (total_cost * (proposal.applied_margin / 100));
        const withTaxValue = withMarginValue + (withMarginValue * (proposal.applied_tax / 100));

        totalValue = totalValue + withTaxValue;
      });

      product?.infrastructures?.map((item: any) => {
        // const total_cost = item.unit_cost * (item.qtd || 1);
        const total_cost = parseFloat(item.total_cost);
        const withMarginValue = total_cost + (total_cost * (proposal.applied_margin / 100));
        const withTaxValue = withMarginValue + (withMarginValue * (proposal.applied_tax / 100));

        totalValue = totalValue + withTaxValue;
      });
    });

    totalValue = totalValue - (totalValue * (proposal.applied_discount / 100))
    totalValue = totalValue - (totalValue * (proposal.comission / 100))

    return parseFloat(totalValue);
  };

  return (
    <>
      {proposal && !loadingOptions ? (
        <>
          <div className="products-list">
            {proposal && proposal.products?.length > 0 && (
              proposal.products.map(item => (
                <div key={item.id.toString()} className={`products-item ${item.id === currentProduct.id && 'active'}`}>
                  <button
                    className="button-item"
                    onClick={() => {
                      if (item.id !== currentProduct.id) {
                        handleSelectedProduct(item);
                      }
                    }}
                    key={item.id.toString()}
                  >
                    {item.name}
                  </button>
                  <button onClick={() => {
                    if (proposal && proposal.products?.length > 1) {
                      handleDeleteProduct(item);
                    }
                  }} className="delete-item">
                    <FiXCircle />
                  </button>
                </div>
              ))
            )}
            <button
              className="new-product"
              onClick={() => handleNewProduct()}
            >
              <FiPlus />
            </button>
          </div>
          <FormProduct
            proposal={{
              id: proposal.id,
              tax: proposal.applied_tax / 100,
              margin: proposal.applied_margin / 100,
              discount: proposal.applied_discount / 100,
              comission: proposal.comission / 100,
              point_value: proposal.point_value
            }}
            currentOptions={currentOptions}
            product={currentProduct}
            refreshProposal={() => {
              setRefresh(!refresh);
              refreshProposal();
            }}
            previousStep={previousStep}
          />
          <div className="buttons action-buttons">
            <h3>Resultados gerais</h3>

            <div>
              <table className="results">
                <tbody>
                  {
                    proposal?.products?.map((prod) => (
                      <tr key={prod.id.toString()}>
                        <td>Total {prod.name} (equipamentos) {(calcTotalOfProductEquipments(prod))?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</td>
                        <td></td>
                        <td>Total {prod.name} (mão de obra) {(calcTotalOfProductLabors(prod))?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</td>
                      </tr>
                    ))
                  }

                  <tr>
                    <td>Total em Equipamentos {(calcTotalEquipments())?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</td>
                    <td></td>
                    <td>Total em Mão de Obra {(calcTotalLabors())?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</td>
                  </tr>

                  <tr>
                    <td><strong>Total Geral (equipamentos + mão de obra)</strong></td>
                    <td></td>
                    <td><strong>{(calcTotalOfProducts())?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</strong></td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div>
              <Link
                to={`${process.env.PUBLIC_URL}/propostas/pdf/${proposal?.id}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Proposta técnica
              </Link>
              <Link
                to={`${process.env.PUBLIC_URL}/propostas/comercial/${proposal?.id}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Proposta comercial
              </Link>
              <Button
                type="button"
                onClick={() => {
                  createNewVersion();
                }}
                disabled={loadingNewVersion}
              >
                {loadingNewVersion ? "Criando..." : "Criar nova versão"}
              </Button>

              <Button
                type="button"
                onClick={() => {
                  setShowEmailModal(true);
                }}
              >
                Enviar por e-mail
              </Button>
            </div>
          </div>
        </>
      ) : (
        <p>Carregando...</p>
      )}

      {
        showEmailModal && (
          <SendProposalModal
            onClose={() => setShowEmailModal(false)}
            proposalId={proposal?.id}
          />
        )
      }
    </>
  );
}

export default Step2;

