import styled from 'styled-components';

export const Container = styled.div`
  background-color: #fff;
`;

export const Content = styled.div`
  margin: 0 auto;
  padding: 0.8rem 3.5rem;
  display: flex;
  justify-content: flex-end;

  .user-data {
    display: flex;
    align-items: center;

    .user-pic {
      width: 53px;
      height: 53px;
      border-radius: 50%;
      margin-right: 0.6rem;
      background: var(--secondColor);
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--whiteColor);
      font-weight: 600;
      font-size: 1.6rem;
    }

    > img {
      width: 53px;
      height: 53px;
      border-radius: 50%;
      margin-right: 0.6rem;
    }
  }
`;
