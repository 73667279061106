import React from 'react';
import { Router, Switch } from 'react-router-dom';

import Route from './Route';

import Login from '../pages/Login';

import Dashboard from '../pages/Dashboard';
import Users from '../pages/Users';
import UserForm from '../pages/Users/form';
import Customers from '../pages/Customers';
import CustomerForm from '../pages/Customers/form';
import Equipments from '../pages/Equipments';
import EquipmentsForm from '../pages/Equipments/form';
import Miscellaneous from '../pages/Miscellaneous';
import MiscellaneousForm from '../pages/Miscellaneous/form';
import Infrastructure from '../pages/Infrastructure';
import InfrastructureForm from '../pages/Infrastructure/form';
import Proposals from '../pages/Proposals';
import ProposalForm from '../pages/Proposals/Form';
import ProposalPdf from '../pages/Proposals/proposalPdf';
import ProposalCommercial from '../pages/Proposals/proposalCommercialPdf';
import ListProducts from '../pages/Proposals/listProductsPdf';
import ResetPassword from '../pages/ResetPassword';
import MyProfile from '../pages/MyProfile';
import Services from '../pages/Services';
import ServiceForm from '../pages/Services/form';

const Routes: React.FC = () => (
  <Switch>
    <Route path={`${process.env.PUBLIC_URL}/login`} component={Login} />

    <Route
      path={`${process.env.PUBLIC_URL}/`}
      component={Dashboard}
      exact
      isPrivate
      isAdmin
    />

    <Route
      path={`${process.env.PUBLIC_URL}/recuperar-senha`}
      component={ResetPassword}
    />

    <Route
      path={`${process.env.PUBLIC_URL}/meu-perfil/:user_id`}
      component={MyProfile}
      isPrivate
    />

    {/* USER */}
    <Route
      path={`${process.env.PUBLIC_URL}/usuarios`}
      component={Users}
      isPrivate
      isAdmin
      exact
    />
    <Route
      path={`${process.env.PUBLIC_URL}/usuarios/:entity_id`}
      component={UserForm}
      isPrivate
      isAdmin
    />
    <Route
      path={`${process.env.PUBLIC_URL}/usuarios/novo`}
      component={UserForm}
      isPrivate
      isAdmin
    />
    {/* CUSTOMER */}
    <Route
      path={`${process.env.PUBLIC_URL}/clientes`}
      component={Customers}
      isPrivate
      isAdmin
      exact
    />
    <Route
      path={`${process.env.PUBLIC_URL}/clientes/:entity_id`}
      component={CustomerForm}
      isPrivate
      isAdmin
    />
    <Route
      path={`${process.env.PUBLIC_URL}/clientes/novo`}
      component={CustomerForm}
      isPrivate
      isAdmin
    />
    {/* EQUIPMENTS */}
    <Route
      path={`${process.env.PUBLIC_URL}/produtos/equipamentos`}
      component={Equipments}
      isPrivate
      isAdmin
      exact
    />
    <Route
      path={`${process.env.PUBLIC_URL}/produtos/equipamentos/:entity_id`}
      component={EquipmentsForm}
      isPrivate
      isAdmin
    />
    <Route
      path={`${process.env.PUBLIC_URL}/produtos/equipamentos/novo`}
      component={EquipmentsForm}
      isPrivate
      isAdmin
    />
    {/* MISCELLANEOUS */}
    <Route
      path={`${process.env.PUBLIC_URL}/produtos/miscelaneas`}
      component={Miscellaneous}
      isPrivate
      isAdmin
      exact
    />
    <Route
      path={`${process.env.PUBLIC_URL}/produtos/miscelaneas/:entity_id`}
      component={MiscellaneousForm}
      isPrivate
      isAdmin
    />
    <Route
      path={`${process.env.PUBLIC_URL}/produtos/miscelaneas/novo`}
      component={MiscellaneousForm}
      isPrivate
      isAdmin
    />
    {/* INFRASTRUCTURE */}
    <Route
      path={`${process.env.PUBLIC_URL}/produtos/infraestruturas`}
      component={Infrastructure}
      isPrivate
      isAdmin
      exact
    />
    <Route
      path={`${process.env.PUBLIC_URL}/produtos/infraestruturas/:entity_id`}
      component={InfrastructureForm}
      isPrivate
      isAdmin
    />
    <Route
      path={`${process.env.PUBLIC_URL}/produtos/infraestruturas/novo`}
      component={InfrastructureForm}
      isPrivate
      isAdmin
    />
    {/* PROPOSAL */}
    <Route
      path={`${process.env.PUBLIC_URL}/propostas`}
      component={Proposals}
      isPrivate
      isAdmin
      exact
    />
    <Route
      path={`${process.env.PUBLIC_URL}/propostas/:entity_id`}
      component={ProposalForm}
      isPrivate
      isAdmin
      exact
    />
    <Route
      path={`${process.env.PUBLIC_URL}/propostas/lista-compras/:entity_id`}
      component={ListProducts}
      isPrivate
      isAdmin
      exact
    />
    <Route
      path={`${process.env.PUBLIC_URL}/propostas/pdf/:entity_id`}
      component={ProposalPdf}
      isPrivate
      isAdmin
    />
    <Route
      path={`${process.env.PUBLIC_URL}/propostas/comercial/:entity_id`}
      component={ProposalCommercial}
      isPrivate
      isAdmin
    />
    <Route
      path={`${process.env.PUBLIC_URL}/propostas/novo`}
      component={ProposalForm}
      isPrivate
      isAdmin
    />
    {/* SERVICES */}
    <Route
      path={`${process.env.PUBLIC_URL}/servicos`}
      component={Services}
      isPrivate
      isAdmin
      exact
    />
    <Route
      path={`${process.env.PUBLIC_URL}/servicos/:entity_id`}
      component={ServiceForm}
      isPrivate
      isAdmin
    />
    <Route
      path={`${process.env.PUBLIC_URL}/servicos/novo`}
      component={ServiceForm}
      isPrivate
      isAdmin
    />
  </Switch>
);

export default Routes;
