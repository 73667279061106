import React, { useState, useRef, useCallback, useEffect } from 'react';

import { FiXCircle } from 'react-icons/fi';
import { useParams, useHistory } from 'react-router-dom';

import { useToast } from '../../../hooks/Toast';

import api from '../../../services/api';

import Step1 from './step1';
import Step2 from './step2';

import { Container, FormContainer } from '../styles';
import Layout from '../../../components/Layout';

const FormModal: React.FC = () => {
  const { entity_id } = useParams<any>();

  const [proposal, setProposal] = useState<any | null>();//Proposal
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [currentId, setCurrentId] = useState<number | string | null>(entity_id);

  const history = useHistory();


  const { addToast } = useToast();

  useEffect(() => {
    if (currentId && currentId !== 'novo') {
      api
        .get(`proposals/${currentId}`)
        .then(response => {
          const proposalAux = response.data.item;
          // console.log('proposal no index ==> ', proposalAux)
          setProposal({...proposalAux});
        })
        .catch(err => {
          console.log(err);
          addToast({
            type: 'error',
            title: 'Falha na requisição',
            description:
              'Ocorreu um erro ao carregar os dados, verifique sua conexão e tente novamente.',
          });
        });
    }
  }, [currentId, refresh]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
  }, [currentStep]);

  return (
    <Layout>
      <Container>
        <div className="breadcrumb">
          <h1>MDS &gt; Propostas &gt; {proposal ? proposal.title : 'Novo'}</h1>

          <button
            type="button"
            onClick={() => history.go(-1)}
            className="close-btn"
          >
            <FiXCircle size={30} />
          </button>
        </div>

        <FormContainer>
          {currentStep === 1 ? (
            <Step1 proposal={proposal} nextStep={() => setCurrentStep(2)} refreshProposal={(proposal_id) => {
              setCurrentId(proposal_id);
              setRefresh(!refresh);
            }} />
          ) : (
            <Step2 proposal={proposal} previousStep={() => setCurrentStep(1)} refreshProposal={() => {
              setRefresh(!refresh);
            }} />
          )}
        </FormContainer>

      </Container>
    </Layout>
  );
};

export default FormModal;
