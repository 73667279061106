import React, { useCallback, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  FiHome,
  FiUsers,
  FiCalendar,
  FiLogOut,
  FiMenu,
  FiX,
  FiSettings,
} from 'react-icons/fi';
import {
  AiOutlineFundProjectionScreen,
} from 'react-icons/ai';

import { VscOrganization } from 'react-icons/vsc';

import { useAuth } from '../../hooks/Auth';

import { Container } from './styles';

import Logo from '../../assets/logo.png';
import { FaCaretDown, FaCaretUp } from 'react-icons/fa';

const Menu: React.FC = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [currentPath, setCurrentPath] = useState('');

  const [productsMenu, setProductsMenu] = useState(false);

  const { signOut, user } = useAuth();
  const location = useLocation();

  const handleProductsMenu = useCallback(
    event => {
      event.preventDefault();
      setProductsMenu(!productsMenu);
    },
    [productsMenu],
  );

  useEffect(() => {
    if (location) {
      setCurrentPath(location.pathname);
    }
  }, [location]);

  return (
    <Container>
      <div className="fixed">
        <div className="logo">
          <img src={Logo} alt="MDS" />
        </div>
        <div className="menu">
          <button
            type="button"
            className="toogle-menu"
            onClick={() => setMenuOpen(!menuOpen)}
          >
            {!menuOpen ? <FiMenu size={20} /> : <FiX />}
          </button>
          <ul className={menuOpen ? `open` : ``}>
            <li className={currentPath === '/' ? 'active' : ''}>
              <Link to={`${process.env.PUBLIC_URL}/`}>
                <FiHome size={22} />
                Início
              </Link>
            </li>
            <li className={currentPath.includes('/usuarios') ? 'active' : ''}>
              <Link to={`${process.env.PUBLIC_URL}/usuarios`}>
                <FiUsers size={22} />
                Usuários
              </Link>
            </li>

            <li
              className={currentPath.includes('/clientes') ? 'active' : ''}
            >
              <Link to={`${process.env.PUBLIC_URL}/clientes`}>
                <VscOrganization size={22} />
                Clientes
              </Link>
            </li>

            <li className={currentPath.includes('/produtos') ? 'active' : ''}>
              <a onClick={handleProductsMenu}>
                <AiOutlineFundProjectionScreen size={20} />
                    Produtos
                    {!productsMenu ? (
                  <FaCaretDown size={16} className="menu-toogle" />
                ) : (
                  <FaCaretUp size={16} className="menu-toogle" />
                )}
              </a>
              <ul className={productsMenu ? `submenu open` : `submenu`}>
                <li>
                  <Link to={`${process.env.PUBLIC_URL}/produtos/equipamentos`}>
                    Equipamentos
                      </Link>
                </li>
                <li>
                  <Link to={`${process.env.PUBLIC_URL}/produtos/miscelaneas`}>
                    Miscelâneas
                      </Link>
                </li>
                <li>
                  <Link to={`${process.env.PUBLIC_URL}/produtos/infraestruturas`}>
                    Infraestruturas
                        </Link>
                </li>
              </ul>
            </li>

            <li className={currentPath.includes('/servicos') ? 'active' : ''}>
              <Link to={`${process.env.PUBLIC_URL}/servicos`}>
                <FiSettings size={22} />
                Serviços
              </Link>
            </li>
            <li className={currentPath.includes('/propostas') ? 'active' : ''}>
              <Link to={`${process.env.PUBLIC_URL}/propostas`}>
                <FiCalendar size={22} />
                Propostas
              </Link>
            </li>
            <li>
              <a href="/" onClick={() => signOut()}>
                <FiLogOut size={22} />
                Sair
              </a>
            </li>
            <li>
              <p style={{position: 'absolute', bottom: 10, right: 10, fontSize: 11, color: '#313131'}}>
                Versão 1.8
              </p>
            </li>
          </ul>
        </div>
      </div>
    </Container>
  );
};

export default Menu;
