import React, {
  ChangeEvent,
  useRef,
  useEffect,
  useCallback,
  useState,
} from 'react';
import { useField } from '@unform/core';
import { Container, Label, Error } from './styles';
import { FiAlertCircle } from 'react-icons/fi';

interface Props {
  name: string;
  label?: string;
  fullWidth?: boolean;
}
type InputProps = JSX.IntrinsicElements['input'] & Props;

const InputFile: React.FC<InputProps> = ({ name, label, fullWidth = false, ...rest }) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const { fieldName, registerField, defaultValue, error, clearError } = useField(name);
  const [preview, setPreview] = useState(defaultValue);
  const [isFocused, setIsFocused] = useState(false);

  const handlePreview = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    console.log(file);
    if (!file) {
      setPreview(null);
    }
    const previewURL = URL.createObjectURL(file);
    setPreview(previewURL);
  }, []);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
    clearError();
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);
  }, []);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'files[0]',
      clearValue(ref: HTMLInputElement) {
        ref.value = '';
        setPreview(null);
      },
      setValue(_: HTMLInputElement, value: string) {
        setPreview(value);
      },
    });
  }, [fieldName, registerField]);

  return (
    <Label fullWidth={fullWidth} className="label">
      {!!label && <strong>{label}</strong>}
      {preview && <img src={preview} alt="Preview" width="100" className="preview" />}
      <Container isErrored={!!error} isFocused={isFocused} className="input">
        <input type="file" onBlur={handleInputBlur} onFocus={handleInputFocus} ref={inputRef} onChange={handlePreview} {...rest} />
        {error && (
          <Error title={error}>
            <FiAlertCircle color="c53030" size={20} />
          </Error>
        )}
      </Container>
    </Label>
  );
};

export default InputFile;
