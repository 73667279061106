import { Form } from '@unform/web';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FormHandles } from '@unform/core';
import cep from 'cep-promise';
import { useHistory, useParams } from 'react-router-dom';

import Input from '../Form/Input';
import Select from '../Form/Select';
import MultiSelect from '../Form/MultiSelect';
import Textarea from '../Form/Textarea';
import InputPassword from '../Form/InputPassword';
import DatePicker from '../Form/DatePicker';
import InputFile from '../Form/InputFile';
import InputMask from '../Form/InputMask';
import NumberInput from '../Form/NumberInput';
import InputArray from '../Form/InputArray';
import Button from '../Form/Button';

import { Props as SelectProps } from 'react-select';
import { Props as InputMaskProps } from 'react-input-mask';
import { ReactDatePickerProps } from 'react-datepicker';

import { useToast } from '../../hooks/Toast';

import { Container } from './styles';

interface InputProps {
  input_type: string;
  mask_type?: string;
  name: string;
  type?: string;
  label?: string;
  placeholder?: string;
  fullWidth?: boolean;
  selectProps?: SelectProps;
  datePickerProps?: ReactDatePickerProps;
  inputMaskProps?: InputMaskProps;
  accept?: string,
}

interface FormProps {
  entity: string;
  inputs: InputProps[];
  submitForm(formData: any): any;
  currentData?: any;
  indexPath?: string;
}

const DefaultForm: React.FC<FormProps> = ({ entity, inputs, submitForm, currentData, indexPath }) => {
  const [masks, setMasks] = useState<any>({
    phone: '',
    cnpj: '99.999.999/9999-99'
  });
  const [selectedDates, setSelectedDates] = useState<any>({});
  const [sending, setSending] = useState<boolean>(false);
  const [refresh, setRefresh] = useState<boolean>(false);

  const formRef = useRef<FormHandles>(null);

  const { entity_id } = useParams<any>();
  const { addToast } = useToast();
  const history = useHistory();

  // ------ Load initial data Start ------ //
  useEffect(() => {
    if (currentData) {

      if (currentData?.phone?.length >= 15) {
        setMasks({ ...masks, phone: '(99) 99999-9999' });
      } else {
        setMasks({ ...masks, phone: '(99) 9999-9999?' });
      }

      formRef.current?.setData(currentData);
    }
  }, [currentData]);
  // ------ Load initial data End ------ //

  const handleCep = useCallback((e) => {
    var value = e.target.value;
    cep(value).then((response: any) => {
      formRef.current?.setFieldValue('address', `${response.street}`);
      formRef.current?.setFieldValue('city', response.city);
      formRef.current?.setFieldValue('uf', response.state);
      formRef.current?.setFieldValue(
        'neighborhood',
        `${response.neighborhood}`
      );
    });
  }, [formRef]);

  const handleMask = useCallback(({ value, input }) => {
    switch (input.mask_type) {
      case "phone":
        if (value?.length >= 15) {
          setMasks({ ...masks, phone: '(99) 99999-9999' });
        } else {
          setMasks({ ...masks, phone: '(99) 9999-9999?' });
        }
        break;

      default:
        break;
    }
  }, [masks]);

  const handleSelectedDate = useCallback(({ date, input }) => {
    setSelectedDates({ ...selectedDates, [input.name]: date });
  }, [selectedDates]);

  const handleSubmit = useCallback(async (data: any) => {
    setSending(true);
    try {
      formRef.current?.setErrors({});

      await submitForm(data).then((resp: any) => {
        if (resp?.resp) {
          addToast({
            type: 'success',
            title: `Cadastro ${entity_id && entity_id !== "novo" ? 'atualizado' : 'concluído'}`,
            description: resp.message,
          });

          setRefresh(!refresh);

          if (entity_id && entity_id === "novo") {
            if (indexPath && !!indexPath) {
              history.push(indexPath);
            } else {
              history.back();
            }
          }
        } else {
          if (resp?.errors) {
            formRef.current?.setErrors(resp?.errors);
          }

          addToast({
            type: 'error',
            title: 'Falha ao salvar dados',
            description:
              resp.message || 'Ocorreu um erro ao salvar, verifique os dados e tente novamente',
            timer: 6000
          });
        }
      });

      setSending(false);
    } catch (err) {
      setSending(false);
      console.log(err);
    }
  }, [entity_id, indexPath, refresh]);

  const renderInputs = useCallback((CurrentInput, i) => {
    switch (CurrentInput.input_type) {
      case "Input":
        return <Input key={i.toString()} {...CurrentInput} step="any" />
      case "InputPassword":
        return <InputPassword key={i.toString()} {...CurrentInput} />
      case "InputMask":
        return <InputMask
          key={i.toString()}
          {...CurrentInput}
          {...CurrentInput.inputMaskProps}
          mask={masks[CurrentInput.mask_type]}
          onChange={(e) => handleMask({ value: e.target.value, input: CurrentInput })}
          formatChars={{ '9': '[0-9]', '?': '[0-9 ]' }}
          maskChar={null}
        />
      case "Cep":
        return <InputMask key={i.toString()} mask='99999-999' formatChars={{ '9': '[0-9]' }} maskChar={null} {...CurrentInput} onChange={handleCep} />
      case "Select":
        return <Select key={i.toString()} {...CurrentInput} {...CurrentInput.selectProps} />
      case "MultiSelect":
        return <MultiSelect key={i.toString()} {...CurrentInput} {...CurrentInput.selectProps} />
      case "Textarea":
        return <Textarea key={i.toString()} {...CurrentInput} />
      case "DatePicker":
        return <DatePicker
          key={i.toString()}
          {...CurrentInput}
          {...CurrentInput.datePickerProps}
          onChange={(date: Date) => handleSelectedDate({ date, input: CurrentInput })}
          selected={selectedDates[CurrentInput.name] ? new Date(selectedDates[CurrentInput.name]) : undefined}

        />
      case "InputFile":
        return <InputFile key={i.toString()} {...CurrentInput} />
      case 'Address':
        return (
          <>
            <h3>Dados de endereço</h3>
            <InputMask
              name="cep"
              label="CEP"
              mask="99999-999"
              maskChar={null}
              onChange={event => {
                if (event.target.value.length >= 9) {
                  handleCep(event);
                }
              }}
            />
            <Input name="uf" label="UF" />
            <Input name="city" label="Cidade" />
            <Input name="neighborhood" label="Bairro" />
            <Input name="address" label="Endereço" />
            <Input name="complement" label="Complemento" />
            <Input type="number" name="address_number" label="Número" />
            <div className="break-line" />
          </>
        )
      case 'MoneyInput':
        return <NumberInput
          key={i.toString()}
          {...CurrentInput}
          thousandSeparator='.'
          prefix='R$ '
          placeholder='R$0,00'
          decimalSeparator=','
          decimalScale={2}
          fixedDecimalScale
          allowNegative={false}
        />
      case "InputArray":
        return <InputArray key={i.toString()} {...CurrentInput} />
      default:
        break;
    }
  }, [masks, selectedDates]);

  return (
    <Container>
      <Form
        onSubmit={(data) => handleSubmit(data)}
        ref={formRef}
      >
        {
          inputs.map((item, index) => (
            renderInputs(item, index)
          ))
        }
        <div className="break-line" />
        <div className="buttons">
          <Button type="submit" disabled={sending}>{sending ? "Salvando..." : "Salvar"}</Button>
        </div>
      </Form>
    </Container>
  );
}

export default DefaultForm;
