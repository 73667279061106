import React, {
  InputHTMLAttributes,
  useEffect,
  useRef,
  useState,
  useCallback,
} from 'react';
import { FiAlertCircle } from 'react-icons/fi';
import { useField } from '@unform/core';

import { Container, Error, Label } from './styles';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label?: string;
  fullWidth?: boolean;
  isHidden?: boolean;
}

const Input: React.FC<InputProps> = ({
  name,
  label,
  fullWidth = false,
  isHidden = false,
  ...rest
}) => {
  const inputRef = useRef(null);
  const [isFocused, setIsFocused] = useState(false);
  const { fieldName, defaultValue, error, registerField, clearError } = useField(name);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
    clearError();
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);
  }, []);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <>
      {
        !isHidden ? (
          <Label fullWidth={fullWidth} className="label">
            {!!label && <strong>{label}</strong>}
            <Container isErrored={!!error} isFocused={isFocused} className="input">
              <input
                onFocus={() => handleInputFocus()}
                onBlur={() => handleInputBlur()}
                defaultValue={defaultValue}
                ref={inputRef}
                {...rest}
              />
              {error && (
                <Error title={error}>
                  <FiAlertCircle color="c53030" size={20} />
                </Error>
              )}
            </Container>
          </Label>
        ) : (
          <input
            onFocus={() => handleInputFocus()}
            onBlur={() => handleInputBlur()}
            defaultValue={defaultValue}
            ref={inputRef}
            style={{ width: 0, border: 0, overflow: 'hidden' }}
            {...rest}
          />
        )
      }
    </>
  );
};

export default Input;
