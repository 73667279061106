import styled, { keyframes } from 'styled-components';

const appearFromTop = keyframes`
  from {
    opacity: 0;
    transform: translateY(-3rem);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  width: 100%;

  @media (max-width: 1024px) {
    flex-direction: column;
  }

  .content {
    flex: 1;
    max-width: calc(100% - 10.7rem);

    @media (max-width: 1024px) {
      max-width: 100%;
    }

    .breadcrumb {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-bottom: 2rem;

      @media (max-width: 1024px) {
        padding: 1rem;
      }

      h1 {
        color: var(--primaryColor);
        font-size: 1.7rem;
      }

      .close-btn {
        padding: 0;
        background: transparent;
        border: 0;
        color: var(--primaryColor);
        font-size: 2.2rem;
      }
    }

  }
`;
