import React, { useState, useCallback, useEffect } from 'react';
import { FiXCircle } from 'react-icons/fi';
import { useParams, useHistory } from 'react-router-dom';
import * as Yup from 'yup';

import getValidationErrors from '../../utils/getValidationErrors';

import { useToast } from '../../hooks/Toast';

import api from '../../services/api';

import Layout from '../../components/Layout';
import DefaultForm from '../../components/DefaultForm';

import { Container, FormContainer } from './styles';

import { Equipment } from '../../interfaces/Equipment';

const FormModal: React.FC = () => {
  const [equipment, setEquipment] = useState<Equipment | null>();
  const [refresh, setRefresh] = useState<boolean>(false);
  const history = useHistory();
  const { entity_id } = useParams<any>();

  const { addToast } = useToast();

  useEffect(() => {
    if (entity_id && entity_id !== 'novo') {
      api
        .get(`equipments/${entity_id}`)
        .then(response => {
          const equipmentAux = response.data.item;

          console.log("EquipmentAux ==> ", equipmentAux);
          setEquipment({ ...equipmentAux });
        })
        .catch(err => {
          console.log(err);
          addToast({
            type: 'error',
            title: 'Falha na requisição',
            description:
              'Ocorreu um erro ao carregar os dados, verifique sua conexão e tente novamente.',
          });
        });
    }
  }, [refresh]);

  const convertToBase64 = useCallback((file) => {
    return new Promise((resolve) => {
      var reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onloadend = function () {
        var base64 = reader.result;
        resolve(base64);
      }
    });
  }, []);

  const sendForm = useCallback(
    async (data: Equipment) => {
      const isUpdate = entity_id && entity_id !== 'novo' ? true : false;

      var base64: any;
      if (data.photo) {
        base64 = await convertToBase64(data.photo);
        data.photo = base64;
      }

      try {
        let schema = Yup.object().shape({
          name: Yup.string().required('Nome obrigatório'),
          status: Yup.string().required('Status obrigatório'),
          cost: Yup.string().required('Custo obrigatório'),
          model: Yup.string().required('Custo obrigatório'),
          maker: Yup.string().required('Custo obrigatório'),
          total_of_points: Yup.string().required('Pontos obrigatório'),
          photo: isUpdate ? Yup.string() : Yup.string().required('Foto obrigatória'),
          description: Yup.string().nullable(),
        });
        await schema.validate(data, {
          abortEarly: false,
        });
        data.cost = parseFloat(data.cost);
        let response;
        if (isUpdate) {
          response = await api.put(`equipments/${entity_id}`, { ...data, photo: base64 });
          setRefresh(!refresh);
        } else {
          response = await api.post('equipments', data);
        }
        return ({ resp: true, message: response.data.success.message });
      } catch (err) {
        console.log(err);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          return ({ resp: false, errors });
          // formRef.current?.setErrors(errors);
          // addToast({
          //   type: 'error',
          //   title: 'Atenção!',
          //   description:
          //     'Preencha todos os campos obrigatórios.',
          // });
        } else {
          addToast({
            type: 'error',
            title: 'Falha na requisição',
            description:
              'Ocorreu um erro ao salvar, verifique os dados e tente novamente',
          });
        }
      }
    },
    [entity_id, refresh],
  );

  return (
    <Layout>
      <Container>
        <div className="breadcrumb">
          <h1>MDS &gt; Equipamentos &gt; {equipment ? equipment.name : 'Novo'}</h1>

          <button
            type="button"
            onClick={() => history.go(-1)}
            className="close-btn"
          >
            <FiXCircle />
          </button>
        </div>

        <FormContainer>
          <DefaultForm
            indexPath="/produtos/equipamentos"
            submitForm={(formData) => sendForm(formData)}
            currentData={{
              ...equipment,
              photo: equipment?.photo_url ? equipment?.photo_url : ''
            }}
            entity="equipments"
            inputs={[
              {
                input_type: "Input",
                name: "name",
                label: "Nome do equipamento",
              },
              {
                input_type: "MoneyInput",
                name: "cost",
                label: "Custo",
              },
              {
                input_type: "Input",
                name: "model",
                label: "Modelo",
              },
              {
                input_type: "Input",
                name: "maker",
                label: "Fabricante",
              },
              {
                input_type: "InputFile",
                name: "photo",
                label: "Foto do equipamento",
              },
              {
                input_type: "Input",
                type: "number",
                name: "total_of_points",
                label: "Total de pontos",
              },
              {
                input_type: "Select",
                name: "status",
                label: "Status",
                placeholder: "Selecione...",
                selectProps: {
                  options: [
                    { value: "Ativo", label: "Ativo" },
                    { value: "Inativo", label: "Inativo" }
                  ]
                }
              },
              {
                input_type: "Textarea",
                label: "Descrição do equipamento",
                name: "description",
                fullWidth: true,
              }
            ]}
          />
        </FormContainer>

      </Container>
    </Layout>
  );
};

export default FormModal;
