import React, { useEffect, useState, useCallback } from 'react';
import { useHistory, NavLink } from 'react-router-dom';
import moment from 'moment';
import { FiClipboard, FiClock, FiEdit, FiEye, FiTrash } from 'react-icons/fi';

import Layout from '../../components/Layout';
import { Container, List } from './styles';
import Table from '../../components/Table';
import Pagination from '../../components/Pagination';

import api from '../../services/api';
import DatePicker from '../../components/Form/DatePicker';
import { Form } from '@unform/web';
import SendProposalModal from '../../components/Modals/SendProposalModal';
import Select from '../../components/Form/Select';
import { HiClock } from 'react-icons/hi';
import ProposalsEditHistory from './history';
import { confirmAlert } from 'react-confirm-alert';
import { useToast } from '../../hooks/Toast';

// import { Proposal } from '../../interfaces/Proposal';

interface FilterProps {
  search?: string;
  date?: string;
  status?: string;
}

const Proposals: React.FC = () => {
  const [proposals, setProposals] = useState<any[]>([]); //Proposal[]
  const [filteredAttr, setFilteredAttr] = useState<FilterProps | null>();
  const [loadingData, setLoadingData] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [refresh, setRefresh] = useState(false);

  const [showForm, setShowForm] = useState(false);
  const [currentItem, setCurrentItem] = useState<any>();

  const history = useHistory();
  const { addToast } = useToast();

  useEffect(() => {
    setLoadingData(true);
    api
      .get('proposals', {
        params: {
          search: filteredAttr?.search,
          date: filteredAttr?.date,
          status: filteredAttr?.status,
          page: currentPage,
        },
      })
      .then(response => {
        setProposals(response.data.items);
        setLoadingData(false);
        setTotalPages(response.data.last_page);
      }).catch(err => {
        setLoadingData(false);
        console.log(err);
      });
  }, [filteredAttr, currentPage, refresh]);

  const handleEdit = useCallback(
    proposal => {
      history.push(`${process.env.PUBLIC_URL}/propostas/${proposal.id}`);
    },
    [history],
  );

  const viewHistory = useCallback(item => {
    setCurrentItem(item);
    setShowForm(true);
  }, []);

  let timeout: any;
  const handleSearchInput = useCallback((value) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      setFilteredAttr({ ...filteredAttr, search: value });
    }, 1000);
  }, [timeout, filteredAttr]);

  const handleSearchData = useCallback((value) => {
    setFilteredAttr({ ...filteredAttr, date: value });
  }, [filteredAttr]);

  const handleSearchStatus = useCallback((value) => {
    setFilteredAttr({ ...filteredAttr, status: value });
  }, [filteredAttr]);

  const handleDelete = (item: any) => {
    try {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="custom-ui">
              <h1>Confirmação</h1>
              <p>Gostaria de deletar {item.title}? (Está ação irreversível)</p>
              <button onClick={onClose}>Não</button>
              <button
                onClick={async () => {
                  await api.delete(
                    `proposals/${item.id}`
                  );

                  addToast({
                    type: 'success',
                    title: 'Proposta deletada com sucesso!',
                  });
                  setRefresh(!refresh);

                  onClose();
                }}
              >
                Sim, deletar!
              </button>
            </div>
          );
        },
      });
    } catch (err) {
      console.error('err', err);
      addToast({
        type: 'error',
        title: 'Falha na requisição',
        description:
          'Ocorreu um erro ao deletar os dados, atualize a página e tente novamente.',
      });
    }
  };

  return (
    <Layout>
      <Container>
        <div className="breadcrumb">
          <h1>MDS &gt; Propostas</h1>
        </div>

        <List>
          <Table>
            <div className="top">
              <div className="filter">
                <Form onSubmit={console.log}>
                  <input
                    type="text"
                    name="search"
                    placeholder="Busque por cliente/título"
                    onChange={event => handleSearchInput(event.target.value)}
                    className="filter-input"
                  />

                  <DatePicker
                    name="date"
                    placeholderText="Busque por data de criação"
                    onSelect={date => {
                      handleSearchData(date);
                    }}
                    onClear={() => {
                      console.log("cleared")
                      handleSearchData(null);
                    }}
                    className="filter-input"
                    isClearable
                  />

                  <Select
                    name="status"
                    placeholder="Busque por status"
                    onChange={(obj: any) => {
                      handleSearchStatus(obj?.value || '');
                    }}
                    isClearable
                    isSearchable={false}
                    options={[
                      { value: "Pendente", label: "Pendente" },
                      { value: "Aprovada", label: "Aprovada" },
                      { value: "Alterada", label: "Alterada" },
                      { value: "Cancelada", label: "Cancelada" },
                    ]}
                  />
                </Form>
              </div>
              <button
                type="button"
                className="new-btn"
                onClick={() =>
                  history.push(`${process.env.PUBLIC_URL}/propostas/novo`)
                }
              >
                Novo
              </button>
            </div>
            <div className="table-container">
              <table>
                <thead>
                  <tr>
                    <th>Título da proposta</th>
                    <th>Versão</th>
                    <th>Cliente</th>
                    <th>Data de criação</th>
                    <th>Status</th>
                    <th>Planilha de compras</th>
                    <th>Prop. Técnica</th>
                    <th>Prop. Comercial</th>
                    <th>Ações</th>
                  </tr>
                </thead>
                <tbody>
                  {loadingData ? (
                    <tr>
                      <td colSpan={9}>
                        Carregando propostas...
                      </td>
                    </tr>
                  ) : (
                    <>
                      {
                        proposals.length > 0 ?
                          proposals.map(proposal => (
                            <tr key={proposal.id.toString()}>
                              <td>{proposal.title}</td>
                              <td>{proposal.version}</td>
                              <td>{proposal.customer_name}</td>
                              <td>{moment(proposal.created_at).format('DD/MM/YYYY')}</td>
                              <td className="icon">{proposal.status}</td>
                              <td>
                                <NavLink
                                  to={`${process.env.PUBLIC_URL}/propostas/lista-compras/${proposal.id}`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="btn"
                                >
                                  <FiClipboard />
                                </NavLink>
                              </td>
                              <td>
                                <NavLink
                                  to={`${process.env.PUBLIC_URL}/propostas/pdf/${proposal.id}`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="btn"
                                >
                                  <FiEye />
                                </NavLink>
                              </td>
                              <td>
                                <NavLink
                                  to={`${process.env.PUBLIC_URL}/propostas/comercial/${proposal.id}`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="btn"
                                >
                                  <FiEye />
                                </NavLink>
                              </td>
                              <td>
                                <button
                                  type="button"
                                  onClick={() => {
                                    viewHistory(proposal);
                                  }}
                                  title="Histórico de edições"
                                >
                                  <FiClock />
                                </button>
                                <button
                                  type="button"
                                  onClick={() => handleEdit(proposal)}
                                >
                                  <FiEdit />
                                </button>
                                <button
                                  type="button"
                                  onClick={() => handleDelete(proposal)}
                                  title="Deletar"
                                >
                                  <FiTrash />
                                </button>
                              </td>
                            </tr>
                          )) : (
                            <>
                              <tr>
                                <td colSpan={9}>
                                  Nenhum resultado encontrado
                                  </td>
                              </tr>
                            </>
                          )
                      }
                    </>
                  )}
                </tbody>
              </table>
              <Pagination
                pages={totalPages}
                currentPage={currentPage}
                setPage={p => setCurrentPage(p)}
              />
            </div>
          </Table>
        </List>

        {showForm && (
          <ProposalsEditHistory
            item={currentItem}
            closeModal={() => setShowForm(false)}
          />
        )}

      </Container>
    </Layout>
  );
};

export default Proposals;
