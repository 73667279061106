import styled, { keyframes } from 'styled-components';

const appearFromTop = keyframes`
  from {
    opacity: 0;
    transform: translateY(-50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const Container = styled.div`
  width: 100%;
  background: #fff;
  padding: 30px;
  border-radius: 5px;
  margin-bottom: 50px;

  animation: ${appearFromTop} 1s;


  .top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.8rem;
    align-items: center;

    @media (max-width: 1024px) {
      flex-wrap: wrap;
    }

    @media (max-width: 768px) {
      flex-direction: column;
      width: 100%;
      padding: 1rem;
      background: #fff;

      button {
        width: 100%;
      }
    }

    &.to-right {
      justify-content: flex-end;
    }

    .filter {
      width: 100%;
      max-width: calc(100% - 100px);

      @media (max-width: 768px) {
        max-width: 100%;
        margin-bottom: 1rem;
      }

      form {
        display: flex;

        .label {
          > div {
            margin-bottom: 0;
          }
        }

        & > div, input {
          margin-right: 20px;
          max-width: 300px;
          width: 30%;
        }

        @media (max-width: 768px) {
          flex-direction: column;
          width: 100%;

          & > div, & > input {
            margin: 0 0 1rem;
            max-width: 100%;
            width: 100%;

            &:first-of-type {
              margin-bottom: 10px;
            }
          }
        }
      }

      input {
        height: 38px;
        background: #ffffff;
        border: 1px solid #c9c9c9;
        border-radius: 5px;
        width: 300px;
        color: var(--textColor);
        padding: 0 0.6rem;

        & + input {
          margin-left: 0.8rem;
        }

        &::placeholder {
          color: #c9c9c9;
        }

        @media (max-width: 768px) {
          width: 100%;
        }
      }
    }

    button:not(.react-datepicker__navigation, .react-datepicker__close-icon) {
      height: 38px;
      background-color: var(--primaryColor);
      border: 1px solid var(--primaryColor);
      color: var(--whiteColor);
      padding: 0 1.6rem;
      border-radius: 5px;
    }
  }

  .table-container {
    width: 100%;
    overflow-x: auto;

    table {
      width: 100%;
      border-radius: 5px;
      overflow: hidden;
      border-spacing: 0;
      min-width: 650px;

      thead {
        th {
          font-weight: 600;
          color: var(--whiteColor);
          background-color: var(--primaryColor);
          padding: 0.5rem 0.8rem;
        }
      }

      tbody {
        tr {
          &:nth-child(2n + 1) {
            background-color: #f5f5f5;
          }

          td {
            padding: 0.8rem;
            text-align: center;
            color: var(--textColor);
            white-space: nowrap;
            overflow: hidden;

            &.icon {
              svg {
                font-size: 22px;

                &.active {
                  color: #2baf39;
                }

                &.inactive {
                  color: #af081c;
                }
              }
            }

            button, .btn {
              color: #181818;
              border: 0;
              font-size: 25px;
              padding: 0;
              height: 20px;
              background-color: transparent;

              & + button {
                margin-left: 10px;
              }

              a {
                font-size: 12px;
                display: flex;
                align-items: center;
                justify-content: center;
                text-decoration: none;
                color: #313131;
                margin-right: 5px;

                svg {
                  margin-right: 3px;
                }
              }
            }

            span.status {
              width: 30px;
              height: 30px;
              background: #ffe35a;
              display: inline-block;
              border-radius: 50%;
            }
          }

          &.loading {
            td {
              padding: 0;
              position: relative;

              .alert {
                border-radius: 0 0 5px 5px;
              }
            }
          }
        }
      }
      .alert {
        background: #ddd;
        padding: 20px;
        border-radius: 10px;
        color: #333;
        font-weight: 500;
      }
    }
  }

  @media (max-width: 769px) {
    padding: 0;
    background: transparent;

    /* table {
      background: #fff;
      display: flex;

      thead {
        tr {
          width: 100px;
          display: inline-block;
          vertical-align: top;

          td {
            text-align: right;
            display: flex;
            align-items: center;
          }
        }
      }

      tbody {
        display: block;
        width: auto;
        position: relative;
        overflow-x: auto;
        white-space: nowrap;

        tr {
          width: 160px;
          display: inline-block;
          vertical-align: top;
          border-right: 1px solid #6788ba;

          td {
            padding: 0 15px;
            color: #9d9d9d;
            display: flex;
            align-items: center;
            height: 61px;
            white-space: normal;
            text-align: left;
          }
        }
      }
    } */
  }
`;
