import React, { useCallback, useEffect, useState } from 'react';
import { PDFViewer, Document, Page, Text, View, Image, Link, StyleSheet, usePDF } from '@react-pdf/renderer';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/pt-br';

import api from '../../services/api';

import { useToast } from '../../hooks/Toast';

import LogoMds from '../../assets/logotipo-mds-security.jpg';
import Logo from '../../assets/logo.png';
import MapaRMC from '../../assets/Mapa-RMC.svg.png';
import CNPJ from '../../assets/CNPJ.jpg';
import ServicosImg from '../../assets/servicos.png';
import CapaDaAgua from '../../assets/papel-timbrado-mds-02.jpg';
import Whats from '../../assets/whatsapp.png';
import RightArrow from '../../assets/right-arrow-of-straight-thin-line.png';
import Check from '../../assets/check-icon.png';
import Fornecedores from '../../assets/fornecedores.jpg';

import { Proposal, Product } from '../../interfaces/Proposal';

const Header = () => (
  <View style={geral.header} fixed>
    <Image src={LogoMds} style={geral.headerLogo} />
  </View>
);

const Footer = () => (
  <View style={geral.footer} fixed>
    <View style={geral.footerCol1}>
      <Text style={geral.TextFooter}>&middot; Av. Antônio Artioli, 570 - Prédio Flims{"\n"}Swiss Park Office - Campinas/SP{"\n"}</Text>
      <Text style={geral.TextFooter}>&middot; R. Guarujá, 86 - São Bernardo - Campinas/SP{"\n"}</Text>
      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
        <Image src={Whats} style={{ height: '10px', marginRight: 2 }} />
        <Text style={geral.TextFooter}>
          19 3273.7339 |&nbsp;
          <Text style={{ color: '#0077cc', fontFamily: 'Helvetica-Bold' }}>
            <Link src="www.mdssecurity.com.br">
              www.mdssecurity.com.br
            </Link>
          </Text>
        </Text>
      </View>
    </View>
    <View style={geral.footerCol2}>
      <Image src={ServicosImg} style={geral.footerImg} />
    </View>
  </View>
);

interface DocInterface {
  proposal?: Proposal | null;
  customers?: any[];
}

const MyDoc = ({ proposal, customers }: DocInterface) => {

  function calcTotalServices() {
    let totalValue: any = 0;

    proposal?.products?.map((product) => {
      product.services.map((service) => {
        totalValue = parseFloat(totalValue) + parseFloat(service.total_cost);
      })
    })

    return totalValue;
  }

  function calcSpecificEquipmentTotal(data: any) {
    let totalValue = 0;

    const withMargin = data.total_cost + (((proposal?.applied_margin || 0) / 100) * data.total_cost);
    totalValue = totalValue + (withMargin + (withMargin * ((proposal?.applied_tax || 0) / 100)));

    return totalValue;
  }

  function calcTotalEquipments(data: any) {
    let totalValue = 0;
    data.equipments.map((item2: any) => {
      const withMargin = item2.total_cost + (((proposal?.applied_margin || 0) / 100) * item2.total_cost);
      totalValue = totalValue + (withMargin + (withMargin * ((proposal?.applied_tax || 0) / 100)));
    });

    data.infrastructures.map((item2: any) => {
      const withMargin = item2.total_cost + (((proposal?.applied_margin || 0) / 100) * item2.total_cost);
      totalValue = totalValue + (withMargin + (withMargin * ((proposal?.applied_tax || 0) / 100)));
    });

    data.miscellanies.map((item2: any) => {
      const withMargin = item2.total_cost + (((proposal?.applied_margin || 0) / 100) * item2.total_cost);
      totalValue = totalValue + (withMargin + (withMargin * ((proposal?.applied_tax || 0) / 100)));
    });
    return totalValue;
  }

  function calcTotalLabor(data: any) {
    let totalValue = 0;
    data.labor_equipments.map((item2: any) => {
      const withMargin = item2.total_cost + (((proposal?.applied_margin || 0) / 100) * item2.total_cost);
      totalValue = totalValue + (withMargin + (withMargin * ((proposal?.applied_tax || 0) / 100)));
    });

    data.general_labors.map((item2: any) => {
      const withMargin = item2.total_cost + (((proposal?.applied_margin || 0) / 100) * item2.total_cost);
      totalValue = totalValue + (withMargin + (withMargin * ((proposal?.applied_tax || 0) / 100)));
    });
    return totalValue;
  }

  function calcValues(data: any) {
    let totalValue = 0;
    data.map((item: any) => {
      const withMargin = item.total_cost + (((proposal?.applied_margin || 0) / 100) * item.total_cost);
      totalValue = totalValue + (withMargin + (withMargin * ((proposal?.applied_tax || 0) / 100)));
    })
    return totalValue;
  }

  function calcProductValue(data: Product) {
    let totalValue = 0;

    data.equipments.map(item => {
      const withMargin = item.total_cost + (((proposal?.applied_margin || 0) / 100) * item.total_cost);
      totalValue = totalValue + (withMargin + (withMargin * ((proposal?.applied_tax || 0) / 100)));
    });

    data.miscellanies.map(item => {
      const withMargin = item.total_cost + (((proposal?.applied_margin || 0) / 100) * item.total_cost);
      totalValue = totalValue + (withMargin + (withMargin * ((proposal?.applied_tax || 0) / 100)));
    });

    data.infrastructures.map(item => {
      const withMargin = item.total_cost + (((proposal?.applied_margin || 0) / 100) * item.total_cost);
      totalValue = totalValue + (withMargin + (withMargin * ((proposal?.applied_tax || 0) / 100)));
    });

    return totalValue;
  }

  function calcProductsValue(data: Proposal) {
    let totalValue = 0;
    data.products.map(item => {
      item.equipments.map(item => {
        const withMargin = item.total_cost + (((proposal?.applied_margin || 0) / 100) * item.total_cost);
        totalValue = totalValue + (withMargin + (withMargin * ((proposal?.applied_tax || 0) / 100)));
      });

      item.miscellanies.map(item => {
        const withMargin = item.total_cost + (((proposal?.applied_margin || 0) / 100) * item.total_cost);
        totalValue = totalValue + (withMargin + (withMargin * ((proposal?.applied_tax || 0) / 100)));
      });

      item.infrastructures.map(item => {
        const withMargin = item.total_cost + (((proposal?.applied_margin || 0) / 100) * item.total_cost);
        totalValue = totalValue + (withMargin + (withMargin * ((proposal?.applied_tax || 0) / 100)));
      });
    })
    return totalValue;
  }

  function calcLabor(data: Proposal) {
    let totalValue = 0;
    data.products.map(item => {
      item.general_labors.map((item: any) => {
        const withMargin = item.total_cost + (((proposal?.applied_margin || 0) / 100) * item.total_cost);
        totalValue = totalValue + (withMargin + (withMargin * ((proposal?.applied_tax || 0) / 100)));
      });

      item.labor_equipments.map((item: any) => {
        const withMargin = item.total_cost + (((proposal?.applied_margin || 0) / 100) * item.total_cost);
        totalValue = totalValue + (withMargin + (withMargin * ((proposal?.applied_tax || 0) / 100)));
      });
    })
    return totalValue;
  }

  const checkIfHasMonthlyServices = (products: Product[]) => {
    let state = false;

    if (products) {
      products.map((product: Product) => {
        if (product.services.length > 0) {
          state = true;
        }
      });
    }

    return state;
  };

  const checkIfProductHasSomeProvision = (product: Product) => {
    let state = false;

    if (
      product.equipments.length > 0 ||
      product.miscellanies.length > 0 ||
      product.infrastructures.length > 0 ||
      product.services.length > 0 ||
      product.general_labors.length > 0
    ) {
      state = true;
    }

    return state;
  };

  const productHasEquipments = (product: Product) => {
    let state = false;

    if (product.equipments.length > 0) {
      state = true;
    }

    return state;
  };

  const productHasSomeButServices = (product: Product) => {
    let state = false;

    if (
      product.equipments.length > 0 ||
      product.miscellanies.length > 0 ||
      product.infrastructures.length > 0
    ) {
      state = true;
    }

    return state;
  };

  return (
    <Document >
      {/* PAGE1 */}
      <Page size="A4" style={geral.page}>
        <Image src={CapaDaAgua} style={geral.bg} />
        <Header />
        <View style={page1.cover}>
          <Text style={{ fontSize: '40px', fontFamily: 'Helvetica-Bold' }}>PROPOSTA COMERCIAL</Text>
          <Image style={page1.logo} src={proposal?.customer_logo || Logo} />
          <Text style={{ fontSize: '30px', fontFamily: 'Helvetica-Bold' }}>{proposal?.customer_name}</Text>
          <Text style={{ fontSize: '15px', fontFamily: 'Helvetica-Bold' }}>Proposta Nº PC 001.{moment(proposal?.created_at).format('DDMMYYYY')}.{proposal?.version || '00'}.00</Text>
          <Text style={{ fontSize: '15px', fontFamily: 'Helvetica-Bold' }}>{moment(proposal?.created_at).format('MMMM/YYYY').toUpperCase()}</Text>
        </View>
        <Footer />
      </Page>
      {/* PAGE 2 */}
      <Page size="A4" style={geral.page}>
        <Image src={CapaDaAgua} style={geral.bg} />
        <Header />
        <View style={page2.section}>
          <View>
            <Text style={geral.TitleBold}>
              {proposal?.company_treatment_pronoun}{"\n"}
              {proposal?.customer_name}{"\n"}
            </Text>
            <Text style={geral.Title}>
              {proposal?.customer_address ? proposal?.customer_address + ', ' + `${(proposal?.customer_address_number ?? "") + "\n" || '' + "\n"}` : "\n"}
              {proposal?.customer_city ? proposal?.customer_city + '/' + proposal?.customer_uf + "\n" : ''}
              A/C {proposal?.treatment_pronoun === 'Prezado Senhor' ? 'Sr.' : 'Sra.'} {proposal?.responsible_name}
            </Text>
          </View>
          <Text style={geral.Text1}>
            {proposal?.treatment_pronoun},{"\n"}{"\n"}
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Temos o prazer de apresentar nossa Proposta Comercial para {proposal?.title}.{"\n"}{"\n"}
            A MDS SECURITY, terá a honra de poder trabalhar para vossa empresa com qualidade e eficiência.</Text>
          <Text style={geral.Text1}>Atenciosamente,{"\n"}{"\n"}
            Manoel dos Santos{"\n"}
            Gerente Comercial{"\n"}
            <Text style={{ color: '#0077cc' }}><Link src="mailto:mds@mdssecurity.com.br">mds@mdssecurity.com.br</Link></Text>{"\n"}
            Fone: 19-3273-7339
          </Text>
        </View>
        <Footer />
      </Page>
      {/* PAGE3 */}
      <Page size="A4" style={geral.page}>
        <Image src={CapaDaAgua} style={geral.bg} />
        <Header />
        <View style={page3.section}>
          <Text style={{ textAlign: 'center', fontSize: '18px', marginBottom: 20, marginTop: 30 }}>ÍNDICE</Text>
          <View style={page3.item}>
            <Text style={geral.indexText}>
              1.&nbsp;&nbsp;&nbsp;A MDS SECURITY
            </Text>
            <View style={page3.dotted} />
            <Text style={geral.indexText}>4</Text>
          </View>
          <View style={page3.item}>
            <Text style={geral.indexText}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Atuação diversificada:
            </Text>
            <View style={page3.dotted} />
            <Text style={[geral.indexText, { fontFamily: 'Helvetica' }]}>4</Text>
          </View>
          <View style={page3.item}>
            <Text style={geral.indexText}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Área de Atuação:
            </Text>
            <View style={page3.dotted} />
            <Text style={[geral.indexText, { fontFamily: 'Helvetica' }]}>4</Text>
          </View>
          <View style={page3.item}>
            <Text style={geral.indexText}>
              2.&nbsp;&nbsp;&nbsp;NOSSA MISSÃO
            </Text>
            <View style={page3.dotted} />
            <Text style={geral.indexText}>5</Text>
          </View>
          <View style={page3.item}>
            <Text style={geral.indexText}>
              3.&nbsp;&nbsp;&nbsp;NOSSA VISÃO
            </Text>
            <View style={page3.dotted} />
            <Text style={geral.indexText}>5</Text>
          </View>
          <View style={page3.item}>
            <Text style={geral.indexText}>
              4.&nbsp;&nbsp;&nbsp;NOSSOS VALORES
            </Text>
            <View style={page3.dotted} />
            <Text style={geral.indexText}>5</Text>
          </View>
          {/* <View style={page3.item}>
            <Text style={geral.indexText}>
              5.&nbsp;&nbsp;&nbsp;DOCUMENTO LEGAL
            </Text>
            <View style={page3.dotted} />
            <Text style={geral.indexText}>6</Text>
          </View> */}
          <View style={page3.item}>
            <Text style={geral.indexText}>
              5.&nbsp;&nbsp;&nbsp;ALGUNS FORNECEDORES
            </Text>
            <View style={page3.dotted} />
            <Text style={geral.indexText}>6</Text>
          </View>
          <View style={page3.item}>
            <Text style={geral.indexText}>
              6.&nbsp;&nbsp;&nbsp;ALGUNS CLIENTES
            </Text>
            <View style={page3.dotted} />
            <Text style={geral.indexText}>7</Text>
          </View>
          <View style={page3.item}>
            <Text style={geral.indexText}>
              7.&nbsp;&nbsp;&nbsp;OBJETIVO
            </Text>
            <View style={page3.dotted} />
            <Text style={geral.indexText}>8</Text>
          </View>
          <View style={page3.item}>
            <Text style={geral.indexText}>
              8.&nbsp;&nbsp;&nbsp;CONFIDENCIALIDADE
            </Text>
            <View style={page3.dotted} />
            <Text style={geral.indexText}>9</Text>
          </View>
          <View style={page3.item}>
            <Text style={geral.indexText}>
              9.&nbsp;&nbsp;SEGURANÇA DO TRABALHO
            </Text>
            <View style={page3.dotted} />
            <Text style={geral.indexText}>10</Text>
          </View>
          <View style={page3.item}>
            <Text style={geral.indexText}>
              10.&nbsp;&nbsp;CONSIDERAÇOES GERAIS
            </Text>
            <View style={page3.dotted} />
            <Text style={geral.indexText}>11</Text>
          </View>
          <View style={page3.item}>
            <Text style={geral.indexText}>
              11.&nbsp;&nbsp;ESCOPO DE FORNECIMENTO
            </Text>
            <View style={page3.dotted} />
            <Text style={geral.indexText}>12</Text>
          </View>

          {
            proposal?.has_summary_of_values ? (
              <View style={page3.item}>
                <Text style={geral.indexText}>
                  13.&nbsp;&nbsp;PROPOSTA COMERCIAL
                </Text>
                <View style={page3.dotted} />
                {/* <Text style={geral.indexText}>12</Text> */}
              </View>
            ) : (
              <></>
            )
          }

          {
            proposal?.has_summary_of_values ? (
              <View style={page3.item}>
                <Text style={geral.indexText}>
                  14.&nbsp;&nbsp;BENEFICIOS E VANTAGENS DO ALUGUEIS DE EQUIPAMENTOS
                </Text>
                <View style={page3.dotted} />
                {/* <Text style={geral.indexText}>14</Text> */}
              </View>
            ) : (
              <></>
            )
          }

          {
            !proposal?.has_summary_of_values ? (
              <View style={page3.item}>
                <Text style={geral.indexText}>
                  13.&nbsp;&nbsp;BENEFICIOS E VANTAGENS DO ALUGUEIS DE EQUIPAMENTOS
                </Text>
                <View style={page3.dotted} />
                {/* <Text style={geral.indexText}>14</Text> */}
              </View>
            ) : (
              <></>
            )
          }

        </View>
        <Footer />
      </Page>
      {/* PAGE4 */}
      <Page size="A4" style={geral.page}>
        <Image src={CapaDaAgua} style={geral.bg} />
        <Header />
        <View style={page2.section}>
          <Text style={geral.TitleBold}>1. A MDS SECURITY</Text>
          <Text style={geral.Text1}>
            Experiência acumulada em mais de 20 anos de presença no mercado, mais de 5.000
            clientes atendidos e mais de 2.000 projetos de integração de sistemas de segurança.
            Atuação regional assegurando atendimento de qualidade em Campinas e toda a Região
            Metropolitana.
          </Text>
          <Text style={geral.TitleBold}>Atuação diversificada:</Text>
          <Text style={geral.Text1}>
            Com experiência adquirida em seu tempo de atuação no segmento Segurança
            Eletrônica, a MDS SECURITY está qualificada para atuar em diversos segmentos da
            atividade econômica:
          </Text>
          <View>
            <View style={{ flexDirection: 'row', alignItems: 'center', paddingHorizontal: 10, marginBottom: 5 }}>
              <Image src={Check} style={{ height: '9px', marginRight: 6, marginTop: -2 }} />
              <Text style={geral.Text1}>Comercial;</Text>
            </View>
            <View style={{ flexDirection: 'row', alignItems: 'center', paddingHorizontal: 10, marginBottom: 5 }}>
              <Image src={Check} style={{ height: '9px', marginRight: 6, marginTop: -2 }} />
              <Text style={geral.Text1}>Residencial;</Text>
            </View>
            <View style={{ flexDirection: 'row', alignItems: 'center', paddingHorizontal: 10, marginBottom: 5 }}>
              <Image src={Check} style={{ height: '9px', marginRight: 6, marginTop: -2 }} />
              <Text style={geral.Text1}>Logística &amp; Transportes;</Text>
            </View>
            <View style={{ flexDirection: 'row', alignItems: 'center', paddingHorizontal: 10, marginBottom: 5 }}>
              <Image src={Check} style={{ height: '9px', marginRight: 6, marginTop: -2 }} />
              <Text style={geral.Text1}>Varejo;</Text>
            </View>
            <View style={{ flexDirection: 'row', alignItems: 'center', paddingHorizontal: 10, marginBottom: 5 }}>
              <Image src={Check} style={{ height: '9px', marginRight: 6, marginTop: -2 }} />
              <Text style={geral.Text1}>Energia;</Text>
            </View>
            <View style={{ flexDirection: 'row', alignItems: 'center', paddingHorizontal: 10, marginBottom: 5 }}>
              <Image src={Check} style={{ height: '9px', marginRight: 6, marginTop: -2 }} />
              <Text style={geral.Text1}>Construção Civil – Condomínios &amp; Shoppings;</Text>
            </View>
            <View style={{ flexDirection: 'row', alignItems: 'center', paddingHorizontal: 10, marginBottom: 5 }}>
              <Image src={Check} style={{ height: '9px', marginRight: 6, marginTop: -2 }} />
              <Text style={geral.Text1}>Esporte e Lazer - Estádios e Ginásios Esportivos;</Text>
            </View>
            <View style={{ flexDirection: 'row', alignItems: 'center', paddingHorizontal: 10, marginBottom: 5 }}>
              <Image src={Check} style={{ height: '9px', marginRight: 6, marginTop: -2 }} />
              <Text style={geral.Text1}>Escolas;</Text>
            </View>
            <View style={{ flexDirection: 'row', alignItems: 'center', paddingHorizontal: 10, marginBottom: 5 }}>
              <Image src={Check} style={{ height: '9px', marginRight: 6, marginTop: -2 }} />
              <Text style={geral.Text1}>Hospitais;</Text>
            </View>
          </View>
          <Text style={geral.TitleBold}>Área de Atuação:</Text>
          <Image src={MapaRMC} style={page4.mapa} />
        </View>
        <Footer />
      </Page>
      {/* PAGE5 */}
      <Page size="A4" style={geral.page}>
        <Image src={CapaDaAgua} style={geral.bg} />
        <Header />
        <View style={page2.section}>
          <Text style={geral.TitleBold}>2. NOSSA MISSÃO</Text>
          <Text style={geral.Text1}>
            Procurar sempre a qualidade total nos serviços prestados, atendendo as
            necessidade dos clientes, com tecnologia de ponta sempre com rapidez e
            confiabilidade.
          </Text>
          <Text style={geral.TitleBold}>3. NOSSA VISÃO</Text>
          <Text style={geral.Text1}>
            Ser reconhecida nacionalmente como empresa modelo de segurança
            eletrônica, pela sua competência, confiabilidade, ética e qualidade
            nos serviços prestados sempre atendendo a expectativa de nossos
            clientes.
          </Text>
          <Text style={geral.TitleBold}>4. NOSSOS VALORES</Text>
          <Text style={geral.Text1}>
            Transparência e ética, agilidade, e qualidade total nos serviços
            prestados, sempre com inovações técnicas e treinamento de seus
            colaboradores internos e terceirizados.{"\n"}
            Trabalhamos com materiais e equipamentos de excelente qualidade, e
            contamos com profissionais com larga experiência no mercado, com mais
            de 20 anos de atuação em prestação de serviço de monitoramento e
            instalação de sistemas de alarme, cerca elétrica e CFTV.
          </Text>
          <Image style={[page1.logo, { height: 150 }]} src={LogoMds} />
        </View>
        <Footer />
      </Page>
      {/* PAGE6 */}
      {/* <Page size="A4" style={geral.page}>
        <Image src={CapaDaAgua} style={geral.bg} />
        <Header />
        <View style={page2.section}>
          <Text style={geral.TitleBold}>5. DOCUMENTO LEGAL</Text>
          <Image src={CNPJ} style={{ objectFit: 'scale-down' }} />
        </View>
        <Footer />
      </Page> */}
      {/* PAGE7 */}
      <Page size="A4" style={geral.page}>
        <Image src={CapaDaAgua} style={geral.bg} />
        <Header />
        <View style={page2.section}>
          <Text style={geral.TitleBold}>5. ALGUNS FORNECEDORES</Text>
          <Image src={Fornecedores} style={{ objectFit: 'contain' }} />
        </View>
        <Footer />
      </Page>
      {/* PAGE8 */}
      <Page size="A4" style={geral.page}>
        <Image src={CapaDaAgua} style={geral.bg} />
        <Header />
        <View style={page3.section}>
          <Text style={geral.TitleBold}>6. ALGUNS CLIENTES</Text>
          <View style={geral.customerList}>
            {
              customers?.map((customer: any, index: number) => (
                <Image src={customer.logo_url} style={geral.logoCustomer} key={index.toString()} />
              ))
            }
          </View>
        </View>
        <Footer />
      </Page>
      {/* PAGE9 */}
      <Page size="A4" style={geral.page}>
        <Image src={CapaDaAgua} style={geral.bg} />
        <Header />
        <View style={page2.section}>
          <Text style={geral.TitleBold}>7. OBJETIVO</Text>
          <Text style={geral.Text1}>
            A presente proposta consiste na {proposal?.title}.
          </Text>

          <Text style={geral.TitleBold}>8. CONFIDENCIALIDADE</Text>
          <Text style={geral.Text1}>
            Considerando que as informações contidas na presente proposta incluem, mas não se
            limitam a, cláusulas contratuais, dados, informações comerciais e financeiras, fórmulas,
            composições e processos industriais, know-how, especificações, designs, modelos,
            desenhos, fluxogramas, clientes e fornecedores, informações técnicas (coletivamente as
            "Informações Confidenciais"), de propriedade exclusiva entre as partes, seus sócios e/ou
            de empresas do mesmo grupo econômico e sujeitas a manutenção de sigilo; a MDS
            SECURITY considera que o cliente compromete-se, por prazo indeterminado e
            independentemente do término ou rescisão da relação comercial com
            entre as partes, a manter estritamente confidenciais e em absoluto sigilo todos os
            documentos, informações, experiências e registros que lhe forem transmitidos,
            revelados e/ou divulgados, por quaisquer meios, não revelando tais Informações
            Confidenciais, no todo ou em parte, a quaisquer terceiros, nem as utilizar em proveito
            próprio, exceto se mediante prévia e expressa autorização por escrito entre as partes.
          </Text>

          <Text style={geral.TitleBold}>9. SEGURANÇA DO TRABALHO</Text>
          <View>
            <View style={{ flexDirection: 'row' }}>
              <Image src={RightArrow} style={{ height: '17px', objectFit: 'contain', marginRight: 4, marginTop: -2 }} />
              <Text style={geral.Text1}>
                A MDS SECURITY, assume total responsabilidade pela manutenção dos
                equipamentos, em conformidade com as leis e normais vigentes no país, inerente
                às atividades a serem executadas (ex: serviços em altura, EPI’s, EPC’s, etc.), tais
                como capacetes, luvas, botas de borracha, cintos especiais, etc.{"\n"}{"\n"}
              </Text>
            </View>
            <View style={{ flexDirection: 'row' }}>
              <Image src={RightArrow} style={{ height: '17px', objectFit: 'contain', marginRight: 4, marginTop: -2 }} />
              <Text style={geral.Text1}>
                A MDS SECURITY, não permite que seus funcionários ou prepostos trabalhem
                sem IPI`s. Em caso de constatação, os serviços deveram ser paralisados até a
                presença do responsável.{"\n"}{"\n"}
              </Text>
            </View>
            <View style={{ flexDirection: 'row' }}>
              <Image src={RightArrow} style={{ height: '17px', objectFit: 'contain', marginRight: 4, marginTop: -2 }} />
              <Text style={geral.Text1}>
                A MDS SECURITY é responsável por quaisquer danos causados por prepostos,
                sendo das empresas do Grupo e/ou subcontratados para execução de serviço
                não fins, objeto da contratação.
              </Text>
            </View>
          </View>

        </View>
        <Footer />
      </Page>
      {/* PAGE10 */}
      <Page size="A4" style={geral.page}>
        <Image src={CapaDaAgua} style={geral.bg} />
        <Header />
        <View style={page2.section}>
          <View style={{ marginBottom: 15 }}>
            <Text style={geral.TitleBold}>10. CONSIDERAÇOES GERAIS</Text>
          </View>
          <View style={{ flex: 1, flexDirection: 'column', justifyContent: 'space-evenly', paddingHorizontal: 15 }} wrap={false}>
            <View wrap={false}>
              <Text style={geral.TitleBold}>10.1 SERVIÇOS</Text>
              <Text style={geral.Text1}>
                Esta proposta contempla os serviços de instalação e configuração dos equipamentos
                mencionados no escopo de fornecimento, bem como, treinamento operacional e administrativo
                do respectivo sistema.{"\n"}
                A contratante deve nomear seus representantes a serem treinados para o devido uso dos
                equipamentos e do sistema integrado como um todo. Caso seja feita opção em contratar o
                Plano de Manutenção, a contratante deve comunicar formalmente a MDS SECURITY quando do
                desligamento deste representante e solicitar a qualquer tempo o treinamento do novo
                representante, podendo incidir novos custos nestes casos, caso exceda a cota prevista em
                contrato.{"\n"}
                O valor apresentado considera que os serviços a serem prestados sejam realizados no horário
                comercial (segunda à sexta-feira das 8h00 às 18h00). Caso possua restrições para execução dos
                serviços dentro do horário comercial, a contratante deverá comunicar formalmente para que a
                proposta seja revisada.
              </Text>
            </View>

            <View>
              <Text style={geral.TitleBold}>10.2 GARANTIA DE SERVIÇOS</Text>
              <Text style={geral.Text1}>
                Todos os equipamentos fornecidos e instalados por técnicos da <Text style={{ fontFamily: 'Helvetica-Bold' }}>CONTRATADA</Text> e/ou por
                terceiros da <Text style={{ fontFamily: 'Helvetica-Bold' }}>CONTRATADA</Text> possuem garantia conforme Código de Defesa do Consumidor,
                inciso II do Artigo 26 contra defeito de fabricação ou instalação iniciando-se a contagem do
                prazo decadencial a partir da entrega efetiva do produto ou do término da execução dos
                serviços.
              </Text>
            </View>

            <View>
              <Text style={geral.TitleBold}>10.3 INFORMAÇÃO</Text>
              <Text style={geral.Text1}>
                Cabe a contratante a leitura e análise da presente proposta, esta contempla apenas e tão
                somente o fornecimento dos equipamentos e serviços mencionados no escopo apresentado,
                qualquer divergência que seja identificada quanto aos equipamentos e/ou serviços e que não
                estejam inclusos, a contratante deverá comunicar formalmente para que a proposta seja
                revisada.
              </Text>
            </View>

            <View>
              <Text style={geral.TitleBold}>10.4 REDES E ATIVOS DE INFORMATICA</Text>
              <Text style={geral.Text1}>
                Caberá a contratante nomear o responsável para conferir, aferir e validar o sistema caso este
                venha a ser projetado para utilizar rede corporativa| legada | existente, ressaltando que neste
                caso caberá a contratante a manutenção da rede e demais ativos. Eventuais alterações
                solicitadas pela contratante, posteriores ao fechamento da presente proposta, serão tratadas
                como aditivo.{"\n"}
                Fica sob responsabilidade da contratante o fornecimento dos recursos e meios necessários para
                prover o acesso remoto ao servidor de gravação de imagem e controle de acesso, tais como,
                endereço IP, DNS dinâmico e configurações de roteamento.
              </Text>
            </View>
          </View>
        </View>
        <Footer />
      </Page>
      {/* PAGE11 */}
      <Page size="A4" style={geral.page}>
        <Image src={CapaDaAgua} style={geral.bg} />
        <Header />
        <View style={page2.scopeSection}>
          <Text fixed style={geral.TitleBoldWithMargin}>11. ESCOPO DE FORNECIMENTO</Text>

          {proposal?.products.map((product, index) => (
            <View key={index.toString()}>
              {
                checkIfProductHasSomeProvision(product) ? (
                  <>
                    <Text style={geral.TitleBoldWithMargin}>{product.name}</Text>

                    {!proposal?.has_unit_values ? (
                      <View style={geral.table} wrap={false}>
                        <View style={geral.row} wrap={false}>
                          {productHasSomeButServices(product) ? (
                            <>
                              <Text style={[geral.col, { width: '10%', paddingVertical: 6, fontFamily: 'Helvetica-Bold' }]}>QTD.</Text>
                              <Text style={[geral.col, { width: '20%', paddingVertical: 6, fontFamily: 'Helvetica-Bold' }]}>MODELO</Text>
                              <Text style={[geral.col, { width: '50%', paddingVertical: 6, fontFamily: 'Helvetica-Bold' }]}>DESCRIÇÃO</Text>
                              <Text style={[geral.col, { width: '20%', paddingVertical: 6, fontFamily: 'Helvetica-Bold' }]}>FABRICANTE</Text>
                            </>
                          ) : (
                            <></>
                          )}
                        </View>
                        {product?.equipments.map((item, index) => (
                          <View style={geral.row} key={index.toString()} wrap={false}>
                            <Text style={[geral.col, { width: '10%' }]}>{item.qtd}</Text>
                            <Text style={[geral.col, { width: '20%' }]}>{item.model}</Text>
                            <Text style={[geral.col, { width: '50%' }]}>{item.name}</Text>
                            <Text style={[geral.col, { width: '20%' }]}>{item.maker}</Text>
                          </View>
                        ))}

                        {
                          product?.miscellanies.length > 0 && calcValues(product?.miscellanies) ? (
                            <View style={geral.row} wrap={false}>
                              <Text style={[geral.col, { width: '10%' }]}>1</Text>
                              <Text style={[geral.col, { width: '90%' }]}>MISCELANEAS</Text>
                            </View>
                          ) : (
                            <></>
                          )
                        }

                        {
                          product?.infrastructures.length > 0 && calcValues(product?.infrastructures) > 0 ? (
                            <View style={geral.row} wrap={false}>
                              <Text style={[geral.col, { width: '10%' }]}>1</Text>
                              <Text style={[geral.col, { width: '90%' }]}>MATERIAL DE INFRAESTRUTURA, TUBULAÇÃO E ACABAMENTO</Text>
                            </View>
                          ) : (
                            <></>
                          )
                        }

                        {
                          calcValues([
                            ...product?.labor_equipments,
                            ...product?.general_labors
                          ]) > 0 ? (
                            <View style={geral.row} wrap={false}>
                              <Text style={[geral.col, { width: '100%' }]}>{product?.labor_title && !!product?.labor_title ? product?.labor_title : `MÃO DE OBRA INSTALAÇÃO`}</Text>
                            </View>
                          ) : (
                            <></>
                          )
                        }
                      </View>
                    ) : (
                      <View style={geral.table}>
                        <View style={geral.row} wrap={false}>
                          {
                            productHasSomeButServices(product) ? (
                              <>
                                <Text style={[geral.col, { width: '7%', paddingVertical: 6, fontFamily: 'Helvetica-Bold' }]}>QTD.</Text>
                                <Text style={[geral.col, { width: '15%', paddingVertical: 6, fontFamily: 'Helvetica-Bold' }]}>MODELO</Text>
                                <Text style={[geral.col, { width: '30%', paddingVertical: 6, fontFamily: 'Helvetica-Bold' }]}>DESCRIÇÃO</Text>
                                <Text style={[geral.col, { width: '16%', paddingVertical: 6, fontFamily: 'Helvetica-Bold' }]}>FABRICANTE</Text>
                              </>
                            ) : (
                              <></>
                            )
                          }
                          {
                            !productHasEquipments(product) ? (
                              <>
                                <Text style={[geral.col, { width: '7%', paddingVertical: 6, fontFamily: 'Helvetica-Bold' }]}>QTD.</Text>
                                <Text style={[geral.col, { width: '61%', paddingVertical: 6, fontFamily: 'Helvetica-Bold' }]}>DESCRIÇÃO</Text>
                              </>
                            ) : (
                              <></>
                            )
                          }
                          <Text style={[geral.col, { width: '16%', paddingVertical: 6, fontFamily: 'Helvetica-Bold' }]}>VALOR UNITÁRIO</Text>
                          <Text style={[geral.col, { width: '16%', paddingVertical: 6, fontFamily: 'Helvetica-Bold' }]}>VALOR TOTAL</Text>
                        </View>

                        {product?.equipments.map((item, index) => (
                          <View style={geral.row} key={index.toString()} wrap={false}>
                            <Text style={[geral.col, { width: '7%' }]}>{item.qtd}</Text>
                            <Text style={[geral.col, { width: '15%' }]}>{item.model}</Text>
                            <Text style={[geral.col, { width: '30%' }]}>{item.name}</Text>
                            <Text style={[geral.col, { width: '16%' }]}>{item.maker}</Text>
                            <Text style={[geral.col, { width: '16%' }]}>{(calcSpecificEquipmentTotal(item) / item.qtd)?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</Text>
                            <Text style={[geral.col, { width: '16%' }]}>{calcSpecificEquipmentTotal(item)?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</Text>
                          </View>
                        ))}

                        {
                          product?.miscellanies?.length > 0 && calcValues(product?.miscellanies) > 0 ? (
                            <View style={geral.row} wrap={false}>
                              <Text style={[geral.col, { width: '7%' }]}>1</Text>
                              <Text style={[geral.col, { width: '61%' }]}>MISCELÂNEAS</Text>
                              <Text style={[geral.col, { width: '16%' }]}>{calcValues(product?.miscellanies)?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</Text>
                              <Text style={[geral.col, { width: '16%' }]}>{calcValues(product?.miscellanies)?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</Text>
                            </View>
                          ) : (
                            <></>
                          )
                        }

                        {
                          product?.infrastructures?.length > 0 && calcValues(product?.infrastructures) > 0 ? (
                            <View style={geral.row} wrap={false}>
                              <Text style={[geral.col, { width: '7%' }]}>1</Text>
                              <Text style={[geral.col, { width: '61%' }]}>MATERIAL DE INFRAESTRUTURA, TUBULAÇÃO E ACABAMENTO</Text>
                              <Text style={[geral.col, { width: '16%' }]}>{calcValues(product?.infrastructures)?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</Text>
                              <Text style={[geral.col, { width: '16%' }]}>{calcValues(product?.infrastructures)?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</Text>
                            </View>
                          ) : (
                            <></>
                          )
                        }

                        {
                          calcValues([
                            ...product.labor_equipments,
                            ...product.general_labors
                          ]) > 0 ? (
                            <View style={geral.row} wrap={false}>
                              <Text style={[geral.col, { width: '7%' }]}>-</Text>
                              <Text style={[geral.col, { width: '61%' }]}>{product?.labor_title && !!product?.labor_title ? product?.labor_title : `MÃO DE OBRA INSTALAÇÃO`}</Text>
                              <Text style={[geral.col, { width: '16%' }]}>
                                -
                              </Text>
                              <Text style={[geral.col, { width: '16%' }]}>
                                {calcValues([
                                  ...product.labor_equipments,
                                  ...product.general_labors
                                ])?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                              </Text>
                            </View>
                          ) : (
                            <></>
                          )
                        }

                        <View style={geral.row} key={index.toString()} wrap={false}>
                          <Text style={[geral.col, { width: '68%', fontFamily: 'Helvetica-Bold', textAlign: 'right', fontSize: '14px' }]}>TOTAL</Text>
                          <Text style={[geral.col, { width: '32%', fontFamily: 'Helvetica-Bold', textAlign: 'right', fontSize: '14px' }]}>{(calcTotalLabor(product) + calcTotalEquipments(product))?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</Text>
                        </View>
                      </View>
                    )}
                  </>
                ) : (
                  <></>
                )
              }

              {
                checkIfProductHasSomeProvision(product) && proposal?.has_total_values ? (
                  <View>
                    {/* <Text style={geral.TitleBoldWithMargin}>Totais {product?.name}</Text> */}
                    <View style={geral.table}>
                      <View style={geral.row} wrap={false}>
                        <Text style={[geral.col, { width: '68%', paddingVertical: 6, fontFamily: 'Helvetica-Bold' }]}>DESCRIÇÃO</Text>
                        <Text style={[geral.col, { width: '32%', paddingVertical: 6, fontFamily: 'Helvetica-Bold' }]}>VALOR TOTAL</Text>
                      </View>
                      <View style={geral.row} key={index.toString()} wrap={false}>
                        <Text style={[geral.col, { width: '68%' }]}>Total em Equipamentos</Text>
                        <Text style={[geral.col, { width: '32%' }]}>{calcTotalEquipments(product)?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</Text>
                      </View>

                      {
                        calcTotalLabor(product) > 0 ? (
                          <View style={geral.row} key={index.toString()} wrap={false}>
                            <Text style={[geral.col, { width: '68%' }]}>Total em Mão de obra</Text>
                            <Text style={[geral.col, { width: '32%' }]}>{calcTotalLabor(product)?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</Text>
                          </View>
                        ) : (
                          <></>
                        )
                      }

                      <View style={geral.row} key={index.toString()} wrap={false}>
                        <Text style={[geral.col, { width: '68%' }]}>Total</Text>
                        <Text style={[geral.col, { width: '32%' }]}>{(calcTotalLabor(product) + calcTotalEquipments(product))?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</Text>
                      </View>
                    </View>
                  </View>
                ) : (
                  <></>
                )
              }

              {
                product?.product_observation && !!product?.product_observation ? (
                  <View style={{ marginBottom: '15px' }} wrap={false}>
                    <Text style={geral.TitleBoldWithMargin}>OBSERVAÇÕES</Text>
                    <Text style={geral.Text1}>{product.product_observation}</Text>
                  </View>
                ) : (
                  <></>
                )
              }
            </View>
          ))}
        </View>
        <Footer />
      </Page>
      {/* PAGE12 */}
      <Page size="A4" style={geral.page}>
        <Image src={CapaDaAgua} style={geral.bg} />
        <Header />
        <View style={page2.scopeSection}>
          {
            proposal?.has_summary_of_values ? (
              <Text style={geral.TitleBoldWithMargin}>12. PROPOSTA COMERCIAL</Text>
            ) : (
              <></>
            )
          }

          {proposal?.has_summary_of_values ? (
            <>
              {proposal && proposal?.products?.length > 0 ? (
                <View >
                  <Text style={geral.TitleBoldWithMargin}>RESUMO DE VALORES</Text>
                  <View style={geral.table}>
                    {proposal?.products?.map((product, index) => (
                      <View style={geral.row} key={index.toString()} wrap={false}>
                        <Text style={[geral.col, { width: '60%', paddingVertical: 6, fontFamily: 'Helvetica-Bold' }]}>
                          {product.name}
                        </Text>
                        <Text style={[geral.col, { width: '40%', paddingVertical: 6, fontFamily: 'Helvetica' }]}>
                          {(calcProductValue(product) + calcTotalLabor(product))?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                        </Text>
                      </View>
                    ))}
                    <View style={geral.row} wrap={false}>
                      <Text style={[geral.col, { width: '60%', paddingVertical: 6, fontFamily: 'Helvetica-Bold' }]}>
                        TOTAL
                      </Text>
                      <Text style={[geral.col, { width: '40%', paddingVertical: 6, fontFamily: 'Helvetica' }]}>
                        {(calcLabor(proposal) + calcProductsValue(proposal))?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                      </Text>
                    </View>
                  </View>
                </View>
              ) : (
                <Text>Nenhum produto encontrado</Text>
              )}
            </>
          ) : (<></>)}

          {
            proposal && proposal?.products.length > 0 ? (
              <>
                <View>
                  {
                    checkIfHasMonthlyServices(proposal?.products) ? (
                      <>
                        <Text style={geral.TitleBoldWithMargin}>SERVIÇOS MENSAIS</Text>
                        <View style={geral.table}>
                          <View style={geral.row} wrap={false}>
                            <Text style={[geral.col, { width: '40%', paddingVertical: 6, fontFamily: 'Helvetica-Bold' }]}>
                              NOME
                            </Text>
                            <Text style={[geral.col, { width: '60%', paddingVertical: 6, fontFamily: 'Helvetica-Bold' }]}>
                              DESCRIÇÃO
                            </Text>
                          </View>

                          {proposal?.products.map((product, index) => (
                            product.services.map((service, i) => (
                              <View style={geral.row} key={i.toString()} wrap={false}>
                                <Text style={[geral.col, { width: '40%', paddingVertical: 6 }]}>
                                  {service.name}
                                </Text>
                                <Text style={[geral.col, { width: '60%', paddingVertical: 6 }]}>
                                  {service.description}
                                </Text>
                              </View>
                            ))
                          ))}
                        </View>
                      </>
                    ) : (
                      <></>
                    )
                  }

                  {
                    calcTotalServices() > 0 ? (
                      <View style={geral.table}>

                        <View style={geral.row} wrap={false}>
                          <Text style={[geral.col, { width: '70%', paddingVertical: 6, fontFamily: 'Helvetica-Bold' }]}>
                            TOTAL VALOR MENSAL:
                          </Text>
                          <Text style={[geral.col, { width: '30%', paddingVertical: 6, fontFamily: 'Helvetica' }]}>
                            {calcTotalServices().toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                          </Text>
                        </View>
                      </View>
                    ) : (
                      <></>
                    )
                  }

                  {proposal?.products.map((product, index) => (
                    product.services_observation && !!product.services_observation ? (
                      <View style={geral.table}>
                        <View style={geral.row} wrap={false}>
                          <Text style={[geral.col, { width: '100%', paddingVertical: 6, fontFamily: 'Helvetica-Bold' }]}>
                            OBSERVAÇÕES DOS SERVIÇOS MENSAIS
                          </Text>
                        </View>
                        <View style={geral.row} key={index.toString()} wrap={false}>
                          <Text style={[geral.col, { width: '100%', paddingVertical: 6, fontFamily: 'Helvetica' }]}>
                            {product.services_observation}
                          </Text>
                        </View>
                      </View>
                    ) : (
                      <></>
                    )
                  ))}

                </View>
              </>
            ) : (
              <></>
            )
          }

          <View>
            <Text style={[geral.Text1, { textAlign: 'center' }]}>
              {"\n"}{"\n"}Os custos apresentados estão inclusos todos os impostos municipais, estaduais, federais, despesas administrativas, operacionais para a prestação dos serviços.{"\n"}{"\n"}{"\n"}
            </Text>
            <View style={{ flexDirection: 'row' }}>
              <Image src={RightArrow} style={{ height: '17px', objectFit: 'contain', marginRight: 4, marginTop: -2 }} />
              <Text style={geral.Text1}>
                <Text style={geral.TitleBold}>Validade da proposta - </Text>
                Esta proposta tem validade de 30 (trinta) dias a partir da data de emissão.
              </Text>
            </View>
          </View>

        </View>
        <Footer />
      </Page>

      {/* PAGE13 */}
      {
        proposal && proposal?.products?.length > 0 && proposal?.has_equipments ? (
          <Page size="A4" style={geral.page}>
            <Header />
            <View style={page2.scopeSection}>
              <Text fixed style={geral.TitleBoldWithMargin}>LISTA DE PRODUTOS</Text>
              {proposal.products.map((product) => (
                product?.equipments.length > 0 ? (
                  <View style={geral.table}>
                    <Text style={geral.TitleBoldWithMargin}>{product.name}</Text>
                    <View style={geral.row} wrap={false}>
                      <Text style={[geral.col, { width: '20%', paddingVertical: 6, fontFamily: 'Helvetica-Bold', fontSize: '9px' }]}>FOTO</Text>
                      <Text style={[geral.col, { width: '15%', paddingVertical: 6, fontFamily: 'Helvetica-Bold', fontSize: '9px' }]}>NOME</Text>
                      <Text style={[geral.col, { width: '15%', paddingVertical: 6, fontFamily: 'Helvetica-Bold', fontSize: '9px' }]}>MODELO</Text>
                      <Text style={[geral.col, { width: '30%', paddingVertical: 6, fontFamily: 'Helvetica-Bold', fontSize: '9px' }]}>DESCRIÇÃO TÉCNICA DO EQUIPAMENTO</Text>
                      <Text style={[geral.col, { width: '20%', paddingVertical: 6, fontFamily: 'Helvetica-Bold', fontSize: '9px' }]}>FABRICANTE</Text>
                    </View>
                    {product?.equipments.map((item, index) => (
                      <View style={
                        [geral.row, {
                          backgroundColor: ((index + 1) % 2 === 0) ? '#f1f1f1' : '#fff',
                          paddingVertical: 10,
                          alignItems: 'center',
                        }]}
                        key={index.toString()}
                        wrap={false}
                      >
                        <View style={{ width: '20%', border: 0 }}>
                          {item.photo_url ? (
                            <Image src={item.photo_url} style={{ height: '40px', objectFit: 'contain' }} />
                          ) : (
                            <></>
                          )}
                        </View>
                        <Text style={[geral.col, { width: '15%', border: 0, fontSize: '8px' }]}>{item.name}</Text>
                        <Text style={[geral.col, { width: '15%', border: 0, fontSize: '8px' }]}>{item.model}</Text>
                        <Text style={[geral.col, { width: '30%', border: 0, fontSize: '8px' }]}>{item.description}</Text>
                        <Text style={[geral.col, { width: '20%', border: 0, fontSize: '8px' }]}>{item.maker}</Text>
                      </View>
                    ))}
                  </View>
                ) : <></>
              ))}
            </View>
            <Footer />
          </Page>
        ) : (
          <></>
        )
      }

      {/* PAGE14 */}
      <Page size="A4" style={geral.page}>
        <Image src={CapaDaAgua} style={geral.bg} />
        <Header />
        <View style={[page2.section, { justifyContent: 'flex-start' }]}>

          <View style={{ marginTop: 15 }}>
            <Text style={geral.Text1}>
              <Text style={geral.TitleBold}>{proposal?.has_summary_of_values ? '13.' : '12.'} BENEFICIOS E VANTAGENS DO ALUGUES DE EQUIPAMENTOS</Text>
            </Text>

            <View style={{ flexDirection: 'row', marginVertical: 10 }}>
              <Image src={RightArrow} style={{ height: '17px', objectFit: 'contain', marginRight: 4, marginTop: -2 }} />
              <Text style={geral.Text1}>
                Equipamentos com tecnologia adequada para a sua exigência{"\n"}
                Modernos e atualizados, configuração e performance na medida exata.
              </Text>
            </View>

            <View style={{ flexDirection: 'row', marginVertical: 10 }}>
              <Image src={RightArrow} style={{ height: '17px', objectFit: 'contain', marginRight: 4, marginTop: -2 }} />
              <Text style={geral.Text1}>
                Custo zero de manutenção{"\n"}
                Manutenção e suporte técnico de total responsabilidade da MDS SECURITY durante toda a vigência do contrato.
              </Text>
            </View>

            <View style={{ flexDirection: 'row', marginVertical: 10 }}>
              <Image src={RightArrow} style={{ height: '17px', objectFit: 'contain', marginRight: 4, marginTop: -2 }} />
              <Text style={geral.Text1}>
                Suporte técnico rápido e especializado{"\n"}
                A MDS SECURITY disponibiliza técnicos no local para atendimento em caso de problemas com os equipamentos e consultas via telefone, uma vez que possui total controle dos produtos locados, seus componentes, configurações, locais onde se encontram, etc..
              </Text>
            </View>

            <View style={{ flexDirection: 'row', marginVertical: 10 }}>
              <Image src={RightArrow} style={{ height: '17px', objectFit: 'contain', marginRight: 4, marginTop: -2 }} />
              <Text style={geral.Text1}>
                Facilidade de substituição e adição{"\n"}
                Rapidez e eficiência na substituição e renovação dos equipamentos, reduzindo o tempo perdido em um processo normal de compra. Em caso de alguma falha no equipamento, a sua substituição é feita pela MDS SECURITY em um prazo máximo de 24 horas comerciais, por um equivalente ou melhor. A necessidade de equipamentos adicionais aos locados é atendida de maneira ágil e prática, com a alteração do valor previamente contratado.
              </Text>
            </View>

            <View style={{ flexDirection: 'row', marginVertical: 10 }}>
              <Image src={RightArrow} style={{ height: '17px', objectFit: 'contain', marginRight: 4, marginTop: -2 }} />
              <Text style={geral.Text1}>
                Melhor relação custo x benefício{"\n"}
                A compra de equipamentos demanda disponibilidade de capital e tempo. Com a constante evolução tecnológica, os equipamentos de segurança se tornam obsoletos muito rapidamente, e o seu investimento também é rapidamente perdido. Com o passar do tempo, os gastos com manutenção e suporte da máquina aumentam, o que pode ocasionar também a perda da confiança. O custo da locação é muito pequeno e os benefícios são muito grandes.
              </Text>
            </View>

            <View style={{ flexDirection: 'row', marginVertical: 10 }}>
              <Image src={RightArrow} style={{ height: '17px', objectFit: 'contain', marginRight: 4, marginTop: -2 }} />
              <Text style={geral.Text1}>
                Preservação de seu capital para investimento em sua atividade fim{"\n"}
                A locação dos equipamentos de segurança permite que o parcelamento do pagamento gere uma reserva de capital de giro, que pode ser usada como investimento no seu negócio, evitando o dispêndio imediato de uma grande quantia de recursos que seriam necessários para a sua compra.
              </Text>
            </View>

            <View style={{ flexDirection: 'row', marginVertical: 10 }}>
              <Image src={RightArrow} style={{ height: '17px', objectFit: 'contain', marginRight: 4, marginTop: -2 }} />
              <Text style={geral.Text1}>
                Abatimento como despesa no Imposto de Renda{"\n"}
                O valor da locação pode ser lançado como despesa operacional da empresa, sendo, portanto, dedutível do Imposto de Renda. A locação é 100% dedutível para as empresas tributadas pelo Lucro Real. Além disso, possibilita também uma simplicidade contábil, uma vez que a conta ativo fica com a MDS SECURITY.
              </Text>
            </View>

          </View>
        </View>
        <Footer />
      </Page>
    </Document>
  );
}

export default function ProposalPdf() {
  const [proposal, setProposal] = useState<Proposal | null>();
  const [customers, setCustomers] = useState<any[]>([]);

  const { entity_id } = useParams<any>();

  const { addToast } = useToast();

  // const [instance, update] = usePDF({ document: <MyDoc proposal={proposal} customers={customers} /> });

  useEffect(() => {
    if (entity_id && entity_id !== 'novo') {
      api
        .get(`proposals/${entity_id}`)
        .then(response => {
          const proposalAux = response.data.item;
          setProposal(proposalAux);
          // console.log('proposalAux', proposalAux);
        })
        .catch(err => {
          console.log(err);
          addToast({
            type: 'error',
            title: 'Falha na requisição',
            description:
              'Ocorreu um erro ao carregar os dados, verifique sua conexão e tente novamente.',
          });
        });
    }
  }, [entity_id]);

  useEffect(() => {
    api
      .get(`/customers-logo-for-proposals`)
      .then(response => {
        const customersAux = response.data.items;
        setCustomers([...customersAux]);
      })
      .catch(err => {
        console.log(err);
        addToast({
          type: 'error',
          title: 'Falha na requisição',
          description:
            'Ocorreu um erro ao carregar os dados, verifique sua conexão e tente novamente.',
        });
      });
  }, []);

  return (
    <div style={{ width: '100vw', height: '100vh' }}>
      {proposal ? (
        <PDFViewer width="100%" height="100%" style={{ flex: 1 }}>
          <MyDoc proposal={proposal} customers={customers} />
        </PDFViewer>
      ) : (<></>)}
    </div>
  )
}

// export default ProposalPdf;

// Create geral
const geral = StyleSheet.create({
  page: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: 30,
    paddingBottom: 15,
  },
  bg: {
    position: 'absolute',
    top: 0,
    left: 0,
    flex: 1,
    width: '100vw',
    height: '100%',
  },
  header: {},
  headerLogo: {
    width: 140,
    objectFit: 'scale-down',
  },
  footer: {
    height: 50,
    alignItems: 'center',
    flexDirection: 'row',
  },
  footerCol1: {
  },
  footerCol2: {
    flex: 1,
    marginLeft: '10px',
    borderLeftWidth: '1px',
    borderLeftColor: '#ddd',
    alignItems: 'center',
    justifyContent: 'center',
  },
  footerImg: {
    marginRight: '-15px',
    marginLeft: '10px',
  },
  TextFooter: {
    fontFamily: 'Helvetica',
    fontSize: '9px',
    color: '#777',
    marginBottom: '1px',
  },
  Title: {
    fontSize: '12px',
    fontWeight: 'bold',
    lineHeight: 1.3,
    fontFamily: 'Helvetica'
  },
  TitleBold: {
    fontSize: '12px',
    lineHeight: 1.3,
    fontFamily: 'Helvetica-Bold'
  },
  TitleWithMargin: {
    fontSize: '12px',
    fontWeight: 'bold',
    lineHeight: 1.3,
    fontFamily: 'Helvetica',
    margin: '7px 0',
  },
  TitleBoldWithMargin: {
    fontSize: '12px',
    lineHeight: 1.3,
    fontFamily: 'Helvetica-Bold',
    margin: '7px 0',
  },
  Text1: {
    fontSize: '12px',
    fontWeight: 'light',
    lineHeight: 1.3,
    fontFamily: 'Helvetica'
  },
  table: {
    display: 'flex',
    margin: '7px 0',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  col: {
    fontSize: '9px',
    borderWidth: 1,
    padding: 3,
    fontFamily: 'Helvetica',
    textAlign: 'center'
  },
  indexText: {
    fontFamily: 'Helvetica-Bold',
  },
  customerList: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    paddingHorizontal: 7,
  },
  logoCustomer: {
    display: 'flex',
    padding: '0.5rem',
    marginTop: '7px',
    border: '1px solid #333',
    width: '85px',
    height: '85px',
    objectFit: 'contain',
    boxSizing: 'border-box',
    marginRight: '10px',
  }
});

const page1 = StyleSheet.create({
  cover: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    flex: 1,
  },
  logo: {
    height: 200,
    objectFit: 'scale-down',
    // width: 100,
  },
});

const page2 = StyleSheet.create({
  section: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    flex: 1,
    paddingHorizontal: 15,
  },
  scopeSection: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    flex: 1,
    paddingHorizontal: 15,
  },
});

const page3 = StyleSheet.create({
  section: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    flex: 1,
    paddingHorizontal: 15,
    paddingVertical: 10,
  },
  item: {
    display: 'flex',
    flexDirection: "row",
    marginBottom: 3,
    justifyContent: 'space-between',
    fontSize: '12px',
    fontWeight: 'bold',
    lineHeight: 1.5,
  },
  dotted: {
    flex: 1,
    borderBottomWidth: 2,
    borderBottomStyle: 'dotted',
    marginLeft: 10,
    marginRight: 10,
    position: 'relative',
    top: -3,
  }
});

const page4 = StyleSheet.create({
  mapa: {
    height: 250,
    objectFit: 'contain',
  }
})
