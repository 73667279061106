import React from 'react';
import Modal from 'react-modal';

import { FormContainer, HistoryContainer } from './styles';

import { FiXCircle } from 'react-icons/fi';
import moment from 'moment';

interface PropData {
  item: any;
  closeModal(): void;
}

const ProposalsEditHistory: React.FC<PropData> = ({ item, closeModal }) => {
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '600px',
      maxWidth: '100%',
      padding: '40px',
    },
  };

  return (
    <Modal
      isOpen={true}
      onRequestClose={() => closeModal()}
      style={customStyles}
    >
      <FormContainer>
        <h2>Histórico de edições</h2>

        <button
          type="button"
          onClick={() => {
            closeModal();
          }}
          className="close-btn"
        >
          <FiXCircle size={30} />
        </button>

        <HistoryContainer>
          {
            item && item.edit_history ? (
              item.edit_history.map((h: any, i: number) => (
                <>
                  {
                    i === 0 && h.action !== "Criação" && (
                      <div className="history-item">
                        <strong>MDSSecurity (automático)</strong>
                        <p>{moment(item?.created_at).format('L HH:mm')}</p>
                        <p>Criação</p>
                      </div>
                    )
                  }
                  <div className="history-item">
                    <strong>{h.name}</strong>
                    <p>{moment(h.date).format('L HH:mm')}</p>
                    <p>{h.action}</p>
                  </div>
                </>
              ))
            ) : (
                <div className="history-item">
                  <strong>MDSSecurity (automático)</strong>
                  <p>{moment(item?.created_at).format('L HH:mm')}</p>
                  <p>Criação</p>
                </div>
              )
          }
        </HistoryContainer>
      </FormContainer>
    </Modal>
  );
};

export default ProposalsEditHistory;
