import axios from 'axios';

const bcrypt = require('bcryptjs');

const api = axios.create({
  // baseURL: 'http://localhost:3001/api/',
  baseURL: 'https://mds.cmimonitoramento.com.br/api/'
});

const hash = bcrypt.hashSync('hgbtfN38c0ASDs4a324eD7ncPA4asc', 10);
api.defaults.headers.app_key = hash;

export default api;
