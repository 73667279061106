import { createGlobalStyle, keyframes } from 'styled-components';

const shine = keyframes`
  0% {
    opacity: .4;
    top: 0%;
    left: 0%;
    transition-property: left, top, opacity;
    transition-duration: 1s, 1s, 0.5s;
    transition-timing-function: ease;
  }
  100% {
    opacity: 0;
    top: 0%;
    left: 50%;
    transition-property: left, top, opacity;
  }
`;

export default createGlobalStyle`
  :root {
    --primaryColor: #254CAA;
    --secondColor: #ED3237;

    --redColor: #c53030;

    --titleColor: #254CAA;
    --textColor: #181818;
    --whiteColor: #fff;

    --submenuColor: #395cb0;
  }

  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }

  html {
    @media (max-width: 1080px) {
      font-size: 93.75%;
    }

    @media (max-width: 720px) {
      font-size: 87.5%;
    }
  }

  body {
    background: #F5F5F5;
    -webkit-font-smoothing: antialiased;
  }

  body, input, textarea, button {
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
  }

  h1, h2, h3, h4, strong {
    font-weight: 700;
  }

  h5, h6 {
    font-weight: 600;
  }

  button {
    cursor: pointer;
  }

  [disabled] {
    opacity: 0.6;
    cursor: not-allowed;
  }

  .dzu-dropzone {
    border: 1px solid #ccc;
    margin-bottom: 30px;
    overflow-x: hidden;
  }

  .dzu-inputLabel {
    color: var(--primaryColor);

    @media (max-width: 768px) {
      font-size: 1rem;
    }
  }

  .dzu-previewButton {
    filter: none;
  }

  span.dzu-previewFileName, .dzu-inputLabelWithFiles {
    color: var(--primaryColor);
    margin: 25px auto;
  }

  .dzu-previewContainer {
    padding: 0px 3%;
  }

  .ReactModal__Overlay.ReactModal__Overlay--after-open {
    background-color: rgb(0 0 0 / 60%) !important;
    z-index: 999;
  }

  .no-javascript {
    position: fixed;
    top: 0;
    z-index: 999999;
    background: #c14545;
    width: 100%;
    display: flex;
    justify-content: center;
    color: #fff;
    font-weight: 500;
    padding: 5px;
  }

  .react-confirm-alert-overlay {
    background: rgb(0 0 0 / 90%);
    z-index: 9999;
  }

  .custom-ui {
    text-align: center;
    width: 500px;
    padding: 40px;
    background: var(--primaryColor);
    box-shadow: 0 3px 6px rgb(0 0 0 / 0.26);
    color: #fff;
    border-radius: 5px;

    > h1 {
      margin-top: 0;
      margin-bottom: 18px;
    }

    > p {
      margin-bottom: 13px;
    }

    > button {
      width: 160px;
      padding: 10px;
      border: 1px solid #fff;
      margin: 10px;
      cursor: pointer;
      background: none;
      color: #fff;
      font-size: 14px;

      &:hover {
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
        background-color: rgba(255, 255, 255, 0.16);
        border-color: transparent;
      }
    }
  }
`;
