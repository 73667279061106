import styled from 'styled-components';

export const Container = styled.div`

  .item-block {
    width: 100%;
    overflow: auto;
    margin-bottom: 1.5rem;

    table {
      thead {
        tr {
          td {
            &:nth-child(1) {
              @media(max-width: 1024px) {
                width: 200px;
              }
            }
          }
        }
      }

      tbody {
        tr {
          &.total-tr {
            background-color: #ddd;
          }
        }
      }
    }
  }

`;
