import React, { useRef, useEffect, useState, useCallback } from 'react';
import ReactSelect, {
  OptionTypeBase,
  Props as SelectProps,
} from 'react-select';
import { useField } from '@unform/core';

import { FiAlertCircle, FiX } from 'react-icons/fi';

import { Container, Error, Label } from './styles';

interface Props extends SelectProps {
  name: string;
  fullWidth?: boolean;
  label?: string;
  handleSelected?(selecteds: any[]): void;
}

interface DefaultOptionI {
  value: string;
  label: string;
}

const CustomSelect: React.FC<Props> = ({
  name,
  fullWidth = false,
  label,
  handleSelected,
  ...rest
}) => {
  const selectRef = useRef(null);
  const { fieldName, defaultValue, registerField, error, clearError } = useField(name);

  const [selecteds, setSelecteds] = useState<DefaultOptionI[]>([]);
  const [allOptions, setAllOptions] = useState<any>([]);
  const [options, setOptions] = useState<any>([]);

  useEffect(() => {
    setOptions(rest.options);
    setAllOptions(rest.options);
  }, [rest.options]);

  useEffect(() => {
    if (selecteds.length > 0) {
      const optionsAux = allOptions?.filter((item: any) => selecteds.findIndex(x => x?.value === item?.value) === -1);
      setOptions([...optionsAux]);
    }

    if (handleSelected) {
      handleSelected(selecteds);
    }
  }, [selecteds]);

  useEffect(() => {
    if (defaultValue.length > 0) {
      const optionsAux = allOptions?.filter((item: any) => {
        return defaultValue.findIndex((defaultValueAux: any) => defaultValueAux === item?.value) !== -1
      });

      setSelecteds([...optionsAux]);
      setOptions([...optionsAux]);
    }

    if (handleSelected) {
      handleSelected(selecteds);
    }
  }, [defaultValue]);

  function handleChange(value: any) {
    if (value) {
      setSelecteds((old) => [...old, value]);
      setOptions(options.filter((item: any) => item.value !== value.value));
    } else {
      setSelecteds([]);
      setOptions([...allOptions]);
    }
  }

  const handleRemove = useCallback(
    (value: string) => {
      const selectedsAux = selecteds.filter((item: any) => item.value !== value);
      setSelecteds(selectedsAux);
      const optionsAux = [
        ...options,
        allOptions.filter((item: any) => item.value === value)[0],
      ];
      setOptions(optionsAux);
    },
    [allOptions, options]
  );

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      getValue: () => {
        const aux = selecteds.map((item: any) => {
          return item?.value
        });

        return aux;
      },
      setValue: async (ref, value: any) => {
        if (value)
          setSelecteds([...value]);
      },
    });
  }, [fieldName, registerField, rest.isMulti, selecteds]);
  return (
    <Label fullWidth={fullWidth} className='label'>
      {!!label && <strong className='desc'>{label}</strong>}
      <Container isErrored={!!error} className='input'>
        <ReactSelect
          ref={selectRef}
          classNamePrefix='react-select'
          onChange={handleChange}
          {...rest}
          options={options}
          onFocus={() => clearError()}
        />

        {error && (
          <Error title={error}>
            <FiAlertCircle color='c53030' size={20} />
          </Error>
        )}
      </Container>

      <div className='selectes-box'>
        {selecteds &&
          selecteds.map((item, index) => {
            return (
              <div key={index.toString()}>
                {item?.label}
                <button
                  type='button'
                  className='rm'
                  onClick={() => handleRemove(item.value)}
                >
                  <FiX />
                </button>
              </div>
            );
          })}
      </div>
    </Label>
  );
};

export default CustomSelect;
