import styled, { css } from 'styled-components';

import Tooltip from '../Tooltip';

interface ContainerProps {
  isFocused: boolean;
  isErrored: boolean;
}

interface LabelProps {
  fullWidth: boolean;
}

export const Label = styled.div<LabelProps>`
  width: 100%;
  max-width: calc(50% - 15px);

  & .desc {
    color: #313131;
  }

  strong {
    font-weight: 600;
    margin-bottom: 2px;
    display: inline-block;
    font-size: 13px;
    color: #313131;
  }

  ${props =>
    props.fullWidth &&
    css`
      max-width: 100%;
    `}

  @media (max-width: 769px) {
    max-width: 100%;
  }

  .selectes-box {
    display: flex;
    min-height: 80px;
    background: #ddd;
    margin-bottom: 15px;
    width: 100%;
    padding: 15px;
    border-radius: 5px;
    align-items: flex-start;
    flex-wrap: wrap;
    box-sizing: border-box;

    > div {
      background: var(--primaryColor);
      margin-right: 10px;
      padding: 4px 25px;
      border-radius: 5px;
      position: relative;
      color: #fff;
      font-weight: 600;
      font-size: 12px;
      margin-bottom: 10px;

      .rm {
        position: absolute;
        cursor: pointer;
        right: -6px;
        top: -9px;
        background: #fff;
        border: 0;
        width: 16px;
        height: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        font-size: 10px;
        color: #a90a0a;
        box-shadow: 0 3px 6px rgb(0 0 0 / 0.16);
      }
    }
  }
`;

export const Container = styled.div<ContainerProps>`
  height: 38px;
  background: #ffffff;
  border: 1px solid #c9c9c9;
  border-radius: 5px;
  border-radius: 5px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  padding: 0 10px;
  font-size: 14px;

  ${props =>
    props.isErrored &&
    css`
      border-color: #c53030;
    `}

  > div:nth-child(1) {
    width: 100%;

    > div {
      border: 0 !important;
    }

    .react-select__control {
      box-shadow: none !important;
      min-height: 36px;

      .react-select__value-container {
        padding: 2px 8px 2px 0;
      }
    }
  }

  @media (max-width: 769px) {
    width: 100%;
  }

  input {
    background: transparent;
    flex: 1;
    border: 0;
    width: inherit;
    max-width: 100%;

    &:disabled {
      cursor: not-allowed;
    }

    &::placeholder {
      color: #c9c9c9;
    }
  }

  .add {
    background: var(--primaryColor);
    border: none;
    color: #ffffff;
    padding: 5px 15px;
    border-radius: 5px;
    margin-left: 20px;
  }
`;

export const Error = styled(Tooltip)`
  height: 20px;
  margin-left: 16px;

  svg {
    margin: 0;
  }

  span {
    background: #c53030;
    color: #fff;

    &::before {
      border-color: #c53030 transparent;
    }
  }
`;
