import React, { useCallback, useEffect, useRef, useState } from 'react';
import { AiOutlineCloseCircle } from 'react-icons/ai';

import { Container, Header, PopUp } from './styles';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import getValidationErrors from '../../../utils/getValidationErrors';
import * as Yup from 'yup';

import Button from '../../../components/Form/Button';
import Textarea from '../../Form/Textarea';

import { useToast } from '../../../hooks/Toast';

import api from '../../../services/api';

import Dropzone from 'react-dropzone-uploader';
import 'react-dropzone-uploader/dist/styles.css'
import Select from '../../Form/Select';
import { components } from 'react-select';
import { Link } from 'react-router-dom';

interface ModalProps {
  onClose?(): void;
  onSelectItem(data: any, index: number): void;
  refreshList(): void;
  isLoading: boolean;
  CurrentRoute: string;
  CurrentIndex: number;
  AttrName: string;
  ItemName: string;
  CurrentOptions: any;
}

const SearchModal: React.FC<ModalProps> = ({
  refreshList,
  onClose,
  onSelectItem,
  isLoading,
  CurrentRoute,
  CurrentIndex,
  AttrName,
  ItemName,
  CurrentOptions
}) => {
  const [searching, setSearching] = useState(false);
  const [itemsLimit] = useState<number>(10);

  const modalFormRef = useRef<FormHandles>(null);
  const { addToast } = useToast();

  const OptimizedList = (props: any): any => {
    return (
      <components.MenuList {...props}>
        {Array.isArray(props.children)
          ? props.children.slice(0, itemsLimit)
          : props.children}
      </components.MenuList>
    );
  };

  return (
    <Container>
      <PopUp>
        <Header>
          <h2>Selecione um(a) {ItemName}</h2>
          <div onClick={onClose}><AiOutlineCloseCircle size={24} /></div>
        </Header>
        <Form onSubmit={console.log} ref={modalFormRef}>
          <Select
            name="item"
            options={
              CurrentOptions ?
                [{ value: 0, label: `Exibindo apenas os ${itemsLimit} primeiros. Digite para buscar mais...` }]
                  .concat(
                    CurrentOptions[AttrName]?.map((item: any) => {
                      return {
                        label: item.name || item.description,
                        value: item.id,
                      }
                    })
                  ) :
                []
            }
            fullWidth
            placeholder="Digite para buscar e escolha um..."
            label={`Buscar ${ItemName}`}
            noOptionsMessage={() => "Sem resultados..."}
            loadingMessage={() => "Carregando opções..."}
            isSearchable
            components={{ MenuList: OptimizedList }}
            isOptionDisabled={(opt) => opt.value === 0}
            isLoading={searching}
            onChange={value => onSelectItem(value, CurrentIndex)}
          />

          <div className="buttons">
            <Button type="button" onClick={() => refreshList()} disabled={isLoading}>
              {isLoading ? "Atualizando...":"Atualizar lista"}
            </Button>
            <Link to={CurrentRoute} target="_blank">
              Cadastrar novo
            </Link>
          </div>
        </Form>
      </PopUp>
    </Container>
  );
}

export default SearchModal;
